/*
 * - Stores paths for the file storage service endpoints
 */

const serviceRoot = 'api/FileStorage/';

export const FileStorageEndpoints = {
  // Queries ---------------------------------------------------------------------------------------

  // Mutations -------------------------------------------------------------------------------------
  uploadPnAttachments: () => `${serviceRoot}uploadPublicNoticeAttachments`,
};
