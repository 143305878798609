import { Radio } from '@trussworks/react-uswds';
import classNames from 'classnames';
import './YesNoInput.scss';
import { useFormContext } from 'react-hook-form';

const YesNoInput = ({ hint, id, label, name, onChange, required, showOptionalText = true }) => {
  const {
    formState: { errors },
    register,
    setValue,
    trigger,
  } = useFormContext();

  const handleOnChange = (e) => {
    setValue(name, e.target.value);
    trigger(name);
    onInputChange(e);
  };

  const { ref: inputRef, onChange: onInputChange } = register(name, { onChange });

  const containerStyles = classNames('yes-no-container', {
    error: errors[name],
  });
  const labelStyles = classNames('usa-label', {
    optional: !required && showOptionalText,
    required: required,
  });

  return (
    <div className={containerStyles} id={id}>
      <div className={labelStyles}>{label}</div>
      {hint && <div className='usa-hint'>{hint}</div>}
      <div className='yes-no-input-container'>
        <Radio id={`${id}-yes`} inputRef={inputRef} label='Yes' name={name} onChange={handleOnChange} value='yes' />
        <Radio id={`${id}-no`} label='No' name={name} onChange={handleOnChange} value='no' />
      </div>
    </div>
  );
};

export default YesNoInput;
