import { create } from 'zustand';
import { JpaBasicInfo } from '@api/services/jpa/types';

interface JpaState {
  jpaOrgIds: number[];
  jpaBasicInfo: JpaBasicInfo;
}

export const useJpa = create<JpaState>((set) => ({
  jpaOrgIds: [],
  jpaBasicInfo: [],

  setJpaOrgIds: (jpaOrgIds: number[]) => set({ jpaOrgIds }),
  setJpaBasicInfo: (jpaBasicInfo: JpaBasicInfo) => set({ jpaBasicInfo }),
}));
