import { connect } from 'redux-bundler-react';

import JDForm1bInstructions from '@forms/static-forms/JDForm1bInstructions';
import JDFormGeneralProjectInformation from '@forms/input-forms/JDForm/JDFormGeneralProjectInformation';
import JDFormContacts from '@forms/input-forms/JDForm/JDFormContacts';
import SupportingInformation from '@forms/input-forms/common/SupportingInformation';
import JDFormCertifySignSubmit from '@forms/input-forms/JDForm/JDFormCertifySignSubmit';
import JDFormPropertyOwners from '@forms/input-forms/JDForm/JDFormPropertyOwners';

//List of steps for vertical stepper, these can also be used for form header. index = step ID
export const JDForm1bMetadata = [
  JDForm1bInstructions.metadata,
  JDFormGeneralProjectInformation.metadata,
  JDFormContacts.metadata,
  JDFormPropertyOwners.metadata,
  SupportingInformation.metadata,
  JDFormCertifySignSubmit.metadata,
];

const generateSections = (jDData, jdRequestID, doUpdateJDRequest) => {
  const sections = [
    {
      id: 'JD1bInstructions',
      name: 'Instructions',
      content: JDForm1bInstructions,
    },
    {
      id: 'JD1bGeneralProjectInformation',
      name: 'General Project Information',
      content: JDFormGeneralProjectInformation,
    },
    {
      id: 'JD1bContactInformation',
      name: 'Contact Information',
      content: JDFormContacts,
    },
    {
      id: 'JD1bPropertyOwners',
      name: 'Property Owners',
      content: JDFormPropertyOwners,
    },
    {
      id: 'JD1bSupportingInformation',
      name: 'Supporting Information',
      content: SupportingInformation,
      componentID: jdRequestID,
      subtext: (
        <>
          <p>
            Upload supporting documentation including wetland datasheets, OHWM datasheets, and figures supporting the
            delineation report. This information will help facilitate the review of your request. Multiple documents of
            the same file can be combined and uploaded as one document.
          </p>
        </>
      ),
      requestData: jDData,
      updateRequestData: doUpdateJDRequest,
    },
    {
      id: 'JD1bCertifySignSubmit',
      name: 'Certify Sign Submit',
      content: JDFormCertifySignSubmit,
    },
  ];
  return sections;
};

const JDForm1b = connect(
  'doUpdateJDRequest',
  'selectActiveStep',
  'selectJDRequestID',
  'selectJDData',
  ({ doUpdateJDRequest, activeStep, jDRequestID, jDData }) => (
    <>
      {generateSections(jDData, jDRequestID, doUpdateJDRequest, activeStep).map((section, i) => (
        <section id={section.id} name={section.name} style={{ display: activeStep?.id === i ? 'inline' : 'none' }}>
          <section.content
            stepNo={i}
            componentID={section?.componentID}
            subtext={section?.subtext}
            requestData={section?.requestData}
            updateRequestData={section?.updateRequestData}
          />
        </section>
      ))}
    </>
  )
);

export default JDForm1b;
