import { useMemo } from 'react';
import { Alert } from '@trussworks/react-uswds';

import ReadOnlyMap from '@components/map/readOnlyMap';
import ExternalLink from '@components/external-link/ExternalLink';

import { getStateNameFromCode, calculateGeometryExtent, formatDateToMMDDYYYY } from '@src/utils/helpers';

const LabelValue = ({ label, value, isDate }) => {
  const displayValue = isDate && value ? formatDateToMMDDYYYY(value) : value;

  return (
    <div className='pn-label-value'>
      <b>{label}:</b>
      <div>{displayValue || '-'}</div>
    </div>
  );
};

const PublicNoticeInfo = ({ data, districtData }) => {
  const geometry = useMemo(
    () => ({
      type: 'Point',
      coordinates: [data?.longitude, data?.latitude],
    }),
    [data?.latitude, data?.longitude]
  );

  return (
    <div className='pn-info-container'>
      <div>
        <section className='pn-section flex-item'>
          <h3>Details</h3>
          <div className='pn-flex-container'>
            <div className='pn-flex-item'>
              <LabelValue label='DA Number' value={data.daNumber} />
              <LabelValue label='Project Name' value={data.projectName} />
              <LabelValue label='Action Type' value={data.actionType} />
            </div>
            <div className='pn-flex-item'>
              <LabelValue label='Date of Public Notice' value={data.startDate} isDate />
              <LabelValue label='Comment Period Ends' value={data.commentsEndDate} isDate />
              <LabelValue label='Keyword(s)' value={data.keywords} />
            </div>
          </div>
          <LabelValue label='Description' value={data?.description ?? 'No Description Available'} />
        </section>
        <section className='pn-section flex-item'>
          <h3>Applicant</h3>
          <div className='pn-flex-container'>
            <div className='pn-flex-item'>
              <LabelValue label='Applicant Name' value={data.applicantName} />
            </div>
            <div className='pn-flex-item'>
              <LabelValue label='Applicant Company' value={data.applicantCompany} />
            </div>
          </div>
        </section>
      </div>
      <section className='pn-section'>
        <h3>Location</h3>
        <div className='pn-flex-container'>
          <div className='pn-flex-item'>
            <ReadOnlyMap mapOnly geometry={geometry} extent={calculateGeometryExtent(geometry)} height='240px' />
          </div>
          <div className='pn-flex-item'>
            <div className='pn-flex-container'>
              <div className='pn-flex-item'>
                <LabelValue label='District' value={data.district} />
                <LabelValue label='State' value={getStateNameFromCode(data.state)} />
                <LabelValue label='County' value={data.county} />
                <LabelValue label='Zip Code' value={data.zipCode} />
              </div>
              <div className='pn-flex-item'>
                <LabelValue label='Hydrologic Unit Code' value={data.hydrologicUnitCode} />
                <LabelValue label='Latitude' value={data.latitude} />
                <LabelValue label='Longitude' value={data.longitude} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pn-section'>
        <h3>Files</h3>
        {districtData && (
          <Alert noIcon slim className='callout margin-bottom-1 text-italic'>
            Contact the{' '}
            <ExternalLink title={districtData.orgName} content={districtData.orgName} href={districtData.orgUrl} /> at{' '}
            <a href='tel:{districtData.orgPhone}'>{districtData.orgPhone}</a> to report broken links or obtain
            additional help.
          </Alert>
        )}
        {data.urls?.map((url) => (
          <div key={url}>
            <ExternalLink content={url} href={url} />
          </div>
        ))}
      </section>
    </div>
  );
};

export default PublicNoticeInfo;
