import { connect } from 'redux-bundler-react';

import ViolationsFormInstructions from '@forms/static-forms/ViolationsFormInstructions';
import ViolationsFormContacts from '@forms/input-forms/ViolationsForms/ViolationsFormContacts';
import SupportingInformation from '@forms/input-forms/common/SupportingInformation';
import ViolationsFormGeneralSiteInformation from '@forms/input-forms/ViolationsForms/ViolationsFormGeneralSiteInformation';
import ViolationsFormCertifySignSubmit from '@forms/input-forms/ViolationsForms/ViolationsFormCertifySignSubmit';

//List of steps for vertical stepper, these can also be used for form header. index = step ID
export const ViolationsForm5Metadata = [
  ViolationsFormInstructions.metadata,
  ViolationsFormContacts.metadata,
  ViolationsFormGeneralSiteInformation.metadata,
  SupportingInformation.metadata,
  ViolationsFormCertifySignSubmit.metadata,
];

const generateSections = (violationComplaintData, violationComplaintID, doUpdateViolationComplaintRequest) => {
  const sections = [
    {
      id: 'ViolationsFormInstructions',
      name: 'Instructions',
      content: ViolationsFormInstructions,
    },
    {
      id: 'ViolationsFormContacts',
      name: 'Contact Information',
      content: ViolationsFormContacts,
    },
    {
      id: 'ViolationsFormGeneralSiteInformation',
      name: 'General Site Information',
      content: ViolationsFormGeneralSiteInformation,
    },
    {
      id: 'ViolationsFormSupportingInformation',
      name: 'Supporting Information',
      content: SupportingInformation,
      componentID: violationComplaintID,
      subtext: (
        <>
          <p>Include copies of any supporting documents you have (if available).</p>
        </>
      ),
      requestData: violationComplaintData,
      updateRequestData: doUpdateViolationComplaintRequest,
    },
    {
      id: 'ViolationsFormCertifySignSubmit',
      name: 'Certify Sign Submit',
      content: ViolationsFormCertifySignSubmit,
    },
  ];
  return sections;
};

const ViolationsForm5 = connect(
  'doUpdateViolationComplaintRequest',
  'selectActiveStep',
  'selectViolationComplaintID',
  'selectViolationComplaintData',
  ({ doUpdateViolationComplaintRequest, activeStep, violationComplaintID, violationComplaintData }) => (
    <>
      {generateSections(
        violationComplaintData,
        violationComplaintID,
        doUpdateViolationComplaintRequest,
        activeStep
      ).map((section, i) => (
        <section id={section.id} name={section.name} style={{ display: activeStep?.id === i ? 'inline' : 'none' }}>
          <section.content
            stepNo={i}
            componentID={section?.componentID}
            subtext={section?.subtext}
            requestData={section?.requestData}
            updateRequestData={section?.updateRequestData}
          />
        </section>
      ))}
    </>
  )
);

export default ViolationsForm5;
