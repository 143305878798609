/*
 * - Definitions of PublicNotice queries
 */

import PublicNoticeService from './service';
import {
  ExportPublicNoticeCsvData,
  ExportPublicNoticeCsvRequest,
  SavePublicNoticeCommentRequest,
  SavePublicNoticeResponseData,
} from './types';

export class PublicNoticeMutations {
  public static readonly saveComment = async (
    payload: SavePublicNoticeCommentRequest
  ): Promise<SavePublicNoticeResponseData> => {
    const { data } = await PublicNoticeService.saveComment(payload);

    return data;
  };

  public static readonly exportAllAsCsv = async (
    filters: ExportPublicNoticeCsvRequest
  ): Promise<ExportPublicNoticeCsvData> => {
    const { data } = await PublicNoticeService.exportAllAsCsv(filters);

    return data;
  };
}
