/*
 * - Definitions for PublicNotice queries
 */

import PublicNoticeService from './service';
import {
  GetAllPublicNoticesData,
  GetAllPublicNoticesRequest,
  GetPublicNoticeFilterCountsData,
  GetPublicNoticeFilterCountsRequest,
  PublicNoticeDetail,
} from './types';

export const PN_QUERY_KEYS = {
  GET_ALL: 'GET_ALL_PUBLIC_NOTICES',
  GET_BY_ID: 'GET_PUBLIC_NOTICE_BY_ID',
  GET_CSV_EXPORT: 'GET_PUBLIC_NOTICE_CSV_EXPORT',
  GET_FILTER_COUNTS: 'GET_PUBLIC_NOTICE_FILTER_COUNTS',
};

export class PublicNoticeQueries {
  public static readonly getAll = async (payload: GetAllPublicNoticesRequest): Promise<GetAllPublicNoticesData> => {
    const { data } = await PublicNoticeService.getAll(payload);
    return data;
  };

  public static readonly getFilterCounts = async (
    payload: GetPublicNoticeFilterCountsRequest
  ): Promise<GetPublicNoticeFilterCountsData> => {
    const { data } = await PublicNoticeService.getFilterCounts(payload);

    return data;
  };

  public static readonly getById = async (id: string): Promise<PublicNoticeDetail> => {
    const { data } = await PublicNoticeService.getById(id);

    return data;
  };
}
