import { useEffect, useMemo } from 'react';
import useErrorFocus from '@hooks/useErrorFocus';
import { connect } from 'redux-bundler-react';

import FieldHeader from '@forms/components/Form/FieldHeader';
import DatePicker from '@components/date-picker/DatePicker';
import TextInput from '@components/text-input/TextInput';
import TextAreaInput from '@components/textarea/TextArea';
import SelectInput from '@components/select/Select';
import CreatableSelectInput from '@components/new-inputs/creatableSelectInput';
import Tooltip from '@components/tooltip/tooltip';
import ExternalLink from '@components/external-link/ExternalLink';
import statusModal from '@forms/components/modals/statusModal';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { ormErrorMsg } from '../_helper';
import { projectDescriptionTooltipContent, projectPurposeTooltipContent, natureOfActivityTooltipContent } from '@pages/Resources/components/_shared/helper';
import { PreAppRequestTypes } from '@src/utils/enums';
import { dateBeforeB, isValidORMNumber } from '@src/utils/helpers';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import Checkbox from '@components/checkbox/Checkbox';
import { Label } from '@trussworks/react-uswds';

export const PreAppForm3GeneralProjectInformationMetadata = {
  header: 'Pre-application/Project Scoping Request Form',
  sectionName: 'General Project Information',
};

const schema = yup.object().shape({
  projectName: yup.string().required('Field is required'),
  previousFileNumbersExist: yup.string().required('Please select an option'),
  previousFileNumbers: yup.array().when('previousFileNumbersExist', { is: 'true', then: () => yup.array().min(1, 'At least one Previous File Number is required').required('At least one Previous File Number is required') }),
  preAppRequestType: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', (val) => val.toUpperCase() !== 'DEFAULT_VALUE').required('Please select an option'),
  scopingCommentsDueDate: yup.string().when('preAppRequestType', { is: PreAppRequestTypes.ScopingResponse, then: () => yup.string().required('Please select a date') }).nullable(),
  projectDescription: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', (val) => val.toUpperCase() !== 'DEFAULT_VALUE').required('Field is required'),
  projectPurpose: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', (val) => val.toUpperCase() !== 'DEFAULT_VALUE').required('Field is required'),
  natureOfActivity: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', (val) => val.toUpperCase() !== 'DEFAULT_VALUE').required('Field is required'),
  isOtherLeadFederalAgency: yup.string().required('Please select an option'),
  isOtherApplicableLaw: yup.string().required('Please select an option').typeError('Please select an option'),
  applicableLaws: yup.array().when('isOtherApplicableLaw', { is: 'Yes', then: () => yup.array().min(1, 'Please select at least one applicable law').typeError('Please select at least one applicable law') }),
  speciesDetails: yup.string().when(['isOtherApplicableLaw', 'applicableLaws'], { is: (isOtherApplicableLaw, applicableLaws) => isOtherApplicableLaw === 'Yes' && applicableLaws.includes('isSpeciesPresent'), then: () => yup.string().required('Field is required') }).nullable(),
  govResourceConcernDetails: yup.string().when(['isOtherApplicableLaw', 'applicableLaws'], { is: (isOtherApplicableLaw, applicableLaws) => isOtherApplicableLaw === 'Yes' && applicableLaws.includes('isGovResourceConcern'), then: () => yup.string().required('Field is required') }).nullable(),
  historicalResourceConcernDetails: yup.string().when(['isOtherApplicableLaw', 'applicableLaws'], { is: (isOtherApplicableLaw, applicableLaws) => isOtherApplicableLaw === 'Yes' && applicableLaws.includes('isHistoricalResourceConcern'), then: () => yup.string().required('Field is required') }).nullable(),
  section408_Details: yup.string().when(['isOtherApplicableLaw', 'applicableLaws'], { is: (isOtherApplicableLaw, applicableLaws) => isOtherApplicableLaw === 'Yes' && applicableLaws.includes('isSection408_Required'), then: () => yup.string().required('Field is required') }).nullable(),
});

const PreAppForm3GeneralProjectInformation = connect(
  'doUpdateSectionValidity',
  'doSecondaryModalOpen',
  'doUpdateRequestData',
  'doUpdatePreAppRequest',
  'selectRequestFormData',
  'selectRequestAPIData',
  'selectPreAppData',
  'selectSteps',
  'selectActiveStep',
  'selectIsReadOnly',
  ({
    doUpdateSectionValidity,
    doSecondaryModalOpen,
    doUpdateRequestData,
    doUpdatePreAppRequest,
    requestFormData,
    requestAPIData,
    preAppData,
    steps,
    activeStep,
    isReadOnly,
    stepNo,
  }) => {
    const thisSectionStepStatus = useMemo(() => steps.find((step) => step.id === stepNo)?.touched, [steps, stepNo]);
    const convertFileNumbersToSelectFormat = (data) => data && data.map((obj) => ({ label: obj.ormFileNumber, value: obj.ormFileNumber }));
    const section408helper = (<> <p>The U.S. Army Corps of Engineers (USACE) Section 408 program provides permission for people, towns, or businesses to alter or impact a USACE project, like a dam, levee, or waterway.</p> <p>Before any changes can be made, the Section 408 program reviews alterations to ensure they won’t harm the project or the public.</p> <p>You can find more information about the Section 408 at <> <ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Section408/' /></></p></>);
    const convertBoolToApplicableLaws = () => {
      let boolArray = [];

      requestAPIData?.request?.preApplications?.[0]?.isSpeciesPresent === true && boolArray.push('isSpeciesPresent');
      requestAPIData?.request?.preApplications?.[0]?.isGovResourceConcern === true && boolArray.push('isGovResourceConcern');
      requestAPIData?.request?.preApplications?.[0]?.isHistoricalResourceConcern === true && boolArray.push('isHistoricalResourceConcern');
      requestAPIData?.request?.preApplications?.[0]?.isSection408_Required === true && boolArray.push('isSection408_Required');

      return boolArray;
    };

    const defaultValues = {
      projectName: requestFormData?.projectName,
      previousFileNumbersExist: requestFormData?.previousFileNumbers?.length > 0 ? 'true' : 'false',
      previousFileNumbers: requestFormData?.previousFileNumbers?.length > 0 ? convertFileNumbersToSelectFormat(requestFormData.previousFileNumbers) : [],
      projectDescription: requestFormData?.projectDescription ?? null,
      projectPurpose: requestFormData?.projectPurpose ?? null,
      natureOfActivity: requestFormData?.natureOfActivity ?? null,
      preAppRequestType: preAppData?.preAppRequestType ?? null,
      scopingCommentsDueDate: preAppData?.scopingCommentsDueDate?.slice(0, 10) ?? '',
      isOtherLeadFederalAgency: preAppData?.isOtherLeadFederalAgency ?? null,
      isOtherApplicableLaw: preAppData?.isOtherApplicableLaw ?? null,
      applicableLaws: convertBoolToApplicableLaws(),
      speciesDetails: preAppData?.speciesDetails ?? null,
      govResourceConcernDetails: preAppData?.govResourceConcernDetails ?? null,
      historicalResourceConcernDetails: preAppData?.historicalResourceConcernDetails ?? null,
      section408_Details: preAppData?.section408_Details ?? null,
    };

    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { errors, isValid }, setValue, watch, getValues, trigger } = methods;

    const preAppRequestType = watch('preAppRequestType', '');
    const previousFileNumbersExist = watch('previousFileNumbersExist', 'false');
    const previousFileNumbers = watch('previousFileNumbers', []);
    const isOtherApplicableLaw = watch('isOtherApplicableLaw');
    const applicableLaws = watch('applicableLaws');

    const handleSelectChange = (e) => {

      const results = e.map((num) => isValidORMNumber(num.value));

      if (!results.every(Boolean)) {
        setValue('previousFileNumbers', e.length > 1 ? previousFileNumbers : []);
        doSecondaryModalOpen(statusModal, { status: 'Failed', msg: ormErrorMsg });
      }
    };

    useEffect(() => {

      switch (isOtherApplicableLaw) {
        case 'Yes':
          break;
        case 'No':
          setValue('applicableLaws', []);
          setValue('speciesDetails', null);
          setValue('govResourceConcernDetails', null);
          setValue('historicalResourceConcernDetails', null);
          setValue('section408_Details', null);
          break;
        case 'I am not sure':
          setValue('applicableLaws', []);
          setValue('speciesDetails', null);
          setValue('govResourceConcernDetails', null);
          setValue('historicalResourceConcernDetails', null);
          setValue('section408_Details', null);
          break;
        default:
          break;
      }

    }, [isOtherApplicableLaw, setValue]);

    useEffect(() => {
      doUpdateSectionValidity(PreAppForm3GeneralProjectInformationMetadata.sectionName, isValid ? true : false, stepNo, isReadOnly);
    }, [isValid, doUpdateSectionValidity, stepNo, isReadOnly]);

    const handleChange = (e) => {

      trigger(e?.target?.value);

      if (e?.target?.name === 'previousFileNumbersExist' && e?.target?.value === 'false') {
        setValue('previousFileNumbers', []);
        doUpdateRequestData({ previousFileNumbers: [] });
        return;
      }

      if (!isReadOnly) {
        const values = getValues();
        const preAppValues = {
          preAppRequestType: values?.preAppRequestType,
          scopingCommentsDueDate: values?.scopingCommentsDueDate,
          isOtherLeadFederalAgency: values?.isOtherLeadFederalAgency,
          isOtherApplicableLaw: values?.isOtherApplicableLaw,
          speciesDetails: values?.speciesDetails,
          govResourceConcernDetails: values?.govResourceConcernDetails,
          historicalResourceConcernDetails: values?.historicalResourceConcernDetails,
          section408_Details: values?.section408_Details,
          hasAddPropertyOwners: values?.hasAddPropertyOwners,
          hasSupportingDocuments: values?.hasSupportingDocuments,
        };
        const prevFileNumbers = values?.previousFileNumbersExist === 'false' ? [] : values?.previousFileNumbers?.length > 0 ? values?.previousFileNumbers?.map((val) => ({ ormFileNumber: val?.value?.toUpperCase() })) : [];
        doUpdateRequestData({ projectName: values?.projectName, previousFileNumbers: prevFileNumbers, projectDescription: values?.projectDescription, projectPurpose: values?.projectPurpose, natureOfActivity: values?.natureOfActivity });
        doUpdatePreAppRequest(preAppValues);
      }
    };

    useEffect(() => {
      const convertApplicableLawsToBool = () => {

        const bools = {
          isSpeciesPresent: applicableLaws.includes('isSpeciesPresent') ? true : false,
          isGovResourceConcern: applicableLaws.includes('isGovResourceConcern') ? true : false,
          isHistoricalResourceConcern: applicableLaws.includes('isHistoricalResourceConcern') ? true : false,
          isSection408_Required: applicableLaws.includes('isSection408_Required') ? true : false,
        };

        doUpdatePreAppRequest(bools);
      };
      applicableLaws && convertApplicableLawsToBool();
    }, [applicableLaws, doUpdatePreAppRequest]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }

        <FieldHeader text='General Project Information' />

        <h6 className='border-bottom width-full padding-bottom-1 margin-top-2'>Project</h6>
        <div className='ml-2'><TextInput label='Project Name' name='projectName' type='text' required tooltip='Please provide name identifying the proposed project, e.g., Landmark Plaza, Burned Hills Subdivision, or Edsall Commercial Center' readOnly={isReadOnly} onChange={handleChange} /></div>

        <div className='ml-2'><SelectInput name='previousFileNumbersExist' label='Has the USACE previously issued a file number for any part of the project area?' required className='w-50' readOnly={isReadOnly} onChange={handleChange}>
          <option key='2' value='true'>Yes</option>
          <option key='3' value='false'>No</option>
        </SelectInput></div>

        {previousFileNumbersExist === 'true' && <>

          <div className='ml-2'>
            <CreatableSelectInput name='previousFileNumbers' label='Previous File Number(s)' tooltip='Enter the file number and press "ENTER" or click on "Create ..."' placeholder='e.g. MVS-2023-12345, MVM-2020-1234 ...' required multi handleChange={handleSelectChange} handleBlur={handleChange} readOnly={isReadOnly} />
          </div>

        </>}

        <div className='ml-2'>
          <SelectInput name='preAppRequestType' label='Pre-application Request Type' required className='w-50' readOnly={isReadOnly} onChange={handleChange}>
            <option key='2' value={PreAppRequestTypes.StandardPermit}>Standard Permit</option>
            <option key='3' value={PreAppRequestTypes.MitigationBankOrFee}>Mitigation bank or in-lieu fee pre-application meeting</option>
            <option key='4' value={PreAppRequestTypes.ScopingResponse}>Scoping response for environmental compliance</option>
            <option key='5' value={PreAppRequestTypes.NeedAdvice}>I need advice on what type of permit is applicable to my project</option>
          </SelectInput>
        </div>

        {preAppRequestType === PreAppRequestTypes.ScopingResponse && <>
          <div className='ml-2'>
            <DatePicker style={{ width: '50%' }} name='scopingCommentsDueDate' label='Date Scoping Comments Due' min={dateBeforeB} required readOnly={isReadOnly} onChange={handleChange} />
          </div>
        </>
        }
        <h6 className='border-bottom width-full padding-bottom-1 margin-top-3'>Project Info</h6>

        <div className='ml-2'>
          <TextAreaInput name='projectDescription' label='Project Description' tooltip={projectDescriptionTooltipContent} readOnly={isReadOnly} onChange={handleChange} required />
        </div>
        <div className='ml-2'>
          <TextAreaInput name='projectPurpose' label='Project Purpose' tooltip={projectPurposeTooltipContent} readOnly={isReadOnly} onChange={handleChange} required />
        </div>
        <div className='ml-2'>
          <TextAreaInput name='natureOfActivity' label={'Nature of Activity'} tooltip={natureOfActivityTooltipContent} readOnly={isReadOnly} onChange={handleChange} required />
        </div>
        <div className='ml-2'>
          <SelectInput name='isOtherLeadFederalAgency' label='Is there another lead Federal Agency?' required className='w-50' readOnly={isReadOnly} onChange={handleChange}>
            <option key='2' value='Yes'>Yes</option>
            <option key='3' value='No'>No</option>
            <option key='4' value='I am not sure'>I'm not sure</option>
          </SelectInput>
        </div>

        <div className='ml-2'>
          <SelectInput name='isOtherApplicableLaw' label='Are any of the items listed below present?' required className='w-50' readOnly={isReadOnly} onChange={handleChange}>
            <option key='2' value='Yes'>Yes</option>
            <option key='3' value='No'>No</option>
            <option key='4' value='I am not sure'>I'm not sure</option>
          </SelectInput>
        </div>

        {isOtherApplicableLaw !== 'Yes' &&
          <ul>
            <li>Endangered Species Act-subject species</li>
            <li>Tribal government resource concerns or issues</li>
            <li>Cultural or historical resource concerns or issues</li>
            <li>Section 408<Tooltip clickable name='section-408' content={section408helper} /> (USACE project) permission required</li>
          </ul>
        }

        {isOtherApplicableLaw === 'Yes' &&
        <>
          <fieldset className='margin-left-0 margin-bottom-0 w-50' id='applicableLaws'>
            <Label htmlFor='applicableLaws'>
              <span id='applicableLaws_label'>Select any applicable laws (at least one required)</span>
              <span className='asterisk-color'>*</span>:
            </Label>
            <Checkbox
              disabled={isReadOnly}
              id='applicableLaws-isSpeciesPresent'
              label='Federally listed/proposed threatened/endangered species or habitat may be present on site'
              name='applicableLaws'
              onChange={() => trigger('speciesDetails')}
              value='isSpeciesPresent'
              tile
            />
            <Checkbox
              disabled={isReadOnly}
              id='applicableLaws-isGovResourceConcern'
              label='Known tribal government resources issues'
              name='applicableLaws'
              onChange={() => trigger('govResourceConcernDetails')}
              value='isGovResourceConcern'
              tile
            />
            <Checkbox
              disabled={isReadOnly}
              id='applicableLaws-isHistoricalResourceConcern'
              label='Known cultural or historical resource issue'
              name='applicableLaws'
              onChange={() => trigger('historicalResourceConcernDetails')}
              value='isHistoricalResourceConcern'
              tile
            />
            <Checkbox
              disabled={isReadOnly}
              id='applicableLaws-isSection408_Required'
              label='Known Section 408 (USACE projects) permission required'
              name='applicableLaws'
              onChange={() => trigger('section408_Details')}
              value='isSection408_Required'
              tile
            />
          </fieldset>
          {applicableLaws.includes('isSpeciesPresent') && <div className='mt-2 ml-3'><TextAreaInput name='speciesDetails' label='List any federally listed/proposed threatened/endangered species or habitat that may be present on site' required onChange={handleChange} readOnly={isReadOnly} /></div>}
          {applicableLaws.includes('isGovResourceConcern') && <div className='mt-2 ml-3'><TextAreaInput name='govResourceConcernDetails' label='Describe any known tribal government resource concerns or issues' required onChange={handleChange} readOnly={isReadOnly} /></div>}
          {applicableLaws.includes('isHistoricalResourceConcern') && <div className='mt-2 ml-3'><TextAreaInput name='historicalResourceConcernDetails' label='Describe any known cultural or historical resource issues' required onChange={handleChange} readOnly={isReadOnly} /></div>}
          {applicableLaws.includes('isSection408_Required') && <div className='mt-2 ml-3'><TextAreaInput name='section408_Details' label='Describe any known Section 408 (USACE project) permission requirements' required onChange={handleChange} readOnly={isReadOnly} /></div>}
        </>}
      </FormProvider >
    );
  });

PreAppForm3GeneralProjectInformation.metadata =
  PreAppForm3GeneralProjectInformationMetadata;

export default PreAppForm3GeneralProjectInformation;
