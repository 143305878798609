/*
 * - This file is used to create a new instance of QueryClient
 * - The queryClient is consumed by the QueryClientProvider at the top of the app
 * - This makes react-query available throughout the app
 */

import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  },
});
