import ReactDOM from 'react-dom/client';
import { Provider } from 'redux-bundler-react';
import { getNavHelper } from 'internal-nav-helper';
import NavbarNavigateWarningModal from '@pages/Forms/components/modals/NavbarNavigateWarningModal';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from '@api/queryClient';

import App from './App';
import cache from './cache';
import getStore from './app-bundles';

const enhancedNavHelper = (store) => {
  const originalNavHelper = getNavHelper(store.doUpdateRelativeUrl);

  return (event) => {
    let target = event.target;
    while (target && target.nodeName !== 'A') {
      target = target.parentNode;
    }

    if (target && target.getAttribute('href')) {
      const targetPath = target.getAttribute('href');
      const currentPath = store.selectRelativePathname();

      if (currentPath.startsWith('/forms/') && !targetPath.startsWith('/forms/')) {
        event.preventDefault(); // Prevent default navigation
        const openInNewTab = target.getAttribute('target') === '_blank';
        store.doModalOpen(NavbarNavigateWarningModal, { url: targetPath, openInNewTab: openInNewTab });
      } else {
        originalNavHelper(event); // Proceed with normal navigation
      }
    }
  };
};

cache.getAll().then((initialData) => {
  const store = getStore(initialData);
  const root = ReactDOM.createRoot(document.getElementById('root'));
  const navHelper = enhancedNavHelper(store);

  if (import.meta.env.VITE_ENVIRONMENT === 'local') window.store = store;

  root.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <div onClick={navHelper}>
          <App />
        </div>
      </Provider>
      <ReactQueryDevtools buttonPosition='bottom-left' initialIsOpen={false} />
    </QueryClientProvider>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
