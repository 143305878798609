import { useEffect, useMemo } from 'react';
import { connect } from 'redux-bundler-react';
import './errorSummary.scss';

import { getLabelTextById } from '@src/utils/helpers';
import classNames from 'classnames';
import ErrorSummaryBase from './ErrorSummaryBase';
import { boldError, cleanAndTruncateLabels, scrollById } from './errorSummary.utils';

const typeSuffixes = {
  modal: '_modal',
  form: 'form',
};

// for server-side validation errors
const apiValidationRenderer = (error, index) => (
  <li key={index}>
    <p className='margin-bottom-0 text-bold'>{error.sectionTitle}</p>
    <ul className='api-error-list'>
      {Object.keys(error.errors)?.map((key, index) => (
        <li key={index}>{`${key}: ${error.errors[key]}`}</li>
      ))}
    </ul>
  </li>
);

const ErrorSummary = connect(
  'doSetActiveStep',
  'selectFormValidation',
  ({ defaultExpanded, doSetActiveStep, formValidation, errors, type = 'base', modalID, sectionNo = 0 }) => {
    const typeSuffix = typeSuffixes[type] || sectionNo;
    const isModal = type === 'modal';
    const isForm = type === 'form';
    const isApi = type === 'api-validation';
    const errorString = Object.keys(errors).reduce((acc, key) => acc + key + errors[key].message, '');

    const genericErrorData = useMemo(
      () =>
        Object.keys(errors).reduce(
          (acc, key) =>
            errors[key].message
              ? [
                  ...acc,
                  {
                    id: `${key}_li${typeSuffix}`,
                    onClick: () => scrollById(key, modalID, typeSuffix),
                    text: (cleanAndTruncateLabels(getLabelTextById(key), 40) ?? '') + errors[key].message,
                  },
                ]
              : acc,
          []
        ),
      [errorString, modalID, typeSuffix] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const sectionErrorData = useMemo(() => {
      const steps = formValidation && Object?.values(formValidation);
      const finalSection = 'Certify, Sign, and Submit';

      return steps
        ?.map((step, index) => ({
          id: step.validity === false ? `${index}_li${typeSuffix}` : null, // set id to null step should not be displayed - allows filtering without adding extra property or mapping twice
          onClick: () => doSetActiveStep(index),
          text: step?.section,
          validity: step?.validity,
        }))
        ?.filter((step) => step.id && step.section !== finalSection);
    }, [formValidation, doSetActiveStep, typeSuffix]);

    const apiErrorData = useMemo(
      () =>
        isApi
          ? errors?.map((error) => ({
              renderFunction: (error, index) => apiValidationRenderer(error),
              errorData: error,
            }))
          : [],
      [errorString, isApi] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const errorData = useMemo(() => {
      if (isForm) {
        return sectionErrorData;
      } else if (isApi) {
        return apiErrorData;
      } else {
        return genericErrorData;
      }
    }, [apiErrorData, genericErrorData, isApi, isForm, sectionErrorData]);

    const genericErrorCount = genericErrorData?.length;
    const sectionErrorCount = sectionErrorData?.length;
    const hasGenericErrors = genericErrorCount > 0;
    const hasSectionErrors = sectionErrorCount > 0;
    const hasFormErrors = isForm && hasSectionErrors;
    const hasApiErrors = isApi && errors?.length > 0;
    const hasErrors = hasGenericErrors || hasFormErrors || hasApiErrors;
    const summaryStyles = classNames({
      'modal-variant': isModal,
    });

    const alertTitle = useMemo(() => {
      const genericCountText = `Resolve the following ${genericErrorCount === 1 ? 'issue' : `${genericErrorCount} issues`}`;
      const sectionCountText = `This request is incomplete. Resolve the following ${sectionErrorCount === 1 ? 'issue' : `${sectionErrorCount} issues`}`;
      const sectionCompleteText = 'This section is complete';

      switch (type) {
        case 'modal':
          return hasGenericErrors ? genericCountText : null;

        case 'form':
          if (hasSectionErrors) {
            return sectionCountText;
          } else if (hasGenericErrors) {
            return genericCountText;
          } else {
            return sectionCompleteText;
          }

        case 'api-validation':
          const errorCount = errors.reduce(
            (apiCount, apiError) =>
              (apiCount += Object.keys(apiError.errors).reduce(
                (errorCount, key) => (apiError.errors[key] ? (errorCount += 1) : errorCount),
                0
              )),
            0
          );
          return errors
            ? `Resolve the following ${errorCount === 1 ? 'issue' : `${errorCount} issues`}`
            : 'This request is complete';

        default:
          return hasGenericErrors ? genericCountText : sectionCompleteText;
      }
    }, [errors, genericErrorCount, hasGenericErrors, hasSectionErrors, sectionErrorCount, type]);

    useEffect(() => {
      const handleBlur = (e) => {
        boldError(e, typeSuffix);
      };
      document.addEventListener('focus', handleBlur, true);

      return () => document.removeEventListener('focus', handleBlur, true);
    }, [typeSuffix]);

    if (isModal && !hasErrors) return null; // don't display success message for modals

    return (
      <ErrorSummaryBase
        className={summaryStyles}
        defaultExpanded={defaultExpanded}
        errors={errorData}
        hasErrors={hasErrors}
        title={alertTitle}
        typeSuffix={typeSuffix}
      />
    );
  }
);

export default ErrorSummary;
