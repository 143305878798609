import Icon from '@components/icon/Icon';
import {
  mdiCloseCircle,
  mdiCheckboxMarkedCircle,
  mdiCheckboxBlankCircle,
  mdiCheckboxBlankCircleOutline,
} from '@mdi/js';

import './Step.scss';
import classnames from 'classnames';

const Step = ({ children, status, touched, onClick, id, clickId, isSubStep }) => {
  let icon = '';
  let classes = '';

  switch (true) {
    case status === 'error' && touched === 'true':
      icon = mdiCloseCircle;
      classes = 'is-error';
      break;
    case status === 'complete' && touched === 'true':
      icon = mdiCheckboxMarkedCircle;
      classes = 'is-complete';
      break;
    case status === 'activecomplete':
      icon = mdiCheckboxMarkedCircle;
      classes = 'is-active is-complete';
      break;
    case status === 'activeerror' && touched === 'true':
      icon = mdiCloseCircle;
      classes = 'is-active is-error';
      break;
    case status === 'activeerror':
      icon = mdiCheckboxBlankCircle;
      classes = 'is-active is-error';
      break;
    default:
      icon = mdiCheckboxBlankCircleOutline;
      classes = '';
  }

  const handleKeyUp = (e) => {
    e.key === 'Enter' && onClick(e);
  };

  const containerStyles = classnames('step-container v-stepper', classes, {
    'sub-step': isSubStep,
  });

  return (
    <div
      className={containerStyles}
      data-clickId={clickId}
      id={id}
      onClick={onClick}
      onKeyUp={handleKeyUp}
      tabIndex={0}
    >
      <div className='step-line'>
        <div />
      </div>
      <div className='step-icon'>
        <Icon focusable={false} path={icon} size={'21px'} />
      </div>
      <div className='step-label'>{children}</div>
    </div>
  );
};

export default Step;
