import { useEffect, useState } from 'react';
import { useJpa } from '@src/stores';
import { connect } from 'redux-bundler-react';

import { ModalContent, ModalTabContainerNewRequest } from '@components/modal';
import ProjectLocationTab from './modal-tabs/project-location-tab/ProjectLocationTab';
import ProjectConfirmationTab from './modal-tabs/ProjectConfirmationTab';
import Questions from '../not-sure/questions';

import '@pages/Home/home.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { RequestTypeStrings } from '@src/utils/enums';
import { latRegex, lngRegex, cityRegex, zipCodeRegex, decimalNumberRegex } from '@src/utils/regex';
import { formatCoordFlt } from '@src/utils/helpers';
import { useQuery } from '@tanstack/react-query';
import { JpaQueries, JPA_QUERY_KEYS } from '@src/api/services/jpa';

const NotSureModal = connect(
  'doFetchGPPermitsByDistrict',
  'doFetchCreditUnitsByDistrict',
  'doUpdateSelectedRequest',
  'doResetReduxFormData',
  'doUpdateRequestData',
  'doUpdateJDRequest',
  'doUpdateProjectAddressObject',
  'doUpdateComplexStateField',
  'doAddPreApp',
  'doAddJD',
  'doAddPermit',
  'selectProjectDetails',
  'selectProjectGeometry',
  'selectDistrictResults',
  'selectPlssResults',
  'selectRequestFormDataLocation',
  ({
    doFetchGPPermitsByDistrict,
    doFetchCreditUnitsByDistrict,
    doUpdateSelectedRequest,
    doResetReduxFormData,
    doUpdateRequestData,
    doUpdateJDRequest,
    doUpdateProjectAddressObject,
    doUpdateComplexStateField,
    doAddPreApp,
    doAddJD,
    doAddPermit,
    projectDetails,
    projectGeometry,
    districtResults,
    plssResults,
    requestFormDataLocation,
  }) => {
    const { setJpaOrgIds, jpaOrgIds } = useJpa();
    const [projectOutsideUS, setProjectOutsideUS] = useState(false);
    const district = requestFormDataLocation?.district ?? districtResults?.[0];

    const schema = yup
      .object()
      .shape({
        projectName: yup.string().required('Field is required'),
        previousFileNumbersExist: yup.string().required('Please select an option'),
        previousFileNumbers: yup.array().when('previousFileNumbersExist', {
          is: 'true',
          then: () =>
            yup
              .array()
              .min(1, 'At least one Previous File Number is required')
              .required('At least one Previous File Number is required'),
          otherwise: () =>
            yup.array().when('previousFileNumbersExist', {
              is: 'false',
              then: () => yup.array().nullable(),
            }),
        }),
        showAddressInput: yup.boolean().nullable(),
        showMapInput: yup.boolean().nullable(),
        showGDBInput: yup.boolean().nullable(),
        addressInputType: yup
          .string()
          .nullable()
          .when('showAddressInput', {
            is: (showAddressInput) => showAddressInput === true,
            then: () => yup.string().required('Please select an option'),
          }),
        address: yup
          .string()
          .nullable()
          .when(['addressInputType', 'showAddressInput'], {
            is: (addressType, showAddressInput) => addressType?.includes('address') && showAddressInput === true,
            then: () => yup.string().required('Field is required'),
          }),
        addressTwo: yup.string().nullable(),
        city: yup
          .string()
          .nullable()
          .when(['addressInputType', 'showAddressInput'], {
            is: (addressType, showAddressInput) => addressType?.includes('address') && showAddressInput === true,
            then: () =>
              yup
                .string()
                .matches(cityRegex, { message: 'Field is invalid', excludeEmptyString: true })
                .required('Field is required'),
          }),
        state: yup
          .string()
          .nullable()
          .when(['addressInputType', 'showAddressInput'], {
            is: (addressType, showAddressInput) => addressType?.includes('address') && showAddressInput === true,
            then: () => yup.string().required('Field is required'),
          }),
        zipcode: yup
          .string()
          .nullable()
          .when(['addressInputType', 'showAddressInput'], {
            is: (addressType, showAddressInput) => addressType?.includes('address') && showAddressInput === true,
            then: () =>
              yup
                .string()
                .matches(zipCodeRegex, { message: 'Field is invalid', excludeEmptyString: true })
                .required('Field is required'),
          }),
        county: yup.string().nullable().matches(cityRegex, { message: 'Field is invalid', excludeEmptyString: true }),
        country: yup
          .string()
          .nullable()
          .when(['addressInputType', 'showAddressInput'], {
            is: (addressType, showAddressInput) => addressType?.includes('address') && showAddressInput === true,
            then: () => yup.string().required('Field is required'),
          }),
        latitude: yup
          .string()
          .nullable()
          .when(['addressInputType', 'showAddressInput'], {
            is: (addressType, showAddressInput) => addressType?.includes('coordinates') && showAddressInput === true,
            then: () =>
              yup
                .string()
                .required('Field is required')
                .test({
                  name: 'latInRange',
                  exclusive: true,
                  message: 'Latitude must be between 0 and 90',
                  test: (val) => (!projectOutsideUS ? Number(val) <= 90 && Number(val) >= 0 : true),
                })
                .test(
                  'latFormat',
                  'Latitude format is incorrect. Must be +-XX.XXXXXX and include at least 6 decimal places.',
                  (val) => latRegex.test(val)
                ),
          }),
        longitude: yup
          .string()
          .nullable()
          .when(['addressInputType', 'showAddressInput'], {
            is: (addressType, showAddressInput) => addressType?.includes('coordinates') && showAddressInput === true,
            then: () =>
              yup
                .string()
                .required('Longitude is required')
                .test({
                  name: 'lngInRange',
                  exclusive: true,
                  message: 'Longitude must be between -180 and 0',
                  test: (val) => (!projectOutsideUS ? Number(val) <= 0 && Number(val) >= -180 : true),
                })
                .test(
                  'lngFormat',
                  'Longitude format is incorrect. Must be +-XXX.XXXXXX and include at least 6 decimal places.',
                  (val) => lngRegex.test(val)
                ),
          }),
        projectArea: yup
          .string()
          .matches(decimalNumberRegex, { message: 'Area is invalid', excludeEmptyString: true })
          .required('Field is required'),
        unitOfMeasurement: yup.string().required('Please select an option'),
        parcelNumber: yup.string().nullable(),
        nearestWaterbody: yup.string().nullable(),
        fieldOfficeCode: yup.string().nullable(),
        siteDirections: yup.string().nullable(),
        plssSection: yup.string().nullable(),
        plssTownship: yup.string().nullable(),
        plssRange: yup.string().nullable(),
        projectLocationGDBFile: yup.string().nullable(),
      })
      .test('at-least-one-input', 'Please select a value for either Address, Map, or GDB input', function (value) {
        const { showAddressInput, showMapInput, showGDBInput } = value;
        if (!showAddressInput && !showMapInput && !showGDBInput) {
          return this.createError({
            path: 'inputType',
            message: 'Please select a value for either Address, Map, or GDB input',
          });
        }
        return true;
      })
      .test('geometry-required', 'Map: Please add a point, line or polygon to the map', function (value) {
        const { showMapInput, latitude, longitude } = value;
        if (showMapInput && (!latitude || !longitude)) {
          return this.createError({
            path: 'mapScroll',
            message: 'Map: Please add a point, line or polygon to the map',
          });
        }
        return true;
      });

    const [activeTab, setActiveTab] = useState(0);

    const defaultValues = {
      country: 'US',
      showAddressInput: false,
      showGDBInput: false,
      showMapInput: false,
    };
    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur', defaultValues: defaultValues });
    const { watch, handleSubmit, trigger } = methods;
    const requestType = watch('requestType');
    const showGDBInput = watch('showGDBInput');
    const showMapInput = watch('showMapInput');
    const addressInputType = watch('addressInputType');
    const state = watch('state');

    const { data: orgData } = useQuery({
      queryKey: [JPA_QUERY_KEYS.GET_ORG, state, district, requestFormDataLocation?.geometry],
      queryFn: () =>
        JpaQueries.getOrgs({
          state,
          district,
          geometry: requestFormDataLocation?.geometry,
        }).then((data) => {
          const orgIds = data?.map((org) => org?.jpaOrgID);
          setJpaOrgIds(orgIds);

          return data;
        }),
      enabled: activeTab === 2,
    });

    const getRequestType = () => {
      const latestRequestType = watch('requestType');
      if (Array.isArray(requestType)) {
        if (latestRequestType?.filter((type) => type === '1' || type === '2')?.length === 2) {
          return '1';
        } else {
          return latestRequestType?.[0];
        }
      }
    };

    useEffect(() => {
      doResetReduxFormData();
    }, [doResetReduxFormData]);

    const latitude = watch('latitude');
    const longitude = watch('longitude');

    useEffect(() => {
      latitude && trigger('latitude');
      longitude && trigger('longitude');
    }, [projectOutsideUS, trigger, latitude, longitude]);

    const onSave = (e) => {
      doUpdateComplexStateField({ name: 'isnewrequest', value: true });

      const requestValues = {
        requestType: RequestTypeStrings[getRequestType()],
      };

      const locationValues = {
        latitude: formatCoordFlt(e?.latitude),
        longitude: formatCoordFlt(e?.longitude),
        address: e?.address,
        addressTwo: e?.addressTwo,
        city: e?.city,
        state: e?.state,
        zipcode: e?.zipcode,
        county: e?.county,
        country: e?.country,
        district: requestFormDataLocation?.district ?? districtResults?.[0],
        fieldOfficeCode: e?.fieldOfficeCode,
        plssSection: plssResults?.FRSTDIVNO,
        plssTownship: plssResults?.TWNSHPNO,
        plssRange: plssResults?.RANGENO,
        projectArea: Number(e?.projectArea),
        unitOfMeasurement: e?.unitOfMeasurement,
        siteDirections: e?.siteDirections,
        parcelNumber: e?.parcelNumber,
        nearestWaterbody: e?.nearestWaterbody,
        geometry: projectGeometry,
      };

      const previousFileNumbers =
        e?.previousFileNumbers?.length > 0
          ? e?.previousFileNumbers?.map((num) => ({
              ormFileNumber: num?.value?.toUpperCase(),
            }))
          : [];

      const baseMappedValues = {
        projectName: e?.projectName,
        projectID: projectDetails ? projectDetails?.projectID : undefined,
        requestID: projectDetails ? projectDetails?.requestID : undefined,
        version: projectDetails ? projectDetails?.version : undefined,
        request: { ...requestValues },
        location: { ...locationValues },
        hasPreviousFileNumber: e?.previousFileNumbersExist,
        previousFileNumbers: e?.previousFileNumbers?.length > 0 ? [...previousFileNumbers] : undefined,
      };

      const permitsMappedValues = {
        permit: baseMappedValues,
        jpaOrgIds: jpaOrgIds,
      };

      const jdMappedValues = {
        ...baseMappedValues,
        request: {
          requestType:
            requestType?.length > 1
              ? 'Delineation Report and Jurisdictional Determination'
              : getRequestType() === '2'
                ? 'Jurisdictional Determination'
                : 'Delineation Report',
          jdRequests: [
            {
              requestType:
                requestType?.length > 1
                  ? 'Delineation Report and Jurisdictional Determination'
                  : getRequestType() === '2'
                    ? 'Jurisdictional Determination'
                    : 'Delineation Report',
            },
          ],
        },
      };

      doUpdateProjectAddressObject({
        projectName: e?.projectName,
        ...locationValues,
      });

      doUpdateRequestData(getRequestType() === '1' || getRequestType() === '2' ? jdMappedValues : baseMappedValues);
      (getRequestType() === '1' || getRequestType() === '2') &&
        doUpdateJDRequest({
          requestType:
            requestType?.length > 1
              ? 'Delineation Report and Jurisdictional Determination'
              : getRequestType() === '2'
                ? 'Jurisdictional Determination'
                : 'Delineation Report',
        });

      switch (getRequestType()) {
        case '1':
          doUpdateSelectedRequest('1');
          doAddJD(jdMappedValues);
          break;
        case '2':
          doUpdateSelectedRequest('2');
          doAddJD(jdMappedValues);
          break;
        case '3':
          doUpdateSelectedRequest('3');
          doAddPermit(permitsMappedValues);
          doFetchGPPermitsByDistrict(requestFormDataLocation?.district ?? districtResults?.[0]);
          doFetchCreditUnitsByDistrict(requestFormDataLocation?.district ?? districtResults?.[0]);
          break;
        case '4':
          doUpdateSelectedRequest('4');
          doAddPermit(permitsMappedValues);
          doFetchGPPermitsByDistrict(requestFormDataLocation?.district ?? districtResults?.[0]);
          doFetchCreditUnitsByDistrict(requestFormDataLocation?.district ?? districtResults?.[0]);
          break;
        case '5':
          doUpdateSelectedRequest('5');
          doAddPreApp(baseMappedValues);
          break;
        default:
          console.error('INVALID ENDPOINT');
          break;
      }
    };

    return (
      <ModalContent className='modal-resize' title={"I'm Not Sure"}>
        <FormProvider {...methods}>
          <ModalTabContainerNewRequest
            setActiveTab={setActiveTab}
            tabs={[
              {
                content: <ProjectLocationTab activeTab={activeTab} setProjectOutsideUS={setProjectOutsideUS} />,
                setDisabled:
                  !(addressInputType === 'coordinates' || addressInputType === 'address') &&
                  !showMapInput &&
                  !showGDBInput,
              },
              {
                content: <Questions />,
                setDisabled: !!(requestType === null),
              },
              {
                content: (
                  <ProjectConfirmationTab requestType={getRequestType()} activeTab={activeTab} orgData={orgData} />
                ),
                setDisabled: false,
              },
            ]}
            onSave={handleSubmit(onSave)}
          />
        </FormProvider>
      </ModalContent>
    );
  }
);

export default NotSureModal;
