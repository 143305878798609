import { toast } from 'react-toastify';
import statusModal from '@pages/Forms/components/modals/statusModal';
import submissionModal from '@pages/Forms/components/modals/submission';
import { useJpa } from '@stores';

import * as turf from '@turf/turf';

import L from 'leaflet';

const handleSuccess = (signed, permitType, body, store, dispatch, submissionModal) => {
  signed && toast.success(`${permitType} Request has been successfully submitted!`);
  signed &&
    store.doModalOpen(submissionModal, {
      msg: body?.msg,
      status: body?.status ?? 'Success',
    });
  signed && store.doUpdateRelativeUrl('/dashboard');
  !signed && toast.success(`${permitType} Request has been successfully saved!`);
  !signed && dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: body?.data });
  !signed && dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: body?.data });
  !signed && dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: 'Draft' } });
};

const PermitsDataBundle = {
  name: 'PermitFormData',

  getReducer: () => {
    const initialState = {
      permits: [],
      gpPermitTypes: [],
      errors: {},
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case 'RESET_PERMIT':
          return initialState;
        case 'RESET_PERMIT_SECTION':
          return {
            ...state,
            permits: [
              {
                ...state.permits[0],
                [payload]: {},
              },
            ],
          };
        case 'UPDATE_PERMITS':
          return {
            ...state,
            permits: [{ ...state.permits[0], ...payload }],
          };
        case 'UPDATE_PERMITS_SUB':
          return {
            ...state,
            permits: [
              {
                ...state.permits[0],
                [payload.section]: {
                  ...state.permits[0][payload.section],
                  ...payload.data,
                },
              },
            ],
          };
        case 'CLEAR_PILE_DATA_BY_USE': {
          const { pileUse, dataTypesToClear } = payload;

          const updatedPermits = state.permits.map((permit) => {
            let newEsaConsultantInformation = { ...permit.esaConsultantInformation };

            // Only remove items that match the specified pileUse
            if (dataTypesToClear.includes('pileInstallMethods')) {
              newEsaConsultantInformation.pileInstallMethods = newEsaConsultantInformation?.pileInstallMethods?.filter(
                (method) => method.pileUse !== pileUse
              );
            }
            if (dataTypesToClear.includes('pileTypes')) {
              newEsaConsultantInformation.pileTypes = newEsaConsultantInformation?.pileTypes?.filter(
                (type) => type.pileUse !== pileUse
              );
            }
            if (dataTypesToClear.includes('pileDetails')) {
              newEsaConsultantInformation.pileDetails = newEsaConsultantInformation?.pileDetails?.filter(
                (detail) => detail.pileUse !== pileUse
              );
            }

            return {
              ...permit,
              esaConsultantInformation: newEsaConsultantInformation,
            };
          });

          return {
            ...state,
            permits: updatedPermits,
          };
        }
        case 'UPDATE_GP_PERMIT_TYPES':
          return {
            ...state,
            gpPermitTypes: payload,
          };
        case 'UPDATE_PERMITS_ERRORS':
          return { ...state, errors: payload };
        default:
          return state;
      }
    };
  },

  selectPermitData: (state) => state.PermitFormData.permits[0],
  selectPermitRequestID: (state) => state.PermitFormData.permits?.[0]?.permitID,
  selectGPPermitTypes: (state) => state.PermitFormData.gpPermitTypes,
  selectPermitErrors: (state) => state.PermitFormData.errors,

  doFetchPermit:
    (projectID, requestID, permitID, type, requestStatus, isGenerate = false) =>
    ({ apiGet, dispatch, store }) => {
      const permitType = type === '4' ? 'Nationwide Permit (ENG 6082)' : 'Individual Permit (ENG 4345)';
      !isGenerate && store.doSetLoadingState(true);
      !isGenerate && store.doSetLoadingMessage(`Retrieving ${permitType} Request Information...`);

      store.doResetJDFormData();
      store.doResetRequestData();

      const uri =
        '/api/Permit/getPermit?' +
        new URLSearchParams({
          projectID,
          requestID,
          permitID,
        });

      return apiGet(uri, (err, body) => {
        !isGenerate && store.doSetLoadingMessage('Loading...');
        if (!err && body.status === 'Success') {
          const responseData = body?.data;
          const requestData = responseData?.request;
          const jpaBasicInfo = requestData?.jpaBasicInfo;
          const projectLocation = responseData?.location;

          dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: responseData });
          dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: responseData });
          dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: requestStatus } });

          useJpa.setState({ jpaBasicInfo });

          dispatch({
            type: 'UPDATE_PERMITS',
            payload: requestData?.permits?.filter((permit) => permit.permitID === permitID)[0],
          });
          if (isGenerate) {
            store.doGenerateDraftDocuments();
          } else {
            store.doUpdateComplexStateField({ name: 'projectGeometry', value: projectLocation?.geometry });
            let bbox;
            let bounds;
            // ONLY EXECUTE SWITCH STATEMENT IF VALUE EXISTS
            switch (projectLocation?.geometry?.type) {
              case 'Point':
                //Calculate Centroid
                const point = turf.point(projectLocation?.geometry?.coordinates);
                const radius = turf.buffer(point, 5, { units: 'miles' });
                bbox = turf.bbox(radius);
                bounds = L.latLngBounds([
                  { lat: bbox[1], lng: bbox[0] },
                  { lat: bbox[3], lng: bbox[2] },
                ]);
                store.doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
                break;
              case 'Polygon':
                //Calculate Centroid
                const polygon = turf.polygon(projectLocation?.geometry?.coordinates);
                bbox = turf.bbox(polygon);
                bounds = L.latLngBounds([
                  { lat: bbox[1], lng: bbox[0] },
                  { lat: bbox[3], lng: bbox[2] },
                ]);
                store.doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
                break;
              case 'MultiPolygon':
                //Calculate Centroid
                const multiPolygon = turf.multiPolygon(projectLocation?.geometry?.coordinates);
                bbox = turf.bbox(multiPolygon);
                bounds = L.latLngBounds([
                  { lat: bbox[1], lng: bbox[0] },
                  { lat: bbox[3], lng: bbox[2] },
                ]);
                store.doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
                break;
              case 'LineString':
                //Calculate Centroid
                const lineString = turf.lineString(projectLocation?.geometry?.coordinates);
                bbox = turf.bbox(lineString);
                bounds = L.latLngBounds([
                  { lat: bbox[1], lng: bbox[0] },
                  { lat: bbox[3], lng: bbox[2] },
                ]);
                store.doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
                break;
              case 'MultiLineString':
                //Calculate Centroid
                const multiLineString = turf.multiLineString(projectLocation?.geometry?.coordinates);
                bbox = turf.bbox(multiLineString);
                bounds = L.latLngBounds([
                  { lat: bbox[1], lng: bbox[0] },
                  { lat: bbox[3], lng: bbox[2] },
                ]);
                store.doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
                break;
              default:
                break;
            }
            store.doUpdateSelectedRequest(type);
            store.doUpdateRelativeUrl(`/forms/${permitID}`);
            store.doFetchUserFilesDetails(permitID).finally(() => {
              dispatch({ type: 'SET_LOADING_STATE', payload: false });
            });
          }
        } else {
          store.doSecondaryModalOpen(statusModal, {
            msg: body?.msg ?? err?.msg ?? 'An error occurred while retrieving the request, please try again',
            status: body?.status ?? err?.status ?? 'Request Retrieval Error',
          });
          dispatch({ type: 'SET_LOADING_STATE', payload: false });
        }
      });
    },

  doAddPermit:
    (data) =>
    ({ apiPost, dispatch, store }) => {
      const permitType =
        store.selectSelectedRequest() === '4' ? 'Nationwide Permit (ENG 6082)' : 'Individual Permit (ENG 4345)';

      store.doSetLoadingState(true);
      store.doSetLoadingMessage(`Creating ${permitType} Request...`);

      const uri = '/api/Permit/AddPermit';

      return apiPost(uri, data, (err, body) => {
        store.doSetLoadingState(false);
        store.doSetLoadingMessage('Loading...');
        if (!err && body.status === 'Success') {
          dispatch({
            type: 'UPDATE_REQUEST_API_DATA',
            payload: { projectID: body.data.projectID, requestID: body.data.requestID },
          });
          dispatch({
            type: 'UPDATE_REQUEST_FORM_DATA',
            payload: { projectID: body.data.projectID, requestID: body.data.requestID },
          });

          useJpa.setState({ jpaBasicInfo: body.data.jpaBasicInfo });

          dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: 'Draft' } });
          dispatch({ type: 'UPDATE_PERMITS', payload: { permitID: body.data.permitID } });
          store.doUpdateRelativeUrl('/forms');
          store.doFetchUserFilesDetails(body.data.permitID);
          store.doModalClose();
        } else {
          store.doSecondaryModalOpen(statusModal, {
            msg: body?.msg ?? err?.msg ?? 'An error occurred while creating the request, please try again',
            status: body?.status ?? err?.status ?? 'Request Creation Error',
          });
        }
      });
    },
  doUpdatePermit:
    (data) =>
    ({ apiPut, dispatch, store }) => {
      const permitType =
        store.selectSelectedRequest() === '4' ? 'Nationwide Permit (ENG 6082)' : 'Individual Permit (ENG 4345)';

      store.doSetLoadingState(true);
      store.doSetLoadingMessage(`Saving ${permitType} Request...`);

      const uri = '/api/Permit/UpdatePermit';

      return apiPut(uri, data, (err, body) => {
        const signed = Boolean(data?.request?.isSubmit);
        store.doSetLoadingState(false);
        store.doSetLoadingMessage('Loading...');
        if (!err && ['Success', 'Success With Warning'].includes(body?.status)) {
          handleSuccess(signed, permitType, body, store, dispatch, submissionModal);
        } else {
          store.doSecondaryModalOpen(statusModal, {
            msg: body?.msg ?? err?.msg ?? 'An error occurred while saving or submitting the request, please try again',
            status: body?.status ?? err?.status ?? 'Request Save/Submission Error',
          });
          dispatch({ type: 'UPDATE_PERMITS_ERRORS', payload: body?.data });
        }
      });
    },
  doUploadAndUpdatePermits:
    (data, status) =>
    ({ store }) => {
      store.doSetLoadingState(true);
      store.doSetLoadingMessage('Uploading Permit Request Files...');
      if (store.selectValidFiles()?.length > 0) {
        // Format to FormData
        const formdata = new FormData();
        formdata.append('overwrite', false);
        formdata.append('isAnonymous', false);
        store.selectValidFiles().forEach((item) => {
          const fileMetaData = {
            ProjectID: store.selectProjectID(),
            RequestID: store.selectRequestID(),
            Version: 0,
            Type: status,
            Tags: item?.tags,
          };
          formdata.append('files', item?.file);
          formdata.append('fileMetaData', JSON.stringify(fileMetaData));
        });
        Promise.all([store.doUploadFiles(formdata)])
          .then((results) => {
            if (results?.[0]?.status === 'Success') {
              // if all files upload successfully
              store.doSetLoadingMessage('Saving Permit...');
              store.doUpdatePermit(data);
              store.doResetUsersFileData();
              store.doFetchUserFilesDetails(store.selectPermitRequestID());
            } else {
              // if any of the files failed to upload
              store.doSetLoadingState(false);
              store.doSetLoadingMessage('Loading...');
              store.doResetUsersFileData();
              store.doSecondaryModalOpen(statusModal, {
                status: 'ERROR! Failed Uploads',
                msg: (
                  <>
                    <p>{results?.[0]?.msg}</p>
                    <p>Retry saving/submitting your General Permit Request in a few minutes.</p>
                  </>
                ),
                saveText: 'Return to request',
              });
            }
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        // If no files were selected, just update GP
        store.doUpdatePermit(data);
      }
    },
  doFetchGPPermitsByDistrict:
    (district) =>
    ({ dispatch, apiGet }) => {
      const uri =
        '/api/Lookup/getGPPermitsByDistrict?' +
        new URLSearchParams({
          district,
        });

      apiGet(uri, (err, body) => {
        if (!err && body.status === 'Success') {
          dispatch({
            type: 'UPDATE_GP_PERMIT_TYPES',
            payload: body.data,
          });
        } else {
          console.error('FAILED TO GET LATEST LOOKUP TABLE DATA');
        }
      });
    },
  doUpdatePermitRequest:
    (data) =>
    ({ dispatch, store }) => {
      dispatch({ type: 'UPDATE_PERMITS', payload: data });
      dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { permits: [store.selectPermitData()] } });
    },
  doUpdatePermitRequestSub:
    (section, data) =>
    ({ dispatch, store }) => {
      dispatch({ type: 'UPDATE_PERMITS_SUB', payload: { section, data } });
      dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { permits: [store.selectPermitData()] } });
    },
  doClearPileDataByUse:
    (pileUse, dataTypesToClear = []) =>
    ({ dispatch, store }) => {
      dispatch({ type: 'CLEAR_PILE_DATA_BY_USE', payload: { pileUse, dataTypesToClear } });
      dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { permits: [store.selectPermitData()] } });
    },
  doResetPermitSection:
    (section) =>
    ({ dispatch }) => {
      dispatch({ type: 'RESET_PERMIT_SECTION', payload: section });
    },
  doResetPermitData:
    () =>
    ({ dispatch }) => {
      dispatch({ type: 'RESET_PERMIT' });
    },
};

export default PermitsDataBundle;
