const bundleRoot = '/api/Lookup/';

const fieldLookupTableBundle = {
  name: 'fieldLookupTable',

  getReducer: () => {
    const initialData = {
      ARTypes: [],
      Cowardins: [],
      HGMCodes: [],
      StateRegulations: [],
      DivisionAndDistricts: [],
      DivisionAndDistrictsUnAuth: [],
      CreditUnits: [],
    };

    return (state = initialData, { type, payload }) => {

      switch (type) {
        case 'UPDATE_FIELD_LOOKUP_TABLE':
          return {
            ...state, ...payload
          };
        case 'UPDATE_UNAUTH_DISTRICTS_LOOKUP_TABLE':
          return {
            ...state,
            DivisionAndDistrictsUnAuth: payload
          };
        case 'UPDATE_DISTRICTS_CODE_LOOKUP':
          const districtsByCode = {};

          payload.forEach((district) => {
            if(!districtsByCode[district.orgShortName]) {
              districtsByCode[district.orgShortName] = {
                orgAddress: district.orgAddress,
                orgName: district.orgName,
                orgPhone: district.orgPhone,
                orgUrl: district.orgUrl,
              };
            }
          });

          return {
            ...state,
            districtsByCode,
          };
        case 'UPDATE_CREDIT_UNITS_LOOKUP_TABLE':
          return {
            ...state,
            CreditUnits: payload
          };
        default:
          return state;
      }
    };
  },
  doGetLookupTableData: () => ({ dispatch, apiGet }) => {
    const uri = `${bundleRoot}getALLLKPTables`;

    apiGet(uri, (err, body) => {
      if (!err && body.status === 'Success') {
        dispatch({
          type: 'UPDATE_FIELD_LOOKUP_TABLE',
          payload: body.data
        });
      }
      else {
        console.error('FAILED TO GET LATEST LOOKUP TABLE DATA');
      }

    });
  },
  doGetDistrictsUnAuth: () => ({ dispatch, apiGet }) => {
    const uri = `${bundleRoot}getDistricts`;
    return new Promise((resolve, reject) => {
      apiGet(uri, (err, body) => {
        if (!err && body.status === 'Success') {
          dispatch({
            type: 'UPDATE_UNAUTH_DISTRICTS_LOOKUP_TABLE',
            payload: body.data
          });
          dispatch({
            type: 'UPDATE_DISTRICTS_CODE_LOOKUP',
            payload: body.data
          });
          resolve(body.data);
        } else {
          console.error('FAILED TO GET LATEST DISTRICT LOOKUP TABLE DATA');
          reject(err);
        }
      }, true);
    });
  },
  doFetchCreditUnitsByDistrict: (district) => ({ dispatch, apiGet }) => {
    const uri = `${bundleRoot}getCreditUnitsForDistrict?`  + new URLSearchParams({
      district
    });

    apiGet(uri, (err, body) => {
      if (!err && body?.status === 'Success') {
        dispatch({
          type: 'UPDATE_CREDIT_UNITS_LOOKUP_TABLE',
          payload: body?.data
        });
      }
      else {
        console.error('FAILED TO GET LATEST CREDIT UNITS DATA');
      }

    });
  },
  selectLookupTableARTypes: (state) => state.fieldLookupTable.ARTypes,
  selectLookupTableCowardins: (state) => state.fieldLookupTable.Cowardins,
  selectLookupTableCreditUnits: (state) => state.fieldLookupTable.CreditUnits,
  selectLookupTableData: (state) => state.fieldLookupTable,
  selectLookupTableDivisionsAndDistricts: (state) => state.fieldLookupTable.DivisionAndDistricts,
  selectLookupTableHGMCodes: (state) => state.fieldLookupTable.HGMCodes,
  selectLookupTableStateRegulations: (state) => state.fieldLookupTable.StateRegulations,
  selectUnAuthDivisionsAndDistricts: (state) => state.fieldLookupTable.DivisionAndDistrictsUnAuth,
  selectDistrictsByCode: (state) => state.fieldLookupTable.districtsByCode,
};

export default fieldLookupTableBundle;
