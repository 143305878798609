import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';

type User = {
  token: string | null;
  fname: string | null;
  lname: string | null;
  email: string | null;
};

const defaultUser = {
  token: null,
  fname: null,
  lname: null,
  email: null,
};

export const userStore = createStore((set) => ({
  user: defaultUser,
  setUser: (user: User) => set({ user }),
}));

export const useUserStore = () => useStore(userStore, (state) => state);
