import { toast } from 'react-toastify';
import Keycloak from 'keycloak-js';
import SessionExpirationModal from '../app-pages/common/modals/SessionExpiration';
import { userStore } from '@stores';

const keycloakUrl = import.meta.env.VITE_KEYCLOAK_URL;
const redirectUrl = import.meta.env.VITE_REDIRECT_URL;
const keycloakRealm = import.meta.env.VITE_KEYCLOAK_REALM;
const keycloakClient = import.meta.env.VITE_KEYCLOAK_CLIENT;
const idpHint = import.meta.env.VITE_IDP_HINT;

const initOptions = {
  realm: keycloakRealm,
  url: keycloakUrl,
  clientId: keycloakClient,
  onLoad: 'check-sso',
};

let kc = new Keycloak(initOptions);

const createAuthBundle = (options) => ({
  name: 'auth',

  getReducer: () => {
    const { mock, mockToken, ...rest } = options;
    const defaults = {
      loading: false,
      token: mock ? mockToken : null,
      isMock: mock,
      authData: null,
    };
    const initState = { ...rest, ...defaults };

    return (state = initState, { type, payload }) => {
      switch (type) {
        case 'START_AUTH':
          return { ...state, loading: payload };
        case 'UPDATE_AUTH':
          return { ...state, ...payload };
        default:
          return state;
      }
    };
  },

  init: (store) => {
    kc.init({
      onLoad: initOptions.onLoad,
    })
      .then((auth) => {
        if (auth) {
          store.doAuthUpdate(kc.token);

          setInterval(() => {
            try {
              if (kc.isTokenExpired(30)) {
                console.error('Token will expire in less than 30 seconds');
                kc.onTokenExpired = () => {
                  console.error('There was something wrong with your session. Refreshing token...');
                  kc.updateToken(-1)
                    .then(() => store.doAuthUpdate(kc.token))
                    .catch((e) => {
                      console.error('There was an issue refreshing the token! ', e);
                    });
                };
              }
              store.doCheckUserSession();
            } catch (err) {
              console.error(err);
              kc.updateToken(-1)
                .then(() => {
                  store.doAuthUpdate(kc.token);
                })
                .catch((e) => {
                  console.error('There was an issue refreshing the token! ', e);
                });
            }
          }, 30000);
        }
      })
      .catch((e) => {
        toast.error('Authentication Failed!');
        console.error(`Request returned a ${e.status}`);
      });
  },

  selectAuth: (state) => state.auth,
  selectAuthIsLoggedIn: (state) => !!state.auth.token,
  selectAuthToken: (state) => state.auth.token,
  selectAuthData: (state) => ({
    ...state?.auth?.authData,
    given_name: state?.auth?.authData?.given_name?.trim(),
    family_name: state?.auth?.authData?.family_name?.trim(),
    email: state?.auth?.authData?.email?.trim(),
  }),

  doAuthLogin:
    () =>
    ({ dispatch, store }) => {
      store.doSetLoadingState(true);
      store.doSetLoadingMessage('Authenticating...');
      dispatch({ type: 'START_AUTH', payload: true });
      kc.login({ idpHint: idpHint, redirectUri: redirectUrl });
    },

  doAuthLogout:
    () =>
    ({ dispatch, store }) => {
      window.localStorage.removeItem('KEYCLOAK_TOKEN');
      dispatch({ type: 'AUTH_LOGOUT_SUCCESS' });
      if (import.meta.env.VITE_ENVIRONMENT !== 'local') {
        const redirectURI = `${import.meta.env.VITE_IDP_URL}openid_connect/logout?client_id=${import.meta.env.VITE_IDP_CLIENT_ID}&post_logout_redirect_uri=${redirectUrl}`;
        kc.logout({ redirectUri: redirectURI });
      } else if (import.meta.env.VITE_ENVIRONMENT === 'local') {
        kc.logout({ redirectUri: redirectUrl });
      }
    },

  doAuthUpdate:
    (accessToken) =>
    ({ dispatch }) => {
      const authInfo = accessToken ? JSON.parse(atob(accessToken.split('.')[1])) : null;

      userStore.getState().setUser({
        token: accessToken,
        email: authInfo.email,
        fname: authInfo.given_name,
        lname: authInfo.family_name,
      });

      dispatch({
        type: 'UPDATE_AUTH',
        payload: {
          loading: false,
          token: accessToken,
          authData: authInfo,
        },
      });

      //save token to local storage
      window.localStorage.setItem('KEYCLOAK_TOKEN', accessToken);
    },

  doCheckUserSession:
    () =>
    ({ store }) => {
      // if user session is IDLE, then after x amount of times, give user option to continue session or logout, otherwise auto-logout
      if (store.selectSessionIdleCount() >= 10) {
        store.doSecondaryModalOpen(SessionExpirationModal, {
          handleLogout: () => store.doAuthLogout(),
          handleExtend: () => {
            kc.updateToken(-1)
              .then(() => store.doAuthUpdate(kc.token))
              .catch((e) => {
                console.error('There was an issue refreshing the token! ', e);
              });
          },
          idle: true,
        });
      } else {
        // if user session is ACTIVE, refresh token
        kc.updateToken(30)
          .then(() => store.doAuthUpdate(kc.token))
          .catch((e) => {
            console.error('There was an issue refreshing the token! ', e);
          });
      }
    },
});

export default createAuthBundle;
