import { useMemo } from 'react';
import { useJpa } from '@src/stores';
import { connect } from 'redux-bundler-react';

import IPFormInstructions from '@forms/static-forms/IPForm2aInstructions';
import SupportingInformation from '@forms/input-forms/common/SupportingInformation';
import PermitsFormContacts from '@forms/input-forms/PermitsForm/PermitsFormContacts';
import IPFormGeneralProjectInformation from '@forms/input-forms/PermitsForm/PermitsFormGeneralProjectInformation/IPFormGeneralProjectInformation';
import PermitsFormOtherLawsRegulations from '@forms/input-forms/PermitsForm/PermitsFormOtherLawsRegulations/PermitsFormOtherLawsRegulations';
import PermitsFormAdjoiningPropertyOwners from '@forms/input-forms/PermitsForm/PermitsFormAdjoiningPropertyOwners';
import PermitsFormAquaticResources from '@forms/input-forms/PermitsForm/PermitsFormAquaticResources/PermitsFormAquaticResources';
import PermitsFormImpactsMitigation from '@forms/input-forms/PermitsForm/PermitsFormImpactsMitigation';
import PermitsFormCertifySignSubmit from '@forms/input-forms/PermitsForm/PermitsFormCertifySignSubmit';
import { getEnvironmentVisibility } from '@components/environment-visibility/EnvironmentVisibility';
import JpaInfo from './JpaInfo';
import JpaForm from './JpaForm';

const showJpa = getEnvironmentVisibility({ mode: 'hide', prod: true, test: true });

//List of steps for vertical stepper, these can also be used for form header. index = step ID
export const getIpForm2aMetadata = () => {
  const jpaBasicInfo = useJpa.getState().jpaBasicInfo;
  const isJpa = jpaBasicInfo?.length > 0;
  const baseFormArray = [
    IPFormInstructions.metadata,
    PermitsFormContacts.metadata,
    IPFormGeneralProjectInformation.metadata,
    PermitsFormAdjoiningPropertyOwners.metadata,
    PermitsFormOtherLawsRegulations.metadata,
    PermitsFormAquaticResources.metadata,
    PermitsFormImpactsMitigation.metadata,
    SupportingInformation.metadata,
    PermitsFormCertifySignSubmit.metadata,
  ];

  // insert JPA sidebar steps
  if (isJpa && showJpa) {
    const jpaInformationSteps = [
      {
        id: 'jpa-info',
        sectionName: 'JPA Information',
        clickId: 2,
      },
    ];
    const jpaQuestionSteps = [
      {
        id: 'jpa-questions',
        sectionName: 'JPA Questions',
        clickId: 11,
      },
    ];

    jpaBasicInfo.forEach((jpa) => {
      jpaInformationSteps.push({
        id: `jpa-info-${jpa.formID}`,
        sectionName: `${jpa.orgName} - ${jpa.formCode}`,
        isSubStep: true,
      });
      jpaQuestionSteps.push({
        id: `jpa-questions-${jpa.formID}`,
        sectionName: `${jpa.orgName} - ${jpa.formCode}`,
        isSubStep: true,
      });
    });

    baseFormArray.splice(1, 0, ...jpaInformationSteps);
    baseFormArray.splice(baseFormArray.length - 1, 0, ...jpaQuestionSteps);
  }

  return baseFormArray;
};

const generateSections = ({ doUpdatePermitRequest, isJpa, jpaBasicInfo, permitData, permitRequestID }) => {
  const sections = [
    {
      id: 'IP2aInstructions',
      name: 'Instructions',
      content: IPFormInstructions,
    },
    {
      id: 'IPFormContactInformation',
      name: 'Contact Information',
      content: PermitsFormContacts,
    },
    {
      id: 'IPFormGeneralProjectInformation',
      name: 'General Project Information',
      content: IPFormGeneralProjectInformation,
    },
    {
      id: 'IPAdjoiningPropertyOwners',
      name: 'Adjoining Property Owners',
      content: PermitsFormAdjoiningPropertyOwners,
    },
    {
      id: 'IPOtherLawsAndRegulations',
      name: 'Other Laws And Regulations',
      content: PermitsFormOtherLawsRegulations,
    },
    {
      id: 'IPFormAquaticResources',
      name: 'Aquatic Resources',
      content: PermitsFormAquaticResources,
    },
    {
      id: 'IPProjectImpactsAndMitigation',
      name: 'Project Impact and Mitigation',
      content: PermitsFormImpactsMitigation,
    },
    {
      id: 'IP2aSupportingInformation',
      name: 'Supporting Information',
      content: SupportingInformation,
      componentID: permitRequestID,
      subtext: (
        <p>
          Please upload supporting documentation including project plans, state water certifications, etc. Multiple
          documents of the same file type can be combined and uploaded as one document.
        </p>
      ),
      requestData: permitData,
      updateRequestData: doUpdatePermitRequest,
    },
    {
      id: 'IP2aCertifySignSubmit',
      name: 'Certify Sign Submit',
      content: PermitsFormCertifySignSubmit,
    },
  ];

  // insert JPA content
  if (isJpa && showJpa) {
    const jpaInformationSections = [
      {
        id: 'jpa-info',
        name: 'JPA Information',
        content: () => <></>,
      },
    ];
    const jpaQuestionSections = [
      {
        id: 'jpa-questions',
        name: 'JPA Questions',
        content: () => <></>,
      },
    ];

    jpaBasicInfo.forEach((jpa) => {
      jpaInformationSections.push({
        id: `jpa-info-${jpa.formID}`,
        name: `jpa-info-${jpa.formID}`,
        content: JpaInfo,
        formId: jpa.formID,
        requestId: permitData.permitID,
        version: permitData.version,
      });
      jpaQuestionSections.push({
        id: `jpa-questions-${jpa.formID}`,
        name: `jpa-questions-${jpa.formID}`,
        content: JpaForm,
        formId: jpa.formID,
        requestId: permitData.permitID,
        version: permitData.version,
      });
    });
    sections.splice(1, 0, ...jpaInformationSections);
    sections.splice(sections.length - 1, 0, ...jpaQuestionSections);
  }

  return sections;
};

const IPForm2a = connect(
  'doUpdatePermitRequest',
  'selectActiveStep',
  'selectPermitRequestID',
  'selectPermitData',
  ({ doUpdatePermitRequest, activeStep, permitRequestID, permitData }) => {
    const { jpaBasicInfo } = useJpa();
    const isJpa = jpaBasicInfo?.length > 0;
    const sections = useMemo(
      () => generateSections({ doUpdatePermitRequest, isJpa, jpaBasicInfo, permitData, permitRequestID }),
      [doUpdatePermitRequest, isJpa, jpaBasicInfo, permitData, permitRequestID]
    );

    return sections.map((section, i) => {
      const { content: Content, ...props } = section;
      return (
        <section id={section.id} name={section.name} style={{ display: activeStep?.id === i ? 'inline' : 'none' }}>
          <Content stepNo={i} {...props} />
        </section>
      );
    });
  }
);

export default IPForm2a;
