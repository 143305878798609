import React, { useState } from 'react';
import TabItem from './tabItem';

import { classArray } from '../../utils/helpers.jsx';

const generateTabs = (tabs, activeTab, changeTab) => (
  <>
    {tabs.map((tab, i) => {
      const { title, className } = tab;

      const cls = classArray([
        '',
        className,
      ]);

      return (
        <TabItem
          key={title}
          className={cls}
          tab={tab}
          onClick={() => changeTab(title, i)}
          isActive={activeTab === i}
        />
      );
    })}
  </>
);

/**
 *
 * @param {Array} tabs - List of tabs to display, objects structured as `{ title: <string>, content: <Element>, className = <string> }`. Default `[]`.
 * @param {Function} onChange - Function triggered when tab is changed. Provides the new tabs' `title`, `index` as a parameters.
 * @param {number} defaultTab - Default tab index to be set as active. Default `0`.
 * @param {string} tabListClassName - Class(es) to be applied to the tablist container element `<ul />`.
 * @param {string} contentClassName - Class(es) to be applied to the contents container element `<section />`.
 */
const TabContainer = ({
  tabs = [],
  onChange = (_title, _index) => { },
  defaultTab = 0,
  tabListClassName = '',
  contentClassName = '',
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const navClasses = classArray([
    'navbar',
    'navbar-expand-xl',
    'primary-bg',
    'seperator',
  ]);

  const divClasses = classArray([
    tabListClassName,
  ]);

  const sectionClasses = classArray([
    'mt-3',
    contentClassName,
  ]);

  const changeTab = (title, index) => {
    onChange(title, index);
    setActiveTab(index);
  };

  return (
    <>
      <nav className={navClasses}>
        <div className={divClasses}>
          <ul className='navbar-nav ml-auto'>
            {generateTabs(tabs, activeTab, changeTab)}
          </ul>
        </div>
      </nav>
      <section className={sectionClasses}>
        {tabs[activeTab] ? tabs[activeTab].content : (
          <p>No Content</p>
        )}
      </section>
    </>
  );
};

export default TabContainer;
