/*
 * - Exposes class and methods to interact with the JPA API
 */

import request, { REQUEST_METHODS } from '@api/Request';
import { JpaEndpoints } from './config';
import {
  GetJpaFormRequest,
  GetJpaFormResponse,
  GetJpaInitialSectionRequest,
  GetJpaInitialSectionResponse,
  GetJpaOrgRequest,
  GetJpaOrgResponse,
} from './types';

export default class JpaService {
  public static readonly getOrgs = (data: GetJpaOrgRequest): Promise<GetJpaOrgResponse> =>
    request({
      url: JpaEndpoints.getOrgs(),
      method: REQUEST_METHODS.POST,
      data: {
        State: data?.state?.toLowerCase?.() ?? null,
        District: data?.district?.toLowerCase?.() ?? null,
        Geometry: data?.geometry || null,
      },
    });

  public static readonly getInitialSection = (
    params: GetJpaInitialSectionRequest
  ): Promise<GetJpaInitialSectionResponse> =>
    request({
      url: JpaEndpoints.getInitialSection(),
      method: REQUEST_METHODS.GET,
      params,
    });

  public static readonly getForm = (params: GetJpaFormRequest): Promise<GetJpaFormResponse> =>
    request({
      url: JpaEndpoints.getForm(),
      method: REQUEST_METHODS.GET,
      params,
    });
}
