import { connect } from 'redux-bundler-react';
import Icon from '@components/icon/Icon';
import { mdiNoteEdit, mdiFileCheckOutline } from '@mdi/js';

import './FormStatus.scss';

const statusStyleMap = {
  Draft: 'draft',
  Submitted: 'submitted',
  Acknowledged: 'acknowledged',
  'Not Approved': 'notApproved',
  'Lead Agency Changed': 'lead-agency-changed',
  'Re-Routed': 're-routed',
  Assigned: 'assigned',
};

const FormStatus = connect('selectRequestStatus', ({ requestStatus }) => {
  const icon = requestStatus === 'Draft' ? mdiNoteEdit : mdiFileCheckOutline;
  const statusStyles = statusStyleMap[requestStatus] || 'draft';

  return (
    <div className={`form-status ${statusStyles}`}>
      <Icon focusable={false} className='form-status-icon' path={icon} size={1} /> {requestStatus}
    </div>
  );
});

export default FormStatus;
