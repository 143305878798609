import { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import { Button } from '@trussworks/react-uswds';
import Icon from '@components/icon/Icon';
import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';
import { createColumnHelper } from '@tanstack/react-table';

import AgentAuthorizationUpload from '@forms/components/Form/AgentAuthorizationUpload';
import FieldHeader from '@forms/components/Form/FieldHeader';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';
import AgentsActionsTableCell from '@src/app-components/table/tableCellComponents/AgentsActionsTableCell';
import AddAgentModal from '@forms/components/modals/AddAgentModal';
import SelectInput from '@components/select/Select';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import { contactInputValidation } from '@src/utils/validation/inputValidation.contact';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import useErrorFocus from '@hooks/useErrorFocus';
import { ContactTypes, ContactsFormNames, ErrorMessages, ProfileRoles } from '@src/utils/enums';
import {
  addUserProfileAgent,
  updateUserProfileAgent,
  userProfileAgentUsed,
  updateAgentsValue,
} from '@forms/components/modals/_shared/UserProfileAgent';
import { formatUSPhoneNumber, stripUncompletedContacts } from '@src/utils/helpers';
import TanStackTableBasic from '@src/app-components/table/TanStackTable/TanStackTableBasic';

import '@styles/index.scss';

export const JDFormContactsMetadata = {
  sectionName: 'Contact Information',
};

const JDFormContacts = connect(
  'doResetFileSection',
  'doClearContactType',
  'doUpdateSectionValidity',
  'doModalOpen',
  'doUpdateRequestContacts',
  'doSaveTable',
  'selectTableData',
  'selectRequestAPIData',
  'selectAgentAuthorizationFile',
  'selectUserProfileData',
  'selectSteps',
  'selectActiveStep',
  'selectIsReadOnly',
  ({
    doResetFileSection,
    doClearContactType,
    doUpdateSectionValidity,
    doModalOpen,
    doUpdateRequestContacts,
    doSaveTable,
    tableData,
    requestAPIData,
    agentAuthorizationFile,
    userProfileData,
    steps,
    activeStep,
    isReadOnly,
    componentID,
    edit,
    stepNo,
  }) => {
    const thisSectionStepStatus = useMemo(() => steps.find((step) => step.id === stepNo)?.touched, [steps, stepNo]);
    const [rowData, setRowData] = useState([]);
    const [isUserProfileAgentUsed, setIsUserProfileAgentUsed] = useState(false);

    const applicantContact = useMemo(() => {
      const contacts = requestAPIData?.request?.contacts || [];
      return contacts.find((contact) => contact.contactType === ContactTypes.Applicant);
    }, [requestAPIData?.request?.contacts]);
    const contacts = requestAPIData?.request?.contacts ?? [];
    const agentsTableLength = rowData?.length;

    const schema = (agentsTableLength) =>
      yup.object().shape(
        {
          ...contactInputValidation.nameRequired,
          ...contactInputValidation.addressRequired,
          ...contactInputValidation.phoneRequired,
          ...contactInputValidation.faxOptional,
          ...contactInputValidation.emailRequired,
          agents: yup.string().required(ErrorMessages.SelectOption),
          agentsTable: yup.boolean().when('agents', {
            is: (val) => val === 'true' && agentsTableLength < 1,
            then: () => yup.boolean().required('Agents: At least one agent is required'),
          }),
          agentAuth: yup
            .string()
            .when('agents', {
              is: 'true',
              then: () =>
                yup
                  .string()
                  .required('Please provide an Agent Authorization Form')
                  .test('file-invalid-test', 'File selected is invalid', function (value) {
                    return value !== 'error';
                  }),
            })
            .nullable(),
          agentAuthComments: yup.string().when('agents', { is: 'true', then: () => yup.string().nullable() }),
        },
        [contactInputValidation.faxOptionalDependencies]
      );

    const defaultValues = {
      salutation: applicantContact?.salutation ?? '',
      firstName: applicantContact?.firstName ?? '',
      middleName: applicantContact?.middleName ?? '',
      lastName: applicantContact?.lastName ?? '',
      address: applicantContact?.address ?? '',
      addressTwo: applicantContact?.addressTwo ?? '',
      city: applicantContact?.city ?? '',
      state: applicantContact?.state ?? '',
      zipcode: applicantContact?.zipcode ?? '',
      country: applicantContact?.country ?? 'US',
      phoneOneType: applicantContact?.phoneOneType ?? '',
      phoneOneCountryCode: applicantContact?.phoneOneCountryCode ?? '1',
      phoneOne: applicantContact?.phoneOne
        ? applicantContact?.phoneOneCountryCode === '1'
          ? formatUSPhoneNumber(applicantContact.phoneOne)
          : applicantContact?.phoneOne
        : '',
      phoneOneExtension: applicantContact?.phoneOneExtension ?? '',
      phoneTwoType: applicantContact?.phoneTwoType ?? '',
      phoneTwoCountryCode: applicantContact?.phoneTwoCountryCode ?? '1',
      phoneTwo: applicantContact?.phoneTwo
        ? applicantContact?.phoneTwoCountryCode === '1'
          ? formatUSPhoneNumber(applicantContact.phoneTwo)
          : applicantContact?.phoneTwo
        : '',
      phoneTwoExtension: applicantContact?.phoneTwoExtension ?? '',
      faxCountryCode: applicantContact?.faxCountryCode ?? '1',
      faxPhone: applicantContact?.faxPhone ?? '',
      emailAddress: applicantContact?.emailAddress ?? '',
      company: applicantContact?.company ?? '',
      agents:
        contacts.some((contact) => contact.contactType === ContactTypes.Agent) ||
        (userProfileData?.role === ContactTypes.Agent && !isReadOnly)
          ? 'true'
          : 'false',
      agentAuthComments: requestAPIData?.request?.agentAuthComments ?? '',
    };

    const methods = useForm({
      resolver: yupResolver(schema(agentsTableLength)),
      mode: 'onBlur',
      defaultValues: defaultValues,
    });
    const {
      formState: { isValid, errors },
      watch,
      setError,
      trigger,
      getValues,
      setValue,
    } = methods;
    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    const agents = watch('agents');
    const latestvalues = watch();
    const columnHelper = createColumnHelper();

    const columnDefs = useMemo(
      () => [
        columnHelper.display({
          header: 'Actions',
          id: 'actions',
          cell: ({ row }) => (
            <AgentsActionsTableCell
              row={row}
              rowData={rowData}
              setRowData={setRowData}
              modalComponent={AddAgentModal}
              isReadOnly={isReadOnly}
            />
          ),
          size: 60,
          enableSorting: false,
          meta: {
            centerText: true,
          },
        }),
        columnHelper.accessor('firstName', {
          header: 'First Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('middleName', {
          header: 'Middle Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('lastName', {
          header: 'Last Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('address', {
          header: 'Address',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 500,
        }),
        columnHelper.accessor('city', {
          header: 'City',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('state', {
          header: 'State',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
        columnHelper.accessor('country', {
          header: 'Country',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
      ],
      [columnHelper, rowData, isReadOnly]
    );

    const clearRowData = useCallback(() => {
      setRowData([]);
      doClearContactType(ContactTypes.Agent);
      setIsUserProfileAgentUsed(false);
    }, [doClearContactType]);

    useEffect(() => {
      setIsUserProfileAgentUsed(
        userProfileAgentUsed(
          rowData,
          userProfileData?.firstName,
          userProfileData?.lastName,
          userProfileData?.emailAddress
        )
      );
    }, [rowData, userProfileData]);

    useEffect(() => {
      // Load tables from database
      let initialRowData = requestAPIData?.request?.contacts?.filter(
        (contact) => contact.contactType === ContactTypes.Agent
      );
      // If country is null, default to US
      initialRowData = initialRowData?.map((contact) =>
        contact.country === null ? { ...contact, country: 'US' } : contact
      );
      if (initialRowData) {
        //Update agents array if user profile agent exists
        if (
          userProfileAgentUsed(
            initialRowData,
            userProfileData?.firstName,
            userProfileData?.lastName,
            userProfileData?.emailAddress
          )
        ) {
          initialRowData = updateUserProfileAgent(initialRowData, userProfileData);
        }
        updateAgentsValue(userProfileData?.role, initialRowData, setValue);
        setRowData(initialRowData);
      }
    }, [requestAPIData, userProfileData, setValue]);

    useEffect(() => {
      userProfileData?.role === ProfileRoles.Agent && setValue('agents', 'true');
    }, [userProfileData?.role, agents, setValue]);

    useEffect(() => {
      if (agents === 'true' && rowData?.length < 1) {
        setError('agentsTable', { type: 'custom', message: 'Agents: At least one agent is required' });
      } else {
        trigger('agentsTable');
      }
    }, [agents, trigger, rowData?.length, setError]);

    useEffect(() => {
      const agentAuthValid = agents === 'false' || (agentAuthorizationFile?.length > 0 && rowData?.length > 0);
      const validity = isValid && agentAuthValid;
      doUpdateSectionValidity(JDFormContactsMetadata.sectionName, validity, stepNo, isReadOnly);
    }, [isValid, rowData, agents, agentAuthorizationFile, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      const { agents, agentAuth, agentAuthComments, propertyowners, showPhoneTwo, ...applicantFields } = latestvalues;
      const applicant = {
        ...applicantFields,
        contactID: applicantContact?.contactID ?? undefined,
        requestID: applicantContact?.requestID ?? undefined,
        version: applicantContact?.version ?? undefined,
        contactType: ContactTypes.Applicant,
        createdBy: applicantContact?.createdBy ?? undefined,
        phoneOne: applicantFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
        phoneTwo: applicantFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
        faxPhone: applicantFields?.faxPhone?.replace(/\D/g, '') ?? undefined,
      };
      const contacts = [applicant, ...rowData, ...tableData?.propOwners];
      doUpdateRequestContacts(stripUncompletedContacts(contacts));
    }, [rowData, getValues, latestvalues, doUpdateRequestContacts, applicantContact, tableData?.propOwners]);

    // Reset Files if section is hidden
    useEffect(() => {
      if (agents === 'false') {
        clearRowData();
        doResetFileSection(ContactsFormNames.AgentAuthorization, true);
      }
    }, [agents, doResetFileSection, clearRowData]);

    useEffect(() => {
      rowData && doSaveTable('agents', rowData);
    }, [rowData, doSaveTable]);

    const placeholder = (
      <div className='d-flex flex-column align-items-center margin-top-1 margin-bottom-1'>
        <p>No agents found, please click below to add an agent:</p>
        <div className='margin-top-1'>
          <Button
            className='add-agent-button'
            title='Add an Agent'
            size='small'
            onClick={() =>
              doModalOpen(AddAgentModal, { setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly })
            }
            disabled={isReadOnly}
          >
            <Icon focusable={false} className='mr-1' path={mdiAccountPlus} size={'16px'} />
            Add an Agent
          </Button>
        </div>
      </div>
    );

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly && (
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        )}
        <FieldHeader
          text='Contact Information'
          subtext='Provide contact information for the applicant and the agent if applicable. The applicant is the individual
            or entity submitting the delineation report and/or requesting the
            jurisdictional determination. The agent is a third-party that has
            been retained by the applicant to act on their behalf in submitting
            this request to the U.S. Army Corps of Engineers.'
        />

        {/* Contact Section comes first when role is not agent  */}
        {userProfileData?.role !== ProfileRoles.Agent && (
          <ContactFields
            type='applicant'
            label='Applicant'
            edit={edit}
            isReadOnly={isReadOnly}
            showButton={!isReadOnly}
          />
        )}

        <FieldHeader text='Agents' />
        <div className='ml-2 mb-2' id='agentsTable'>
          <SelectInput
            name='agents'
            label='Has the applicant hired an agent to complete the application process?'
            required
            className='w-50'
            readOnly={isReadOnly || userProfileData?.role === ContactTypes.Agent}
          >
            <option key='2' value='true'>
              Yes
            </option>
            <option key='3' value='false'>
              No
            </option>
          </SelectInput>
        </div>

        {agents === 'true' && (
          <>
            <div className='d-flex width-full margin-top-2 padding-bottom-2 justify-content-end'>
              {rowData.length > 0 && (
                <Button
                  className='add-agent-button'
                  title='Add an Agent'
                  size='small'
                  onClick={() =>
                    doModalOpen(AddAgentModal, { setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly })
                  }
                  disabled={isReadOnly}
                >
                  <Icon focusable={false} className='margin-right-1' path={mdiAccountPlus} size={'16px'} />
                  Add an Agent
                </Button>
              )}
              {userProfileData?.role === ProfileRoles.Agent && (
                <Button
                  className={`table-btn-profile ${isUserProfileAgentUsed || isReadOnly ? 'disabled' : 'hover'}`}
                  title='Use Profile Data'
                  size='small'
                  onClick={() =>
                    addUserProfileAgent(
                      userProfileData,
                      rowData,
                      setRowData,
                      isUserProfileAgentUsed,
                      setIsUserProfileAgentUsed
                    )
                  }
                  disabled={isReadOnly || isUserProfileAgentUsed}
                >
                  Use Profile Data
                </Button>
              )}
              {rowData.length !== 0 && (
                <Button
                  className={`clear-table-button ${rowData.length === 0 || isReadOnly ? 'disabled' : 'hover'}`}
                  title='Clear Agents'
                  size='small'
                  onClick={() => clearRowData()}
                  disabled={rowData.length > 0 ? isReadOnly : true}
                >
                  <Icon focusable={false} className='margin-right-1' path={mdiCloseOctagon} size={'16px'} />
                  Clear Agents
                </Button>
              )}
            </div>
            <div className='width-full margin-bottom-2'>
              <TanStackTableBasic data={rowData} columns={columnDefs} placeholder={placeholder} />
            </div>
            <AgentAuthorizationUpload componentID={componentID} />

            {/* Contact Section comes after Agent Section when role is agent  */}
            {userProfileData?.role === ProfileRoles.Agent && (
              <div className='ml-2 mb-2'>
                <ContactFields type='applicant' label='Applicant' edit={edit} isReadOnly={isReadOnly} showButton />
              </div>
            )}
          </>
        )}
      </FormProvider>
    );
  }
);
JDFormContacts.metadata = JDFormContactsMetadata;

export default JDFormContacts;
