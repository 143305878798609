/*
 * - Definitions of file storage queries
 */

import FileStorageService from './service';
import { UploadPnAttachmentsRequest, UploadPnAttachmentsResponse } from './types';

export class FileStorageMutations {
  public static readonly uploadPnAttachments = async ({
    publicNoticeId,
    daNumber,
    commentId,
    fileData,
  }: UploadPnAttachmentsRequest): Promise<UploadPnAttachmentsResponse> => {
    const formData: any = new FormData();
    formData.append('publicNoticeId', publicNoticeId);
    formData.append('daNumber', daNumber);
    formData.append('commentId', commentId);

    fileData?.forEach((file: any) => {
      formData.append('files', file);
    });

    const data = await FileStorageService.uploadPnAttachments(formData);
    return data;
  };
}
