import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Label, Select, Table, Pagination } from '@trussworks/react-uswds';

const pageBreakdown = [10, 20, 30, 50];

const TanStackTableBasic = ({
  data,
  columns,
  initialTableState,
  isReadOnly,
  hideDrag,
  tableVersion,
  placeholder = 'NO RECORDS TO DISPLAY',
}) => {
  // Initialize TanStack table
  const table = useReactTable({
    autoResetPageIndex: false,
    columns,
    data: data,
    manualPagination: false,
    enableRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
  });
  return (
    <div className='pn-table-container'>
      <Table bordered fullWidth>
        <thead>
          <>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      position: 'relative',
                      userSelect: 'none',
                      width: header.getSize() + 'px',
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </>
        </thead>
        <tbody>
          {table.getRowModel()?.rows?.length === 0 && (
            <tr>
              <td colSpan='100%'>{placeholder}</td>
            </tr>
          )}
          {table.getRowModel()?.rows?.map((row) => (
            <tr key={row.id} className={row.getIsSelected() ? 'selected-row' : ''}>
              {row.getVisibleCells().map((cell) => (
                <td key={`${row.id}-${cell.id}`} style={{ width: cell?.column?.getSize() + 'px' }}>
                  <div className={`pn-cell d-flex ${cell?.column?.columnDef?.meta?.centerText ? 'center-text' : ''}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Pagination */}
      {table.getCoreRowModel().rows.length >= 11 && (
        <div className='footer-container'>
          <div className='pagination-container'>
            <div className='d-flex justify-content-center usa-prose'>
              <div className='d-flex flex-align-center'>
                <Pagination
                  className='margin-right-3 pagination-styles'
                  currentPage={table.getState().pagination.pageIndex + 1}
                  maxSlots={7}
                  totalPages={table.getPageCount()}
                  onClickNext={() => table.nextPage()}
                  onClickPageNumber={(e) => table.setPageIndex(Number(e.target.innerText) - 1)}
                  onClickPrevious={() => table.previousPage()}
                  pathname=''
                />

                <span
                  className='d-flex flex-align-center margin-right-3 rows-per-page'
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Label htmlFor='rows_per_page' className='margin-right-1'>
                    rows per page:
                  </Label>
                  <div className='footer-select-container'>
                    <Select
                      name='rows_per_page'
                      id='rows_per_page'
                      defaultValue={10}
                      onChange={(e) => table.setPageSize(Number(e.target.value))}
                    >
                      {pageBreakdown.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  </div>
                </span>

                <span className='d-flex flex-align-center'>
                  {`${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()} page${table.getPageCount() !== 1 ? 's' : ''}`}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TanStackTableBasic;
