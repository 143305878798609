import TextArea from '@components/textarea/TextArea';
import TextInput from '@components/text-input/TextInput';
import FileInput from '@components/file-input/FileInput';
import DateInput from '@components/date-picker/DatePicker';
import SelectInput from '@components/select/Select';
import YesNoInput from '@components/yes-no-input/YesNoInput';
import classNames from 'classnames';
import MultiSelectInput from '../multi-select';

export const QUESTION_TYPE = {
  date: 3,
  file: 6,
  longAnswer: 2,
  number: 4,
  option: 7,
  shortAnswer: 1,
  yesNo: 5,
};

export const generateInputName = (type, sectionId, questionId) => `${type}-${sectionId}-${questionId}`;

const Question = ({ onChange, questionData }) => {
  const { question, jpaQuestionID, jpaSectionID, jpaLists, isRequired, jpaQuestionTypeID, allowsMultiselect } =
    questionData;

  const Inputs = {
    [QUESTION_TYPE.date]: DateInput,
    [QUESTION_TYPE.file]: FileInput,
    [QUESTION_TYPE.longAnswer]: TextArea,
    [QUESTION_TYPE.number]: TextInput,
    [QUESTION_TYPE.option]: allowsMultiselect ? MultiSelectInput : SelectInput,
    [QUESTION_TYPE.shortAnswer]: TextInput,
    [QUESTION_TYPE.yesNo]: YesNoInput,
  };

  const Input = Inputs[jpaQuestionTypeID];
  const inputName = generateInputName(jpaQuestionTypeID, jpaSectionID, jpaQuestionID);
  const otherProps = {};

  const handleChange = (e) => {
    onChange(e.target.value, jpaQuestionID, jpaSectionID, jpaQuestionTypeID);
  };

  if (jpaQuestionTypeID === QUESTION_TYPE.option) {
    otherProps.options = jpaLists?.map((answer) => ({ value: answer.valueDesc, label: answer.valueDesc }));
  }
  if (jpaQuestionTypeID === QUESTION_TYPE.number) {
    otherProps.type = 'number';
  }
  if (allowsMultiselect) {
    otherProps.multi = allowsMultiselect;
  }

  const containerStyles = classNames('ddf-question', {
    'date-picker': jpaQuestionTypeID === QUESTION_TYPE.date,
    'long-answer': jpaQuestionTypeID === QUESTION_TYPE.longAnswer,
    'short-answer': jpaQuestionTypeID === QUESTION_TYPE.shortAnswer,
    'yes-no': jpaQuestionTypeID === QUESTION_TYPE.yesNo,
    file: jpaQuestionTypeID === QUESTION_TYPE.file,
    number: jpaQuestionTypeID === QUESTION_TYPE.number,
    option: jpaQuestionTypeID === QUESTION_TYPE.option,
  });

  return (
    <div className={containerStyles}>
      <Input
        hint={questionData.helpInfo}
        id={inputName}
        label={question}
        name={inputName}
        onChange={handleChange}
        required={isRequired}
        {...otherProps}
      />
    </div>
  );
};

export default Question;
