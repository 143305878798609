export const currentEnvironment = import.meta.env.VITE_ENVIRONMENT;

type Mode = 'show' | 'hide';
type GetEnvironmentVisibilityProps = {
  mode: Mode;
  dev: boolean;
  test: boolean;
  demo: boolean;
  prod: boolean;
};
type EnvironmentVisibilityProps = GetEnvironmentVisibilityProps & {
  children: React.ReactNode;
};

const ENVIRONMENTS = {
  demo: 'demo',
  dev: 'development',
  local: 'local',
  prod: 'prod',
  test: 'test',
};

export const getEnvironmentVisibility = ({ mode, dev, test, demo, prod }: GetEnvironmentVisibilityProps) => {
  const allowedEnvironments = [ENVIRONMENTS.local];

  if (mode === 'hide') {
    !demo && allowedEnvironments.push(ENVIRONMENTS.demo);
    !dev && allowedEnvironments.push(ENVIRONMENTS.dev);
    !prod && allowedEnvironments.push(ENVIRONMENTS.prod);
    !test && allowedEnvironments.push(ENVIRONMENTS.test);
  } else if (mode === 'show') {
    demo && allowedEnvironments.push(ENVIRONMENTS.demo);
    dev && allowedEnvironments.push(ENVIRONMENTS.dev);
    prod && allowedEnvironments.push(ENVIRONMENTS.prod);
    test && allowedEnvironments.push(ENVIRONMENTS.test);
  }

  return allowedEnvironments.includes(currentEnvironment);
};

const EnvironmentVisibility = ({ children, ...props }: EnvironmentVisibilityProps) => {
  const showContent = getEnvironmentVisibility(props);

  return showContent ? <>{children}</> : null;
};

export default EnvironmentVisibility;
