/*
 * - Exposes class and methods to interact with the file storage API
 */

import request, { REQUEST_METHODS } from '@api/Request';
import { FileStorageEndpoints } from './config';
import { UploadPnAttachmentsRequest, UploadPnAttachmentsResponse } from './types';

export default class PublicNoticeService {
  // Queries ---------------------------------------------------------------------------------------

  public static readonly uploadPnAttachments = (
    data: UploadPnAttachmentsRequest
  ): Promise<UploadPnAttachmentsResponse> =>
    request({
      url: FileStorageEndpoints.uploadPnAttachments(),
      method: REQUEST_METHODS.POST,
      data,
    });
}
