import Tooltip from '@components/tooltip/tooltip';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { customSelectStyles, customSelectStylesNonRequired } from '@forms/input-forms/_helper';
import '@styles/index.scss';
import { Label, Textarea } from '@trussworks/react-uswds';
import '@components/textarea/textarea.scss';

const MultiSelectInput = ({
  closeMenuOnSelect = false,
  handleBlur,
  handleChange,
  isLoading,
  label,
  menuPlacement,
  multi,
  name,
  options,
  readOnly,
  required,
  tooltip,
  tooltipClickable,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  const inputError = errors[name];

  const errorStyle = required ? customSelectStyles : customSelectStylesNonRequired;

  const selectedValues = [getValues(name)] || [];
  const displayValue = selectedValues?.[0]?.map((option) => option.label).join(', ');

  return (
    <>
      <Label htmlFor={name}>
        <span id={`${name}_label`}>{label}</span>
        {required ? (
          <span className='asterisk-color'>*</span>
        ) : (
          <span style={{ fontStyle: 'italic' }}> (optional)</span>
        )}
        {tooltip && (
          <>
            <Tooltip
              name={name}
              header={label}
              content={tooltip}
              clickable={tooltipClickable}
              iconStyle={{ marginLeft: '5px' }}
            />
          </>
        )}
      </Label>
      {readOnly ? (
        <Textarea className={'read-only-multiselect'} id={name} name={name} readOnly={true} value={displayValue} />
      ) : (
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <Select
              closeMenuOnSelect={closeMenuOnSelect}
              hideSelectedOptions={false}
              inputId={name}
              isDisabled={readOnly}
              isLoading={isLoading}
              isMulti={multi}
              menuPlacement={menuPlacement}
              name={name}
              onBlur={(e) => {
                onBlur(e);
                handleBlur && handleBlur(e);
              }}
              onChange={(e) => {
                onChange(e);
                handleChange && handleChange(e);
              }}
              options={options}
              readOnly={readOnly}
              required={required}
              ref={ref}
              styles={inputError ? errorStyle : {}}
              value={value}
            />
          )}
        />
      )}
    </>
  );
};

export default MultiSelectInput;
