import { connect } from 'redux-bundler-react';

import JDFormInstructions from '@forms/static-forms/JDFormInstructions';
import JDFormGeneralProjectInformation from '@forms/input-forms/JDForm/JDFormGeneralProjectInformation';
import JDFormContacts from '@forms/input-forms/JDForm/JDFormContacts';
import SupportingInformation from '@forms/input-forms/common/SupportingInformation';
import JDFormCertifySignSubmit from '@forms/input-forms/JDForm/JDFormCertifySignSubmit';
import JDFormPropertyOwners from '@forms/input-forms/JDForm/JDFormPropertyOwners';
import JDFormAquaticResources from '@forms/input-forms/JDForm/JDFormAquaticResources';

//List of steps for vertical stepper, these can also be used for form header. index = step ID
export const JDForm1aMetadata = [
  JDFormInstructions.metadata,
  JDFormGeneralProjectInformation.metadata,
  JDFormContacts.metadata,
  JDFormPropertyOwners.metadata,
  JDFormAquaticResources.metadata,
  SupportingInformation.metadata,
  JDFormCertifySignSubmit.metadata,
];

const generateSections = (jDData, jdRequestID, doUpdateJDRequest) => {
  const sections = [
    {
      id: 'JD1aInstructions',
      name: 'Instructions',
      content: JDFormInstructions,
    },
    {
      id: 'JD1aGeneralProjectInformation',
      name: 'General Project Information',
      content: JDFormGeneralProjectInformation,
    },
    {
      id: 'JD1aContactInformation',
      name: 'Contact Information',
      content: JDFormContacts,
    },
    {
      id: 'JD1aPropertyOwners',
      name: 'Property Owners',
      content: JDFormPropertyOwners,
    },
    {
      id: 'JD1aAquaticResources',
      name: 'Aquatic Resources',
      content: JDFormAquaticResources,
    },
    {
      id: 'JD1aSupportingInformation',
      name: 'Supporting Information',
      content: SupportingInformation,
      componentID: jdRequestID,
      subtext: (
        <>
          <p>
            To facilitate your request, we strongly encourage a copy of the Delineation Report and supporting
            information be uploaded in this section. The types of supporting information that should be provided are
            contained in the drop-down list when you click on the "Add a Document" tab. Information that is most helpful
            in the USACE review are aerial photographs, automated wetland data sheets, and maps/plans/plots. Multiple
            documents of the same file type can be combined and uploaded as one document.
          </p>
        </>
      ),
      requestData: jDData,
      updateRequestData: doUpdateJDRequest,
    },
    {
      id: 'JD1aCertifySignSubmit',
      name: 'Certify Sign Submit',
      content: JDFormCertifySignSubmit,
    },
  ];
  return sections;
};

const JDForm1a = connect(
  'doUpdateJDRequest',
  'selectActiveStep',
  'selectJDRequestID',
  'selectJDData',
  ({ doUpdateJDRequest, activeStep, jDRequestID, jDData }) => (
    <>
      {generateSections(jDData, jDRequestID, doUpdateJDRequest, activeStep).map((section, i) => (
        <section id={section.id} name={section.name} style={{ display: activeStep?.id === i ? 'inline' : 'none' }}>
          <section.content
            stepNo={i}
            componentID={section?.componentID}
            subtext={section?.subtext}
            requestData={section?.requestData}
            updateRequestData={section?.updateRequestData}
          />
        </section>
      ))}
    </>
  )
);

export default JDForm1a;
