/*
 * - Sets up instance of axios
 * - Consumes axios instance on request function
 * - Request function is a wrapper around axios that handles success and error responses
 */

import axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

export const REQUEST_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
};

export const client = (() =>
  axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      Accept: 'application/json, text/plain, */*',
    },
  }))();

client.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const accessToken = localStorage.getItem('KEYCLOAK_TOKEN');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

client.interceptors.response.use(
  (response: AxiosResponse) => {
    const status = response?.data?.status;
    const message = response?.data?.message;

    // display error toast for errors handled on the BE - any status other than 'Success'
    if (status !== 'Success' && message?.length > 0) {
      toast.error(message, {
        autoClose: false,
      });
    }

    return response;
  },
  (error: AxiosError) => {
    toast.error('An error occurred. If this persists, please contact support.');
    return Promise.reject(error);
  }
);

const request = async (options: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse) => {
    const { data } = response;
    return data;
  };

  const onError = function (error: AxiosError) {
    return Promise.reject({
      message: error.message,
      code: error.code,
      response: error.response,
    });
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
