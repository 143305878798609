import Icon from '@components/icon/Icon';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import classnames from 'classnames';
import { useState } from 'react';
import LinkButton from '../link/linkButton';

const generateErrorDisplay = (errors) =>
  errors?.map(
    (error, index) =>
      error?.renderFunction?.(error?.errorData) || (
        <li key={error?.id} id={error?.id}>
          <LinkButton onClick={error.onClick}>{error.text}</LinkButton>
        </li>
      )
  );

const ErrorSummaryBase = ({ className, defaultExpanded = true, errors, hasErrors, title, typeSuffix }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const expandIcon = isExpanded ? mdiChevronUp : mdiChevronDown;
  const iconAriaLabel = `${isExpanded ? 'close' : 'open'} error summary`;
  const showList = hasErrors && isExpanded;
  const expandText = hasErrors ? <span className='expand-text'> - expand to view</span> : '';
  const containerStyles = classnames('error-summary', className, {
    collapsed: !showList,
  });
  const alertStyles = classnames('usa-alert', {
    'usa-alert--error': hasErrors,
    'usa-alert--success': !hasErrors,
  });
  const handleExpandClick = () => hasErrors && setIsExpanded((prev) => !prev);
  const handleExpandKeyup = (e) => e.key === ' ' && handleExpandClick(); // open with space bar, similar to inputs

  return (
    <div className={containerStyles}>
      <div className={alertStyles} id='error_list' role='alert'>
        <div className='usa-alert__body'>
          <div className='usa-alert__heading' onClick={handleExpandClick}>
            {title}
            {expandText}
            {hasErrors && (
              <div className='error-summary-chevron' onKeyUp={handleExpandKeyup} tabIndex={0}>
                <Icon aria-label={iconAriaLabel} size={'36px'} path={expandIcon} />
              </div>
            )}
          </div>
          <div className='error-summary-content'>
            <ul className='error-list-container' id={`error_list_summary${typeSuffix}`}>
              {generateErrorDisplay(errors)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorSummaryBase;
