/*
 * - Definitions for Lookup queries
 */

import LookupService from './service';
import { District, DistrictsByCode } from './types';

export const LOOKUP_QUERY_KEYS = {
  GET_DISTRICTS: 'GET_DISTRICTS',
};

export class LookupQueries {
  public static readonly getDistricts = async (): Promise<DistrictsByCode> => {
    const { data } = await LookupService.getDistricts();

    const districtsByCode: DistrictsByCode = {};

    data.forEach((district: District) => {
      if (!districtsByCode[district.orgShortName]) {
        districtsByCode[district.orgShortName] = {
          orgAddress: district.orgAddress,
          orgName: district.orgName,
          orgPhone: district.orgPhone,
          orgUrl: district.orgUrl,
        };
      }
    });

    return districtsByCode;
  };
}
