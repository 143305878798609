import { create } from 'zustand';
import _debounce from 'lodash/debounce';

type PublicNoticesView = 'table' | 'map';
type PublicNoticesSortOrder = 'asc' | 'desc';

interface PublicNoticesState {
  apiValidationErrors: string[];
  debouncedSearchText: string;
  districtCodes: string[];
  itemsPerPage: number;
  pageCount: number;
  pageNumber: number;
  recordCount: number;
  searchText: string;
  sortColumn: string | null;
  sortOrder: PublicNoticesSortOrder;
  stateCodes: string[];
  view: PublicNoticesView;
  setDistrictCodes: (districtCodes: string[]) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  setPageCount: (pageCount: number) => void;
  setPageNumber: (pageNumber: number) => void;
  setRecordCount: (recordCount: number) => void;
  setSearchText: (searchText: string) => void;
  setSortColumn: (sortColumn: string | null) => void;
  setSortOrder: (sortOrder: PublicNoticesSortOrder) => void;
  setStateCodes: (stateCodes: string[]) => void;
  setView: (view: PublicNoticesView) => void;
  toggleSortOrder: () => void;
  clearApiValidationErrors: () => void;
  setApiValidationErrors: (apiValidationErrors: string[]) => void;
  resetToDefaults: () => void;
}

const setDebouncedSearchText = _debounce((set: any, searchText: string) => {
  set({ debouncedSearchText: searchText });
}, 500);

export const usePublicNotices = create<PublicNoticesState>((set, get) => {
  const defaults: PublicNoticesState = {
    apiValidationErrors: [],
    debouncedSearchText: '',
    districtCodes: [],
    itemsPerPage: 10,
    pageCount: 0,
    pageNumber: 1,
    recordCount: 0,
    searchText: '',
    sortColumn: '',
    sortOrder: 'asc',
    stateCodes: [],
    view: 'table',

    clearApiValidationErrors: () => set({ apiValidationErrors: [] }),
    setApiValidationErrors: (apiValidationErrors: string[]) => set({ apiValidationErrors }),
    setDistrictCodes: (districtCodes: string[]) => set({ districtCodes }),
    setPageCount: (pageCount: number) => set({ pageCount }),
    setPageNumber: (pageNumber: number) => set({ pageNumber }),
    setItemsPerPage: (itemsPerPage: number) => set({ itemsPerPage }),
    setRecordCount: (recordCount: number) => {
      const pageCount = Math.ceil(recordCount / get().itemsPerPage);
      get().setPageCount(pageCount);
      return set({ recordCount });
    },
    setSearchText: (searchText: string) => {
      setDebouncedSearchText(set, searchText);
      set({ searchText });
    },
    setSortColumn: (sortColumn: string | null) => set({ sortColumn }),
    setSortOrder: (sortOrder: PublicNoticesSortOrder) => set({ sortOrder }),
    setStateCodes: (stateCodes: string[]) => set({ stateCodes }),
    setView: (view: PublicNoticesView) => set({ view }),
    toggleSortOrder: () => set((state) => ({ sortOrder: state.sortOrder === 'asc' ? 'desc' : 'asc' })),
    resetToDefaults: () => set(defaults),
  };
  return defaults;
});
