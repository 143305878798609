import { useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorSummary from '@components/error-summary/ErrorSummary';

import { generateInputName, QUESTION_TYPE } from './Question';
import Section from './Section';
import './ddf.scss';

const validationText = {
  [QUESTION_TYPE.date]: 'Date is required',
  [QUESTION_TYPE.file]: 'File is required',
  [QUESTION_TYPE.fullAnswer]: 'This field is required',
  [QUESTION_TYPE.number]: 'This field is required',
  [QUESTION_TYPE.option]: 'An option must be selected',
  [QUESTION_TYPE.shortAnswer]: 'This field is required',
  [QUESTION_TYPE.yesNo]: 'Yes or no must be selected',
};

const generateValidation = (sections) => {
  const validation = {};

  sections?.forEach((section) => {
    section?.jpaQuestions?.forEach((question) => {
      if (question.isRequired) {
        const name = generateInputName(question.jpaQuestionTypeID, section.jpaSectionID, question.jpaQuestionID);
        validation[name] = yup.string().required(validationText[question.jpaQuestionTypeID]);
      }
    });
  });

  return validation;
};

const Form = ({ formData, isInformation, onChange }) => {
  const schema = useMemo(() => yup.object().shape(generateValidation(formData?.jpaSections)), [formData?.jpaSections]);
  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: 'onBlur',
    stateOptions: [],
  });

  const {
    formState: { errors },
    trigger,
  } = methods;

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <FormProvider {...methods}>
      <ErrorSummary defaultExpanded={false} errors={errors} />
      <div className='ddf-form'>
        {formData?.subtitle && <div className='ddf-form-subtitle'>{formData?.subtitle}</div>}
        <h1 className='ddf-form-title'>{formData?.formTitle}</h1>
        <div className='ddf-form-header'>{isInformation ? formData?.jpaInitialContent : formData?.jpaRegionHeader}</div>
        {formData?.jpaSections?.map((sectionData) => (
          <Section key={sectionData?.jpaSectionID} onChange={onChange} sectionData={sectionData} />
        ))}
        <div className='ddf-form-footer'>{formData?.jpaRegionFooter}</div>
      </div>
    </FormProvider>
  );
};

export default Form;
