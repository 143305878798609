import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import ReadOnlyMap from '@components/map/readOnlyMap';
import { Accordion, SummaryBox, SummaryBoxContent, SummaryBoxHeading, Tag } from '@trussworks/react-uswds';
import { RequestTypeStrings } from '@src/utils/enums';
import SplitLayout from '@components/layout/splitLayout';
import { calculateGeometryExtent } from '@src/utils/helpers';
import DistrictModal from '@components/map/modals/districtModal';
import { useFormContext } from 'react-hook-form';
import ProjectLocationSummary from '@components/project-location/ProjectLocationSummary';
import EnvironmentVisibility from '@components/environment-visibility/EnvironmentVisibility';

import './projectConfirmationTab.scss';

const renderAccordionItems = (jpaData) =>
  jpaData
    .filter((jpa) => jpa.isValidIP || jpa.isValidGP)
    .map((jpa, index) => ({
      id: index.toString(),
      headingLevel: 'h6',
      title: jpa.orgName,
      content: <p>{jpa.introText}</p>,
      expanded: true,
    }));

const ProjectConfirmationTab = connect(
  'doUpdateRequestLocation',
  'doSecondaryModalOpen',
  'selectRequestFormData',
  'selectDistrictResults',
  'selectPlssResults',
  ({
    doUpdateRequestLocation,
    doSecondaryModalOpen,
    requestFormData,
    districtResults,
    plssResults,
    requestType,
    activeTab,
    orgData,
  }) => {
    const [district, setDistrict] = useState();
    const { watch } = useFormContext();
    const country = watch('country');
    const location = requestFormData?.location;
    const summaryData = {
      ...location,
      country,
      district,
    };

    useEffect(() => {
      if (plssResults) {
        doUpdateRequestLocation({
          plsssection: plssResults?.FRSTDIVNO,
          plsstownship: plssResults?.TWNSHPNO,
          plssrange: plssResults?.RANGENO,
        });
      }
    }, [plssResults, doUpdateRequestLocation]);

    useEffect(() => {
      if (districtResults?.length > 1 && (!district || !districtResults.some((d) => d === district))) {
        doSecondaryModalOpen(DistrictModal, { setDistrict });
      } else if (districtResults?.length === 1 && districtResults[0] !== district) {
        setDistrict(districtResults[0]);
      }
    }, [districtResults, district, doSecondaryModalOpen]);

    useEffect(() => {
      doUpdateRequestLocation({ district: district });
    }, [doUpdateRequestLocation, district]);

    const renderJPAAccordion = (
      <Accordion
        key='joint-permit'
        className='jpa-content margin-bottom-2'
        bordered
        items={[
          {
            id: 'joint-permit',
            headingLevel: 'h6',
            title:
              'Based on the project location, additional regulatory requirements may be necessary for the permit process.',
            content:
              orgData?.length > 1 ? (
                <Accordion
                  className='jpa-items'
                  bordered
                  items={renderAccordionItems(orgData)}
                  multiselectable={true}
                />
              ) : (
                <div>
                  <h6>{orgData?.[0]?.orgName}:</h6>
                  <p>{orgData?.[0]?.introText}</p>
                </div>
              ),
            expanded: true,
          },
        ]}
      />
    );

    return (
      <>
        <SplitLayout>
          {activeTab === 2 ? (
            <ReadOnlyMap
              geometry={location?.geometry}
              extent={calculateGeometryExtent(location?.geometry)}
              height='100%'
            />
          ) : null}
          <ProjectLocationSummary
            areaLabel={`${requestType === '6' ? 'Area of Violation:' : 'Project Area'}`}
            directionsLabel={`${requestType === '6' ? 'Directions to Site Location:' : 'Directions to Project Location'}`}
            summaryData={summaryData}
          />
        </SplitLayout>
        <div className='row'>
          <div className='col'>
            <SummaryBox>
              <SummaryBoxContent>
                <SummaryBoxHeading headingLevel='h4'>Please review the following:</SummaryBoxHeading>
                <ul>
                  <li>
                    Selected Request: <Tag background=''>{RequestTypeStrings[requestType]}</Tag>
                  </li>
                  <li className='mb-1'>
                    Confirm that the Project Location information above is correct. The project's location determines
                    the range of available requests within that specific geographical area. This information cannot be
                    modified using the RRS once a request has been created.
                  </li>
                  {requestType === '2' && (
                    <li>
                      It is agency practice to honor requests for JDs unless it is clearly impracticable to do so, such
                      as when the USACE is unable to gain access to a site to complete a JD or the USACE lacks other
                      information necessary to respond to the request based on a sound technical record.
                    </li>
                  )}
                  {requestType === '2' && (
                    <li>
                      A jurisdictional determination typically requires a delineation of aquatic resources on the
                      specified property. Persons who would like to request a USACE JD may include an aquatic resources
                      delineation with their request to facilitate timely processing by the USACE. There may be a delay
                      if the USACE needs to conduct the delineation, especially if the project site is large or contains
                      many wetlands, other special aquatic sites, and other waters. Please contact your local USACE
                      Regulatory office to determine current processing times.
                    </li>
                  )}
                  {requestType === '4' && (
                    <li>
                      Please be advised that a Nationwide Permit verification relies on a delineation of Aquatic
                      Resources within the project boundary to note where impacts may be occurring. Failure to provide
                      this information may add additional time to the review of your request.
                    </li>
                  )}
                </ul>
                <EnvironmentVisibility mode='hide' prod test>
                  {orgData?.length > 0 && renderJPAAccordion}
                </EnvironmentVisibility>
                Press the <span className='text-bold'>Continue</span> button below to begin to your request.
                <br />
                If changes are required, press the <span className='text-bold'>Previous</span> button to return to the
                Project Location tab.
              </SummaryBoxContent>
            </SummaryBox>
          </div>
        </div>
      </>
    );
  }
);
export default ProjectConfirmationTab;
