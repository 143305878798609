import { useState, useEffect, useCallback, useMemo } from 'react';
import useErrorFocus from '@hooks/useErrorFocus';
import { connect } from 'redux-bundler-react';
import { Alert, Accordion } from '@trussworks/react-uswds';

import FieldHeader from '@forms/components/Form/FieldHeader';
import DatePicker from '@components/date-picker/DatePicker';
import TextAreaInput from '@components/textarea/TextArea';
import SelectInput from '@components/select/Select';
import ExternalLink from '@components/external-link/ExternalLink';
import Tooltip from '@components/tooltip/tooltip';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { Row } from '@src/app-pages/_shared/helper';
import { dateBeforeA } from '@src/utils/helpers';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import IPACSpeciesTable from './IPACSpeciesTable';
import { ErrorMessages, IPACSupportedFootprintGeometryType } from '@src/utils/enums';

export const PermitsFormOtherLawsRegulationsMetadata = {
  sectionName: 'Other Laws and Regulations',
};

const tooltipContent = {
  gpHistoricTooltip:
    'If you are not a federal agency, and if any historic properties have the potential to be affected by the proposed Nationwide Permit activity, list the name(s) of those historic properties that have the potential to be affected by the proposed Nationwide Permit activity. If you are a Federal agency, and the proposed nationwide permit activity requires a PCN, you must provide documentation demonstrating compliance with Section 106 of the National Historic Preservation Act.',
  ipHistoricTooltip:
    'It is the USACE responsibility to define area of potential effect/permit area for the project. While not required, it can be helpful to work with the USACE to determine the area of potential effect/permit area for the project during the pre-application phase of the project. If you have knowledge of historic properties that would have the potential to be impacted by activities occurring within the area of potential effect/permit area, you should list that information here.',
  gpEndangeredTooltip:
    'If you are not a federal agency, and if any listed species or designated critical habitat might be affected or is in the vicinity of the proposed Nationwide Permit activity, or if the proposed nationwide permit activity is located in designated critical habitat, list the name(s) of those endangered or threatened species that might be affected by the proposed Nationwide Permit activity or utilize the designated critical habitat that might be affected by the proposed Nationwide Permit activity. If you are a Federal agency, and the proposed Nationwide Permit activity requires a preconstruction notification, you must provide documentation demonstrating compliance with Section 7 of the Endangered Species Act.',
  ipEndangeredTooltip:
    'It is the USACE responsibility to define action area for the project. While not required, it can be helpful to work with the USACE to determine the action area for the project during the pre-application phase of the project. If you have knowledge of endangered species that may be affected by activities occurring within the action area, you should list that information here.',
  section408helper: (
    <>
      <p>
        The U.S. Army Corps of Engineers (USACE) Section 408 program provides permission for people, towns, or
        businesses to alter or impact a USACE project, like a dam, levee, or waterway.
      </p>
      <p>
        Before any changes can be made, the Section 408 program reviews alterations to ensure they won't harm the
        project or the public.
      </p>
      <p>
        You can find more information about the Section 408 at{' '}
        <ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Section408/' />
      </p>
    </>
  ),
  riverTooltipContent: (
    <>
      If the proposed Nationwide Permit activity will occur in a river in the National Wild and Scenic River System or
      in a river officially designated by Congress as a "study river" under the Wild and Scenic Rivers Act, provide the
      name of the river. For a list of Wild and Scenic Rivers and study rivers, please visit{' '}
      <span className='text-bold'>
        <ExternalLink href='http://www.rivers.gov' title='Rivers.org' content='http://www.rivers.gov/' />
      </span>
    </>
  ),
  ipRiverTooltipContent: (
    <>
      If the proposed activity will occur in a river in the National Wild and Scenic River System or in a river
      officially designated by Congress as a "study river" under the Wild and Scenic Rivers Act, provide the name of the
      river. For a list of Wild and Scenic Rivers and study rivers, please visit{' '}
      <span className='text-bold'>
        <ExternalLink href='http://www.rivers.gov' title='Rivers.org' content='http://www.rivers.gov/' />
      </span>
    </>
  ),
};

const questionLabel = {
  gpHistoricQuestion:
    'List any historic properties that have the potential to be affected by the proposed Nationwide Permit activity. If no historic properties exist, enter "None" or "I\'m not sure" if you can\'t determine this information.',
  ipHistoricQuestion:
    'Describe your current knowledge of historic properties that would have the potential to be impacted by activities occurring within the area of potential effect/permit area. If no historic properties exist, enter "None" or "I\'m not sure" if you can\'t determine this information.',
  gpEndangeredQuestion:
    'List any Additional Threatened or Endangered Species not included above. If no endangered species exist, enter "None" or "I\'m not sure" if you can\'t determine this information.',
  ipEndangeredQuestion:
    'Describe your current knowledge of endangered species that may be affected by activities occurring within the project or action area. If no endangered species exist, enter "None" or "I\'m not sure" if you can\'t determine this information.',
};

const PermitsFormOtherLawsRegulations = connect(
  'doUpdateSectionValidity',
  'doGetIPACSpeciesList',
  'doUpdatePermitRequest',
  'selectRequestAPIData',
  'selectSelectedRequest',
  'selectProjectGeometry',
  'selectSteps',
  'selectActiveStep',
  'selectPermitData',
  'selectEndSpeciesList',
  'selectIsReadOnly',
  ({
    doUpdateSectionValidity,
    doGetIPACSpeciesList,
    doUpdatePermitRequest,
    requestAPIData,
    selectedRequest,
    projectGeometry,
    steps,
    activeStep,
    permitData,
    endSpeciesList,
    isReadOnly,
    stepNo,
  }) => {
    const speciesFromDB = useMemo(
      () => permitData?.species?.map((species) => species.commonName),
      [permitData?.species]
    );
    const [endangeredSpeciesList, setEndangeredSpeciesList] = useState(null);
    const [selectedSpecies, setSelectedSpecies] = useState([]);
    const [speciesData, setSpeciesData] = useState([]);
    const thisSectionStepStatus = useMemo(() => steps.find((step) => step.id === stepNo)?.touched, [steps, stepNo]);

    const gpSchema = {
      affectedSpecies: yup.string().required(ErrorMessages.Required),
      impactedHistoricalProperty: yup.string().required(ErrorMessages.Required), /// NEEDS TO BE REQUIRED FOR GP, AND NULLABLE FOR IP
      isWildScenicRiver: yup.string().required(ErrorMessages.SelectOption),
      wildScenicRiverName: yup.string().when('isWildScenicRiver', {
        is: 'Yes',
        then: (schema) => schema.required(ErrorMessages.Required),
      }),
      isS408PermissionRequired: yup.string().required(ErrorMessages.SelectOption),
      isS408RequestSubmitted: yup
        .string()
        .when('isS408PermissionRequired', {
          is: 'Yes',
          then: (schema) => schema.required(ErrorMessages.SelectOption),
        })
        .nullable(),
      s408RequestSubmittedDate: yup
        .string()
        .when('isS408RequestSubmitted', {
          is: 'true',
          then: (schema) => schema.required(ErrorMessages.Required),
        })
        .nullable(),
    };
    const ipSchema = {
      affectedSpecies: yup.string().required(ErrorMessages.Required),
      impactedHistoricalProperty: yup.string().required(ErrorMessages.Required),
      isWildScenicRiver: yup.string().required(ErrorMessages.SelectOption),
      wildScenicRiverName: yup.string().when('isWildScenicRiver', {
        is: 'Yes',
        then: (schema) => schema.required(ErrorMessages.Required),
      }),
      isS408PermissionRequired: yup.string().required(ErrorMessages.SelectOption),
      isS408RequestSubmitted: yup
        .string()
        .when('isS408PermissionRequired', {
          is: 'Yes',
          then: (schema) => schema.required(ErrorMessages.SelectOption),
        })
        .nullable(),
      s408RequestSubmittedDate: yup
        .string()
        .when('isS408RequestSubmitted', {
          is: 'true',
          then: (schema) => schema.required(ErrorMessages.Required),
        })
        .nullable(),
    };

    const schema = selectedRequest === '3' ? yup.object().shape(ipSchema) : yup.object().shape(gpSchema);

    const defaultValues = {
      affectedSpecies: permitData?.affectedSpecies ?? '',
      impactedHistoricalProperty: permitData?.impactedHistoricalProperty ?? '',
      isWildScenicRiver: permitData?.isWildScenicRiver ?? '',
      wildScenicRiverName: permitData?.wildScenicRiverName ?? '',
      isS408PermissionRequired: permitData?.isS408PermissionRequired ?? null,
      isS408RequestSubmitted:
        permitData?.isS408RequestSubmitted !== undefined && permitData?.isS408RequestSubmitted !== null
          ? permitData.isS408RequestSubmitted
            ? 'true'
            : 'false'
          : null,
      s408RequestSubmittedDate:
        permitData?.s408RequestSubmittedDate !== null
          ? permitData?.s408RequestSubmittedDate?.split('T')[0]
          : ('' ?? ''),
    };

    // IPAC projectLocationWKT - accepts only LineString, Polygon, or MultiPolygon
    // https://ipac.ecosphere.fws.gov/location/api#tag/resources/operation/locationResources
    const buildProjectLocationWKT = useCallback(() => {
      let projectLocationWkt;

      switch (projectGeometry?.type) {
        case 'Point':
          // Converting Point Geometry to LineString - requires two coordinates so repeat the point coordinate once
          projectLocationWkt = `LINESTRING (${projectGeometry?.coordinates?.[0]} ${projectGeometry?.coordinates?.[1]},${projectGeometry?.coordinates?.[0]} ${projectGeometry?.coordinates?.[1]})`;
          break;
        case 'LineString':
        case 'MultiPoint':
          // Converting MultiPoint Geometry to LineString
          const formattedCoords = projectGeometry?.coordinates.map((coord) => coord.join(' ')).join(', ');

          projectLocationWkt = `LINESTRING (${formattedCoords})`;
          break;
        case 'MultiLineString':
          // Converting MultiLineString to LineString
          projectLocationWkt = 'LINESTRING (';

          projectGeometry?.coordinates?.forEach((lineString) => {
            const formattedCoords = lineString?.map((coord) => coord?.join(' '))?.join(', ');
            projectLocationWkt += formattedCoords + ', ';
          });

          projectLocationWkt = projectLocationWkt.substring(0, projectLocationWkt.lastIndexOf(', '));
          projectLocationWkt += ')';

          break;
        default:
          break;
      }

      return projectLocationWkt;
    }, [projectGeometry?.coordinates, projectGeometry?.type]);

    // IPAC location.footprint - accepts only Polygon or MultiPolygon
    // https://ipac.ecosphere.fws.gov/location/api#tag/resources/operation/locationResources
    const buildSpeciesFootprint = useCallback(() => {
      let projectGeometryIPAC;

      switch (projectGeometry?.type) {
        case 'Polygon':
          projectGeometryIPAC = {
            coordinates: projectGeometry?.coordinates,
            type: 'Polygon',
          };
          break;
        case 'MultiPolygon':
          projectGeometryIPAC = {
            coordinates: projectGeometry?.coordinates,
            type: 'MultiPolygon',
          };
          break;
        default:
          break;
      }

      const updatedFootprint = {
        coordinates: projectGeometryIPAC?.coordinates,
        type: projectGeometryIPAC?.type,
      };

      return updatedFootprint;
    }, [projectGeometry?.coordinates, projectGeometry?.type]);

    const fetchSpecies = useCallback(async () => {
      try {
        if (Object.values(IPACSupportedFootprintGeometryType).includes(projectGeometry?.type)) {
          const footprint = buildSpeciesFootprint();
          await doGetIPACSpeciesList(footprint, true);
        } else {
          const projectLocationWKT = buildProjectLocationWKT();
          await doGetIPACSpeciesList(projectLocationWKT, false);
        }
      } catch (error) {
        console.error('error', error);
      }
    }, [doGetIPACSpeciesList, projectGeometry?.type, buildSpeciesFootprint, buildProjectLocationWKT]);

    // Load data from API
    useEffect(() => {
      if (requestAPIData?.request?.permits?.[0]?.species) {
        setSpeciesData(requestAPIData?.request?.permits?.[0]?.species);
      }
    }, [requestAPIData]);

    useEffect(() => {
      speciesData && doUpdatePermitRequest({ species: speciesData });
    }, [speciesData, doUpdatePermitRequest]);

    const handleChange = useCallback(
      (event) => {
        const { name, value } = event.target;
        doUpdatePermitRequest({ [name]: value });
      },
      [doUpdatePermitRequest]
    );

    const methods = useForm({
      resolver: yupResolver(schema),
      mode: 'onBlur',
      defaultValues: defaultValues,
      context: { selectedSpecies },
    });

    const {
      formState: { isValid, errors },
      watch,
      trigger,
      setValue,
    } = methods;

    const nationalRiver = watch('isWildScenicRiver');
    const civilWorkSelect = watch('isS408PermissionRequired');
    const submittedRequest = watch('isS408RequestSubmitted');

    useEffect(() => {
      if (speciesFromDB && permitData?.species) {
        const checkedSpecies = speciesFromDB.filter((species) =>
          permitData.species.some((dataSpecies) => dataSpecies.commonName === species)
        );
        setSelectedSpecies(checkedSpecies);
      }
    }, [speciesFromDB, permitData?.species]);

    useEffect(() => {
      if (permitData?.species) {
        // Also update selectedSpecies if needed
        const selectedSpeciesNames = permitData.species.map((species) => species.commonName);
        setSelectedSpecies(selectedSpeciesNames);
      }
    }, [permitData]);

    useEffect(() => {
      if (nationalRiver === 'false' || nationalRiver === 'I am not sure') {
        setValue('wildScenicRiverName', '');
        doUpdatePermitRequest({ wildScenicRiverName: '' });
      }
    }, [nationalRiver, setValue, doUpdatePermitRequest]);

    useEffect(() => {
      if (submittedRequest === 'false' || civilWorkSelect === 'No' || civilWorkSelect === 'I am not sure') {
        setValue('s408RequestSubmittedDate', '');
        doUpdatePermitRequest({ s408RequestSubmittedDate: '' });
      }
    }, [submittedRequest, civilWorkSelect, setValue, doUpdatePermitRequest]);

    useEffect(() => {
      if (civilWorkSelect === 'No' || civilWorkSelect === 'I am not sure') {
        setValue('isS408RequestSubmitted', '');
        doUpdatePermitRequest({ isS408RequestSubmitted: null });
      }
    }, [civilWorkSelect, setValue, doUpdatePermitRequest]);

    //Populate species on section load
    useEffect(() => {
      if (!isReadOnly) {
        fetchSpecies();
      }
    }, [fetchSpecies, isReadOnly]);

    useEffect(() => {
      setEndangeredSpeciesList(endSpeciesList);
    }, [endangeredSpeciesList, endSpeciesList]);

    const handleSpeciesCheck = (event) => {
      const { name, checked } = event.target;

      if (checked === true) {
        setSelectedSpecies((prev) => [...prev, name]);
        const speciesDataFromRequest = requestAPIData?.request?.permits?.[0]?.species || [];
        const addedSpeciesData = endangeredSpeciesList
          .filter((species) => species.optionalCommonName === name)
          .map((item) => {
            const speciesFromRequest = speciesDataFromRequest.find((species) => species.speciesID === item.speciesID);
            return {
              ipacSpeciesID: item.speciesId,
              commonName: speciesFromRequest ? speciesFromRequest.commonName : item.optionalCommonName,
              scientificName: item.optionalScientificName,
              listingStatus: item.listingStatusName,
              profileURL: item.speciesProfileUrl,
              isAffectedSpecies: true,
            };
          });
        setSpeciesData((prev) => [...prev, ...addedSpeciesData]);
      }

      if (checked === false) {
        setSelectedSpecies((prev) => prev.filter((val) => val !== name));
        const removedSpeciesData = speciesData.filter((species) => species.commonName !== name);
        setSpeciesData(removedSpeciesData);
      }
    };

    useEffect(() => {
      doUpdateSectionValidity(
        PermitsFormOtherLawsRegulationsMetadata.sectionName,
        isValid ? true : false,
        stepNo,
        isReadOnly
      );
    }, [isValid, doUpdateSectionValidity, stepNo, isReadOnly]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    const ipacSpeciesAccordion = [
      {
        title: 'USFWS IPAC Species in Project Location',
        content: (
          <IPACSpeciesTable
            fetchSpecies={fetchSpecies}
            handleSpeciesCheck={handleSpeciesCheck}
            endangeredSpeciesList={endangeredSpeciesList}
            selectedSpecies={selectedSpecies}
          />
        ),
        id: '1',
        headingLevel: 'h3',
        expanded: true,
      },
    ];

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly && (
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        )}
        <FieldHeader text='Endangered and/or Threatened Species'>
          <p className='text-bold'>
            The Endangered Species Act (ESA), (16 USC 1531-1544), amended 1988, establishes a National program for the
            conservation of threatened and endangered species of fish, wildlife, and plants and the habitat upon which
            they depend. Section 7(a) of the ESA requires Federal agencies (including the USACE) to consult with the
            U.S. Fish and Wildlife Service (USFWS) to ensure that their actions are not likely to jeopardize the
            continued existence of endangered or threatened species or adversely modify or destroy their critical
            habitats. Section 7(c) of the ESA and the Federal regulations on endangered species coordination (50 CFR §
            402.12) require that Federal agencies prepare biological assessments of the potential effects of major
            actions on listed species and critical habitat.
          </p>
          <p className='text-bold'>
            The USACE evaluates permit applications on a case-by-case basis to determine a project's potential to affect
            threatened and endangered species.
          </p>
        </FieldHeader>
        <Row>
          <p className='h6 border-bottom w-100 padding-bottom-1'>USFWS IPAC Species List</p>
        </Row>
        <Row>
          <div className='col-12'>
            <span>
              I believe that the following species and/or its habitat may occur within my project area. (leave unchecked
              if you're not sure):
            </span>
            <>
              <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Accordion bordered items={ipacSpeciesAccordion} />
              </div>
              <Row>
                <div>
                  <Alert type='warning' heading='IMPORTANT' headingLevel='h4'>
                    <span>
                      The IPAC species list only covers species listed under the Endangered Species Act that are managed
                      by the United States Fish and Wildlife Service. Please consult with the{' '}
                      <ExternalLink
                        href='https://noaa.maps.arcgis.com/apps/webappviewer/index.html?id=68d8df16b39c48fe9f60640692d0e318'
                        title='National Marine Fisheries Service (NMFS) Critical Habitat'
                        content='National Marine Fisheries Service (NMFS) Critical Habitat'
                      />{' '}
                      AND the applicable NMFS "Regional Mapper" for NMFS listed species.
                    </span>
                    {selectedRequest === '3' ? (
                      <p>
                        List the name(s) of any species listed as endangered or threatened under the Endangered Species
                        Act that might be affected by the proposed activity or utilize the designated critical habitat
                        that might be affected by the proposed activity.{' '}
                        <Tooltip clickable name='endangeredTooltip-id' content={tooltipContent.gpEndangeredTooltip} />{' '}
                      </p>
                    ) : (
                      <p>
                        List the name(s) of any species listed as endangered or threatened under the Endangered Species
                        Act that might be affected by the proposed Nationwide Permit activity or utilize the designated
                        critical habitat that might be affected by the proposed Nationwide Permit activity.{' '}
                        <Tooltip clickable name='endangeredTooltip-id' content={tooltipContent.gpEndangeredTooltip} />
                      </p>
                    )}
                  </Alert>
                  <TextAreaInput
                    name='affectedSpecies'
                    rowCount={10}
                    onChange={handleChange}
                    maxLength={4000}
                    label={
                      selectedRequest === '3' ? questionLabel.ipEndangeredQuestion : questionLabel.gpEndangeredQuestion
                    }
                    tooltip={
                      selectedRequest === '3' ? tooltipContent.ipEndangeredTooltip : tooltipContent.gpEndangeredTooltip
                    }
                    readOnly={isReadOnly}
                    required
                  />
                </div>
              </Row>
            </>
          </div>
        </Row>
        <FieldHeader text='Historic Properties'>
          <p className='text-bold'>
            Section 106 of the National Historic Preservation Act of 1966 (NHPA), as amended, requires Federal agencies
            to consider the effects of their undertakings on Historic Properties and afford the Advisory Council on
            Historic Preservation a reasonable opportunity to comment on such undertakings. Therefore, prior to the
            issuance or authorization of any permit under Section 404 of the Clean Water Act or Section 10 of the Rivers
            and Harbors Act, the USACE must consider the effect the permit may have on Historic Properties. Historic
            Properties may include prehistoric or historic districts, sites, buildings, structures, objects, sacred
            sites, and traditional cultural places that are included in, or eligible for inclusion in, the National
            Register of Historic Places.{' '}
          </p>
          <p className='text-bold'>
            The State/Territory or Tribal Historic/Cultural preservation organization in your jurisdiction may be able
            to provide you with resources on how to determine whether your project may have the potential to affect a
            historic/cultural resource.{' '}
          </p>
        </FieldHeader>
        <Row>
          <div className='col-12'>
            <TextAreaInput
              name='impactedHistoricalProperty'
              rowCount={10}
              onChange={handleChange}
              required
              placeholder='List Historic Property'
              maxLength={4000}
              label={selectedRequest === '3' ? questionLabel.ipHistoricQuestion : questionLabel.gpHistoricQuestion}
              tooltip={selectedRequest === '3' ? tooltipContent.ipHistoricTooltip : tooltipContent.gpHistoricTooltip}
              readOnly={isReadOnly}
            />
          </div>
        </Row>
        <FieldHeader text='National Wild and Scenic Rivers'>
          <p className='text-bold'>
            No activity may occur in a component of the National Wild and Scenic River System, or in a river officially
            designated by Congress as a study river for possible inclusion in the system while the river is in an
            official study status, unless the appropriate Federal agency with direct management responsibility for such
            river, has determined in writing that the proposed activity will not adversely affect the Wild and Scenic
            River designation or study status{selectedRequest === '3' && ' (Nationwide Permit General Condition 16)'}.
          </p>
          <p className='text-bold'>
            Information on Wild and Scenic Rivers may be obtained from the appropriate Federal land management agency
            responsible for the designated Wild and Scenic River or Study River (e.g., National Park Service, U.S.
            Forest Service, Bureau of Land Management, U.S. Fish and Wildlife Service).
          </p>
          <ExternalLink
            href='https://rivers.gov'
            title='Rivers.gov'
            content='National Wild and Scenic Rivers System Resource'
          />
        </FieldHeader>
        <Row>
          <div className='col-12'>
            <SelectInput
              label={
                selectedRequest === '3'
                  ? 'Will the proposed activity occur in a component of the National Wild and Scenic River System or in a river officially designated by Congress as a "study river" for possible inclusion in the system while the river is in an official study status?'
                  : 'Will the proposed Nationwide Permit activity occur in a component of the National Wild and Scenic River System or in a river officially designated by Congress as a "study river" for possible inclusion in the system while the river is in an official study status?'
              }
              name='isWildScenicRiver'
              required
              tooltip=''
              readOnly={isReadOnly}
              onChange={handleChange}
            >
              <option key='2' value='Yes'>
                Yes
              </option>
              <option key='3' value='No'>
                No
              </option>
              <option key='4' value={'I am not sure'}>
                I'm not sure
              </option>
            </SelectInput>
          </div>
        </Row>
        {nationalRiver === 'Yes' && (
          <Row>
            <div className='col-12'>
              <TextAreaInput
                name='wildScenicRiverName'
                rowCount={10}
                onChange={handleChange}
                required
                placeholder='Scenic River List'
                maxLength={4000}
                label=' List National Wild and Scenic River or Study River'
                tooltipClickable
                tooltip={
                  selectedRequest === '3' ? tooltipContent.ipRiverTooltipContent : tooltipContent.riverTooltipContent
                }
                readOnly={isReadOnly}
              />
            </div>
          </Row>
        )}
        <FieldHeader text='USACE Civil Work Projects (Section 408)'>
          {selectedRequest === '3' ? (
            <p className='text-bold'>
              An activity that requires Section 408
              <Tooltip clickable name='section-408' content={tooltipContent.section408helper} /> permission and/or
              review is not authorized until the appropriate USACE office issues the Section 408 permission or completes
              its review to alter, occupy, or use the USACE project.
            </p>
          ) : (
            <p className='text-bold'>
              If a Nationwide Permit activity also requires review by, or permission from, the USACE pursuant to 33
              U.S.C. 408
              <Tooltip clickable name='section-408' content={tooltipContent.section408helper} /> because it will alter
              or temporarily or permanently occupy or use a U.S. Army Corps of Engineers (USACE) federally authorized
              Civil Works project (a "USACE project"), the prospective permittee must submit a pre-construction
              notification. An activity that requires Section 408
              <Tooltip clickable name='section-408' content={tooltipContent.section408helper} /> permission and/or
              review is not authorized by a Nationwide Permit until the appropriate USACE office issues the Section 408
              <Tooltip clickable name='section-408' content={tooltipContent.section408helper} /> permission or completes
              its review to alter, occupy, or use the USACE project, and the district engineer issues a written
              Nationwide Permit verification.{' '}
            </p>
          )}
        </FieldHeader>
        <Row>
          <div className='col-12'>
            <SelectInput
              label={
                selectedRequest === '3'
                  ? 'Will the proposed activity require permission from the USACE pursuant to 33 U.S.C. 408 because it will alter or temporarily or permanently occupy or use a U.S. Army Corps of Engineers federally authorized civil works project?'
                  : 'Will the proposed Nationwide Permit activity require permission from the USACE pursuant to 33 U.S.C. 408 because it will alter or temporarily or permanently occupy or use a U.S. Army Corps of Engineers federally authorized civil works project?'
              }
              name='isS408PermissionRequired'
              required
              tooltip=''
              readOnly={isReadOnly}
              onChange={handleChange}
            >
              <option key='2' value='Yes'>
                Yes
              </option>
              <option key='3' value='No'>
                No
              </option>
              <option key='4' value={'I am not sure'}>
                I'm not sure
              </option>
            </SelectInput>
          </div>
        </Row>
        {civilWorkSelect === 'Yes' && (
          <Row>
            <div className='col-12'>
              <SelectInput
                label='Have you submitted a written request for Section 408 permission from the USACE District having jurisdiction over that project?'
                name='isS408RequestSubmitted'
                required
                tooltip=''
                readOnly={isReadOnly}
                onChange={handleChange}
              >
                <option key='2' value='true'>
                  Yes
                </option>
                <option key='3' value='false'>
                  No
                </option>
              </SelectInput>
            </div>
          </Row>
        )}
        {submittedRequest === 'false' && (
          <Row>
            <div className='col-12'>
              <p>
                Please contact the location's District 408 coordinator. See:{' '}
                <ExternalLink
                  href='https://www.usace.army.mil/Missions/Civil-Works/Section408'
                  title='Section 408'
                  content='https://www.usace.army.mil/Missions/Civil-Works/Section408'
                />
                .
              </p>
            </div>
          </Row>
        )}
        {submittedRequest === 'true' && civilWorkSelect === 'Yes' && (
          <Row>
            <div className='col-12'>
              <p>Provide the date your request was submitted to the USACE District: </p>
              <DatePicker
                name='s408RequestSubmittedDate'
                label='Submission Date'
                onChange={handleChange}
                min={dateBeforeA}
                readOnly={isReadOnly}
                required
              />
            </div>
          </Row>
        )}
        <div className='margin-bottom-3' />
      </FormProvider>
    );
  }
);
PermitsFormOtherLawsRegulations.metadata = PermitsFormOtherLawsRegulationsMetadata;
export default PermitsFormOtherLawsRegulations;
