import React from 'react';
import { Card, CardHeader, CardBody, Table } from '@trussworks/react-uswds';
import { Icon } from '@mdi/react';
import { mdiClose } from '@mdi/js';
import './mapLegend.scss';

interface LegendItem {
  icon: JSX.Element;
  label: string;
  description: string;
}

interface MapLegendProps {
  legendItems: LegendItem[];
  isVisible: boolean;
  onClose: () => void;
  columnHeaders?: string[];
}

const MapLegend: React.FC<MapLegendProps> = ({
  legendItems,
  isVisible,
  onClose,
  columnHeaders = ['Icon', 'Label', 'Description'],
}) => {
  if (!isVisible) return null;

  const renderLegendItems = () => (
    <Table bordered>
      <thead>
        <tr>
          {columnHeaders.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {legendItems.map((item, index) => (
          <tr key={index}>
            <td style={{ textAlign: 'center' }}>{item.icon}</td>
            <td style={{ textAlign: 'center', width: '20%' }}>{item.label}</td>
            <td>{item.description}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <div className='map-legend-overlay'>
      <Card>
        <CardHeader className='map-legend-header'>
          <span>Map Legend</span>
          <div className='close-icon' onClick={onClose} aria-label='Close Map Legend'>
            <Icon path={mdiClose} size='28px' />
          </div>
        </CardHeader>
        <CardBody>{renderLegendItems()}</CardBody>
      </Card>
    </div>
  );
};

export default MapLegend;
