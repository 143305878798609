import { connect } from 'redux-bundler-react';

import PreAppForm3Instructions from '@forms/static-forms/PreAppForm3Instructions/PreAppForm3Instructions';
import PreAppForm3GeneralProjectInformation from '@forms/input-forms/PreAppForm/PreAppForm3GeneralProjectInformation';
import PreAppForm3Contacts from '@forms/input-forms/PreAppForm/PreAppForm3Contacts';
import PreAppFormPropertyOwners from '@forms/input-forms/PreAppForm/PreAppFormPropertyOwners';
import SupportingInformation from '@forms/input-forms/common/SupportingInformation';
import PreAppForm3CertifySignSubmit from '@forms/input-forms/PreAppForm/PreAppForm3CertifySignSubmit';

//List of steps for vertical stepper, these can also be used for form header. index = step ID
export const PreAppForm3Metadata = [
  PreAppForm3Instructions.metadata,
  PreAppForm3GeneralProjectInformation.metadata,
  PreAppForm3Contacts.metadata,
  PreAppFormPropertyOwners.metadata,
  SupportingInformation.metadata,
  PreAppForm3CertifySignSubmit.metadata,
];

const generateSections = (preAppData, preAppID, doUpdatePreAppRequest) => {
  const sections = [
    {
      id: 'PreAppForm3Instructions',
      name: 'Instructions',
      content: PreAppForm3Instructions,
    },
    {
      id: 'PreAppForm3GeneralProjectInformation',
      name: 'General Project Information',
      content: PreAppForm3GeneralProjectInformation,
    },
    {
      id: 'PreAppForm3ContactInformation',
      name: 'Contact Information',
      content: PreAppForm3Contacts,
    },
    {
      id: 'PreAppForm3PropertyOwners',
      name: 'Property Owners',
      content: PreAppFormPropertyOwners,
    },
    {
      id: 'PreAppForm3SupportingInformation',
      name: 'Supporting Information',
      content: SupportingInformation,
      componentID: preAppID,
      subtext: (
        <>
          <p>
            To facilitate your request, we strongly encourage a copy of the Delineation Report and supporting
            information be uploaded in this section. The types of supporting information that should be provided are
            contained in the drop-down list when you click on the "Add a Document" tab. Information that is most helpful
            in the USACE review are aerial photographs, automated wetland data sheets, and maps/plans/plots. Multiple
            documents of the same file type can be combined and uploaded as one document.
          </p>
        </>
      ),
      requestData: preAppData,
      updateRequestData: doUpdatePreAppRequest,
    },
    {
      id: 'PreAppForm3CertifySignSubmit',
      name: 'Certify Sign Submit',
      content: PreAppForm3CertifySignSubmit,
    },
  ];
  return sections;
};

const PreAppForm3 = connect(
  'doUpdatePreAppRequest',
  'selectActiveStep',
  'selectPreAppID',
  'selectPreAppData',
  ({ doUpdatePreAppRequest, activeStep, preAppID, preAppData }) => (
    <>
      {generateSections(preAppData, preAppID, doUpdatePreAppRequest, activeStep).map((section, i) => (
        <section id={section.id} name={section.name} style={{ display: activeStep?.id === i ? 'inline' : 'none' }}>
          <section.content
            stepNo={i}
            componentID={section?.componentID}
            subtext={section?.subtext}
            requestData={section?.requestData}
            updateRequestData={section?.updateRequestData}
          />
        </section>
      ))}
    </>
  )
);

export default PreAppForm3;
