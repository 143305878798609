import { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'redux-bundler-react';
import { Button } from '@trussworks/react-uswds';
import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';
import { createColumnHelper } from '@tanstack/react-table';
import FieldHeader from '@forms/components/Form/FieldHeader';
import Icon from '@components/icon/Icon';
import AddPropertyOwnerModal from '@forms/components/modals/AddPropertyOwnerModal';
import AgentsActionsTableCell from '@src/app-components/table/tableCellComponents/AgentsActionsTableCell';
import SelectInput from '@components/select/Select';
import LinkButton from '@components/link/linkButton';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import RightOfEntryUpload from '@forms/components/Form/RightOfEntryUpload';
import TanStackTableBasic from '@src/app-components/table/TanStackTable/TanStackTableBasic';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { ContactTypes, ContactsFormNames, FileTypes, ResourceFiles } from '@src/utils/enums';
import useErrorFocus from '@hooks/useErrorFocus';

export const JDFormPropertyOwnersMetadata = {
  sectionName: 'Property Owners',
};

const JDFormPropertyOwners = connect(
  'doDownloadFile',
  'doDownloadPopulatedFile',
  'doClearContactType',
  'doModalOpen',
  'doUpdateSectionValidity',
  'doUpdateJDRequest',
  'doSaveTable',
  'selectRequestAPIData',
  'selectSteps',
  'selectActiveStep',
  'selectIsReadOnly',
  ({
    doDownloadFile,
    doDownloadPopulatedFile,
    doClearContactType,
    doModalOpen,
    doUpdateSectionValidity,
    doUpdateJDRequest,
    doSaveTable,
    requestAPIData,
    steps,
    activeStep,
    isReadOnly,
    componentID,
    stepNo,
  }) => {
    const thisSectionStepStatus = useMemo(() => steps.find((step) => step.id === stepNo)?.touched, [steps, stepNo]);
    const [rowData, setRowData] = useState([]);
    const propertyOwnerTableLength = rowData?.length;

    const schema = (propertyOwnerTableLength) =>
      yup.object().shape({
        rightOfEntryFile: yup
          .string()
          .test('file-invalid-test', 'Right-of-Entry: File selected is invalid', function (value) {
            return value !== 'error';
          }),
        hasAddPropertyOwners: yup.string().required('Please select an option'),
        propertyOwnersTable: yup.boolean().when('hasAddPropertyOwners', {
          is: (val) => (val === 'true' || val === true) && propertyOwnerTableLength < 1,
          then: () => yup.boolean().required('Property Owners: At least one property owner is required'),
        }),
        ownerEntryComments: yup.string().nullable(),
      });

    const defaultValues = {
      hasAddPropertyOwners:
        requestAPIData?.request?.jdRequests?.[0]?.hasAddPropertyOwners !== null
          ? requestAPIData?.request?.jdRequests?.[0]?.hasAddPropertyOwners
          : '',
      ownerEntryComments: requestAPIData?.request?.ownerEntryComments ?? '',
    };

    const methods = useForm({
      resolver: yupResolver(schema(propertyOwnerTableLength)),
      mode: 'onBlur',
      defaultValues: defaultValues,
    });
    const {
      formState: { errors },
      watch,
      setError,
      trigger,
    } = methods;
    const errorCount = Object.entries(errors)?.length;
    const hasAddPropertyOwners = watch('hasAddPropertyOwners');
    const columnHelper = createColumnHelper();

    const columnDefs = useMemo(
      () => [
        columnHelper.display({
          header: 'Actions',
          id: 'actions',
          cell: ({ row }) => (
            <AgentsActionsTableCell
              row={row}
              rowData={rowData}
              setRowData={setRowData}
              modalComponent={AddPropertyOwnerModal}
              isReadOnly={isReadOnly}
            />
          ),
          size: 60,
          enableSorting: false,
          meta: {
            centerText: true,
          },
        }),
        columnHelper.accessor('firstName', {
          header: 'First Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('middleName', {
          header: 'Middle Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('lastName', {
          header: 'Last Name',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('address', {
          header: 'Address',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 500,
        }),
        columnHelper.accessor('city', {
          header: 'City',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
        }),
        columnHelper.accessor('state', {
          header: 'State',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
        columnHelper.accessor('country', {
          header: 'Country',
          cell: ({ cell }) => <span>{cell.getValue()}</span>,
          size: 75,
        }),
      ],
      [columnHelper, rowData, isReadOnly]
    );

    const clearRowData = useCallback(() => {
      setRowData([]);
      doClearContactType(ContactTypes.PropertyOwner);
    }, [doClearContactType]);

    useEffect(() => {
      rowData && doSaveTable('propOwners', rowData);
    }, [rowData, doSaveTable]);

    useEffect(() => {
      // Load tables from database
      let propertyowners = requestAPIData?.request?.contacts?.filter(
        (contact) => contact.contactType === ContactTypes.PropertyOwner
      );
      // If country is null, default to US
      propertyowners = propertyowners?.map((contact) =>
        contact.country === null ? { ...contact, country: 'US' } : contact
      );
      propertyowners && setRowData(propertyowners);
    }, [requestAPIData]);

    useEffect(() => {
      if (rowData?.length > 0) {
        (hasAddPropertyOwners === 'false' || hasAddPropertyOwners === false) && clearRowData();
      }
    }, [rowData, hasAddPropertyOwners, clearRowData]);

    useEffect(() => {
      if ((hasAddPropertyOwners === 'true' || hasAddPropertyOwners === true) && rowData?.length < 1) {
        setError('propertyOwnersTable', {
          type: 'custom',
          message: 'Property Owners: At least one property owner is required',
        });
      } else {
        trigger('propertyOwnersTable');
      }
    }, [hasAddPropertyOwners, rowData?.length, setError, trigger]);

    useEffect(() => {
      const valid =
        hasAddPropertyOwners === 'false' || hasAddPropertyOwners === false
          ? true
          : (hasAddPropertyOwners === 'true' || hasAddPropertyOwners === true) && rowData?.length > 0;
      const validity = errorCount === 0 && valid;
      doUpdateSectionValidity(JDFormPropertyOwnersMetadata.sectionName, validity, stepNo, isReadOnly);
    }, [errorCount, rowData, hasAddPropertyOwners, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      doUpdateJDRequest({ hasAddPropertyOwners: hasAddPropertyOwners });
    }, [hasAddPropertyOwners, doUpdateJDRequest]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    const placeholder = (
      <div className='d-flex flex-column align-items-center margin-top-1 margin-bottom-1'>
        <p>No property owners found, please click below to add a property owner:</p>
        <div className='margin-top-1'>
          <Button
            className='add-property-owner-button'
            title='Add a Property Owner'
            size='small'
            onClick={() =>
              doModalOpen(AddPropertyOwnerModal, { setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly })
            }
            disabled={isReadOnly}
          >
            <Icon focusable={false} className='margin-right-1' path={mdiAccountPlus} size={'16px'} />
            Add a Property Owner
          </Button>
        </div>
      </div>
    );

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly && (
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        )}
        <FieldHeader
          text='Property Owner(s)'
          subtext='If USACE determines a site visit is needed, you must include contact information for all current deeded property owners and provide a signed right-of-entry for each owner. A right-of-entry form allows USACE to access the project area when conducting a site investigation or jurisdictional determination. This form ensures that USACE has the legal right to enter the property.'
        >
          <p>
            <LinkButton
              onClick={() => doDownloadFile(ResourceFiles.Eng6294, FileTypes.Resource)}
              title='Download a blank Right of Entry form'
              content='Download a blank Right of Entry form'
            />{' '}
            to complete or{' '}
            <LinkButton
              onClick={() => doDownloadPopulatedFile(ContactsFormNames.RightOfEntry, ResourceFiles.Eng6294)}
              title='download a Right of Entry form populated'
              content='download a Right of Entry form populated'
            />{' '}
            with the information you entered. and upload the completed document in the section below.
          </p>
        </FieldHeader>

        <RightOfEntryUpload
          componentID={componentID}
          subText={
            <>
              <p>
                The property owner or an easement holder must provide right-of-entry to the U.S. Army Corps of Engineers
                and be a duly authorized owner of record of the property.
              </p>
              <p>
                {' '}
                If the agent signs the right-of-entry, they must have an agent authorization signed by the property
                owner.
              </p>
            </>
          }
        />

        <div className='ml-2' id='propertyOwnersTable'>
          <SelectInput
            name='hasAddPropertyOwners'
            label='Are there any property owners other than the applicant?'
            required
            className='w-50 mb-3'
            readOnly={isReadOnly}
          >
            <option key='1' value='true'>
              Yes
            </option>
            <option key='2' value='false'>
              No
            </option>
          </SelectInput>
        </div>

        {(hasAddPropertyOwners === 'true' || hasAddPropertyOwners === true) && (
          <>
            <div className='d-flex width-full margin-top-2 padding-bottom-2 justify-content-end'>
              {rowData.length > 0 && (
                <Button
                  className='add-property-owner-button'
                  title='Add a Property Owner'
                  size='small'
                  onClick={() =>
                    doModalOpen(AddPropertyOwnerModal, {
                      setRowData: setRowData,
                      rowData: rowData,
                      isReadOnly: isReadOnly,
                    })
                  }
                  disabled={isReadOnly}
                >
                  <Icon focusable={false} className='margin-right-1' path={mdiAccountPlus} size={'16px'} />
                  Add a Property Owner
                </Button>
              )}
              {rowData.length !== 0 && (
                <Button
                  className={`clear-table-button ${rowData.length === 0 || isReadOnly ? 'disabled' : 'hover'}`}
                  title='Clear Property Owner(s)'
                  size='small'
                  onClick={() => {
                    setRowData([]);
                    doClearContactType(ContactTypes.PropertyOwner);
                  }}
                  disabled={isReadOnly || rowData.length < 1}
                >
                  <Icon focusable={false} className='margin-right-1' path={mdiCloseOctagon} size={'16px'} />
                  Clear Property Owner(s)
                </Button>
              )}
            </div>
            <div className='width-full margin-bottom-2'>
              <TanStackTableBasic data={rowData} columns={columnDefs} placeholder={placeholder} />
            </div>
          </>
        )}
      </FormProvider>
    );
  }
);

JDFormPropertyOwners.metadata = JDFormPropertyOwnersMetadata;

export default JDFormPropertyOwners;
