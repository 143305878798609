import { useQuery } from '@tanstack/react-query';
import { PublicNoticeQueries, PN_QUERY_KEYS } from '@api/services/public-notices';
import { ModalContent } from '@components/modal';
import { connect } from 'redux-bundler-react';
import ModalLoader from '@components/loader/ModalLoader';

import PublicNoticeComment from './PublicNoticeComment';
import '../publicNotices.scss';

const PublicNoticeCommentModal = connect('doModalClose', ({ doModalClose, publicNoticeId }) => {
  const { data, isLoading } = useQuery({
    queryKey: [PN_QUERY_KEYS.GET_BY_ID, publicNoticeId],
    queryFn: () => PublicNoticeQueries.getById(publicNoticeId),
  });

  if (isLoading) {
    return <ModalLoader title='Public Notice' />;
  }

  return (
    <ModalContent title='Submit Public Notice Comments' hasCloseButton={false}>
      <div className='modal-body'>
        <PublicNoticeComment isLoading={isLoading} publicNoticeData={data} onClose={doModalClose} />
      </div>
    </ModalContent>
  );
});

export default PublicNoticeCommentModal;
