import { mdiChat, mdiEye } from '@mdi/js';
import { connect } from 'redux-bundler-react';

import Icon from '@components/icon/Icon';
import PublicNoticeInfoModal from '../public-notice-info/PublicNoticeInfoModal';
import PublicNoticeCommentModal from '../public-notice-comment/PublicNoticeCommentModal';
import NewTooltip from '@src/app-components/tooltip/NewTooltip';

import './actionsTableCell.scss';

const ActionsTableCell = connect('doModalOpen', ({ doModalOpen, row }) => {
  const modalPayload = { publicNoticeId: row.original.publicNoticeID };

  const handleCommentClick = (action) => doModalOpen(PublicNoticeCommentModal, modalPayload);
  const handleViewClick = (action) => doModalOpen(PublicNoticeInfoModal, modalPayload);

  return (
    <div className='button-container'>
      <NewTooltip label='View Full Public Notice' className='view-notice' onClick={handleViewClick}>
        <Icon focusable={false} path={mdiEye} size={'16px'} />
      </NewTooltip>
      <NewTooltip label='Submit Comments' className='submit-comments' onClick={handleCommentClick}>
        <Icon focusable={false} path={mdiChat} size={'16px'} />
      </NewTooltip>
    </div>
  );
});

export default ActionsTableCell;
