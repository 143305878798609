import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const NAB_BALTIMORE = () => (
  <>
    <section id='nab-baltimore'>
      <DistrictInfoTable district='NAB' />
      <p>
        In Pennsylvania, Maryland, and certain military installations within northern Virginia (i.e., Fort Belvoir, Fort
        Myer, and the Pentagon), the Regulatory Request System (RRS) should be used to submit requests for
        pre-application meetings and jurisdictional determinations, to report a potential violation, and to learn the
        basics of the Regulatory Program. Please do not use the RRS to apply for a permit in Pennsylvania, Maryland, and
        certain military installations within northern Virginia (i.e., Fort Belvoir, Fort Myer, and the Pentagon). In
        the District of Columbia, the RRS should be used to apply for Department of the Army authorizations, to submit
        requests for pre-application meetings and jurisdictional determinations, to report a potential violation, and to
        learn the basics of the Regulatory Program. For information on how to obtain a Department of the Army
        authorization or to obtain copies of the permit application forms within the Baltimore District, please visit
        the{' '}
        <ExternalLink
          content='Baltimore District website'
          title='Baltimore District Website'
          href='https://www.nab.usace.army.mil/Missions/Regulatory/Permit-Types-and-Process/'
        />
        .
      </p>
    </section>
  </>
);

export default NAB_BALTIMORE;
