/*
 * - Exposes class and methods to interact with the lookup API
 */

import request, { REQUEST_METHODS } from '@api/Request';
import { FileStorageEndpoints } from './config';
import { GetDistrictsResponse } from './types';

export default class LookupService {
  public static readonly getDistricts = (): Promise<GetDistrictsResponse> =>
    request({
      url: FileStorageEndpoints.getDistricts(),
      method: REQUEST_METHODS.GET,
    });
}
