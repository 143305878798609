import Form from '@components/data-driven-form/Form';
import { useQuery } from '@tanstack/react-query';
import { JpaQueries, JPA_QUERY_KEYS } from '@src/api/services/jpa';
import PageLoader from '@src/app-components/loader/PageLoader';

const JpaForm = ({ formId, version, requestId, onChange }) => {
  const { data, isLoading } = useQuery({
    queryKey: [JPA_QUERY_KEYS.GET_FORM, formId],
    queryFn: () =>
      JpaQueries.getForm({
        formId,
        requestId,
        version,
      }),
    enabled: Number.isInteger(formId) && Number.isInteger(requestId) && Number.isInteger(version),
  });

  return <div>{isLoading ? <PageLoader /> : <Form onChange={onChange} formData={data?.jpaForm} />}</div>;
};

export default JpaForm;
