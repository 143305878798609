import Tooltip from '@components/tooltip/tooltip';
import { useFormContext } from 'react-hook-form';
import { Label, Select, TextInput } from '@trussworks/react-uswds';
import classnames from 'classnames';
import { formatReadOnlyValue } from '@src/utils/helpers';

import './select.scss';

const SelectInput = ({
  children,
  className = 'width-full',
  defaultOption = '-Select Option-',
  group,
  showOptionalText = true,
  label,
  name,
  onBlur,
  onChange,
  options = [],
  readOnly,
  required,
  tooltip,
  tooltipClickable,
  validations,
  ...customProps
}) => {
  const {
    register,
    formState: { errors },
    trigger,
    getValues,
  } = useFormContext();

  const inputError = errors[name];
  const renderedOptions = options.map((option, i) => (
    <option key={option.value} value={option.value ? option.value : option}>
      {option.label ? option.label : option}
    </option>
  ));

  const containerStyles = classnames('select-container', className, {
    'is-invalid': inputError,
  });
  const labelStyles = classnames({
    required: required,
    optional: !required && showOptionalText,
  });

  const { ref: selectRef, ...rest } = register(name, {
    onBlur,
    onChange: async (e) => {
      await trigger(name);
      onChange?.(e);
    },
    ...validations,
  });

  return (
    <>
      <Label className={labelStyles} htmlFor={name}>
        <span id={`${name}_label`}>{label}</span>
        {tooltip && <Tooltip clickable={tooltipClickable} content={tooltip} header={label} name={name} />}
      </Label>
      {readOnly ? (
        <TextInput
          className={'margin-bottom-2 read-only-select'}
          id={name}
          name={name}
          readOnly={readOnly}
          value={formatReadOnlyValue(getValues(name))}
        />
      ) : (
        <div className={containerStyles}>
          <Select
            aria-invalid={inputError ? 'true' : 'false'}
            aria-required={required ? 'true' : 'false'}
            disabled={readOnly}
            id={name}
            inputRef={selectRef}
            name={name}
            required={required}
            {...rest}
            {...customProps}
          >
            <option
              value=''
              style={{ display: required && showOptionalText ? 'none' : 'inline' }}
            >{`${defaultOption}`}</option>
            {!group && renderedOptions}
            {group && <optgroup label={group}>{renderedOptions}</optgroup>}
            {children}
          </Select>
        </div>
      )}
    </>
  );
};

export default SelectInput;
