import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { useFormContext } from 'react-hook-form';
import { Button, Label, TextInput as UswdsTextInput } from '@trussworks/react-uswds';
import classNames from 'classnames';

import Tooltip from '../tooltip/tooltip';
import FilePreview from './FilePreview';

import { filenameRegex } from '@src/utils/regex';
import { ErrorMessages } from '@src/utils/enums';

import './fileinput.scss';
import './filePreview.scss';
import '@styles/index.scss';

const FileInput = connect(
  'selectCombinedRequestFiles',
  'selectMaxFileCount',
  'selectMaxIndFileSize',
  'selectMaxTotalFileSize',
  'selectSelectedFiles',
  'selectTotalFileSize',
  'selectCombinedComponentFiles',
  ({
    combinedRequestFiles,
    maxFileCount,
    maxIndFileSize,
    maxTotalFileSize,
    selectedFiles,
    totalFileSize,
    combinedComponentFiles,
    accept,
    chooseText,
    className = 'width-full',
    doDeleteFile = () => {},
    doDownloadFile = () => {},
    doUploadFile = () => {},
    dragText,
    hint,
    id,
    label,
    multiple = false,
    name,
    onChange = () => {},
    onDrop = () => {},
    readOnly,
    required,
    setFileErrors = () => {},
    showUploadButton = false,
    showDownloadButton = false,
    showDeleteButton = false,
    showOptionalText = true,
    tooltip,
    tooltipClickable,
    value,
    resetFileErrors = false,
    ...customProps
  }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorText, setErrorText] = useState([]);
    const [files, setFiles] = useState([]);
    const previewHeaderText = files?.length > 1 ? `${files.length} files selected` : 'Selected file';

    const defaultText = {
      dragText: `Drag file${multiple ? 's' : ''} here or `,
      chooseText: 'choose from folder',
      errorText: 'This is not a valid file type.',
    };

    const {
      register,
      formState: { errors },
    } = useFormContext();
    const inputError = errors[name];

    const fileInputStyles = classNames(
      'usa-file-input',
      {
        'usa-file-input--disabled': readOnly,
      },
      className
    );

    const targetStyles = classNames('usa-file-input__target', {
      'usa-file-input--drag': isDragging,
      'has-invalid-file': (showError || inputError) && resetFileErrors === false,
    });

    const instructionStyles = classNames(
      'usa-file-input__instructions',
      {
        'display-none': files?.length > 0,
      },
      {
        'file-invalid': (showError || inputError) && resetFileErrors === false,
      }
    );

    const labelStyles = classNames('usa-label', {
      required: required,
      optional: !required && showOptionalText,
    });

    const fileInputPreviewHeadingClasses = classNames('usa-file-input__preview-heading', {
      'file-input-disabled': readOnly,
      'file-input-invalid': (showError || inputError) && resetFileErrors === false,
    });

    const fileInputBoxClasses = classNames('usa-file-input__box', {
      'file-input-disabled': readOnly,
    });

    const preventInvalidFiles = (e) => {
      setShowError(false);
      setFileErrors(false);
      // Data transfer or target attributes depends on if event is an onChange or onDrop
      const stagedFiles = e?.dataTransfer?.files ? Array.from(e?.dataTransfer?.files) : Array.from(e?.target?.files);
      const stagedFileSizesArr = stagedFiles.length > 0 ? stagedFiles?.map((file) => file.size) : [];
      const stagedFileSizesArrSum = stagedFileSizesArr.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      let errorsArr = [];

      if (stagedFiles.length > 0) {
        stagedFiles.forEach((file) => {
          // Parse out file extension in the filename
          const filename = file?.name?.replace(/\.[^/.]+$/, '');

          // File does not have an accepted file extension
          if (accept) {
            const fileType = `.${file?.name?.split('.').pop()}`;
            const acceptedTypes = accept.split(',');
            !acceptedTypes.includes(fileType) && errorsArr.push(`${file?.name}: ${defaultText.errorText}`);
          }

          // File is empty
          if (file?.size === 0) {
            errorsArr.push(`${file?.name}: ${ErrorMessages.FileEmpty}`);
          }

          // Exceeds max individual file size (binary bytes) 100MB
          if (file?.size > maxIndFileSize) {
            errorsArr.push(`${file?.name}: ${ErrorMessages.FileTooLarge}`);
          }

          // File already exists in S3 or has been staged already
          if (combinedRequestFiles?.filter((item) => item.fileName === file.name).length > 0) {
            errorsArr.push(`${file?.name}: ${ErrorMessages.FileExists}`);
          }

          // Exceeds max character length (1024 bytes)
          if (filename.length > 1024) {
            errorsArr.push(`${file?.name}: ${ErrorMessages.FileNameTooLong}`);
          }

          // Filename contains invalid characters per S3 naming convention
          if (!filename.match(filenameRegex)) {
            errorsArr.push(`${file?.name}: ${ErrorMessages.FileNameInvalidCharacters}`);
          }

          // Exceeds total file limit (500MB)
          if ((isNaN(totalFileSize) ? 0 : totalFileSize) + stagedFileSizesArrSum > maxTotalFileSize) {
            errorsArr.push(`${file?.name}: ${ErrorMessages.OverTotalFileLimit}`);
          }

          // Exceeds file count limit (50 files)
          if (selectedFiles.length + stagedFiles.length > maxFileCount) {
            errorsArr.push(`${file?.name}: ${ErrorMessages.OverTotalFileCount}`);
          }
        });
      }

      if (errorsArr.length > 0) {
        setShowError(true);
        setFileErrors(true);
        setErrorText(errorsArr);
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const handleDragOver = () => setIsDragging(true);

    const handleDragLeave = () => setIsDragging(false);

    const handleDrop = (e) => {
      const filesArr = Array.from(e?.target?.files) ?? [];
      setFiles(filesArr);
      setIsDragging(false);
      onDrop(e);
    };

    const handleChange = (e) => {
      preventInvalidFiles(e);
      onChange(e);
    };

    const handleDeleteFile = () => {
      doDeleteFile();
      setFiles([]);
      setShowError(false);
      setFileErrors(false);
      setErrorText([]);
    };

    const { ref: fileInputRef, ...rest } = register(name, { onChange: handleChange });

    useEffect(() => {
      setFiles(value);
    }, [value]);

    return (
      <>
        {label && (
          <Label className={labelStyles}>
            {label}
            {tooltip && (
              <Tooltip
                clickable={tooltipClickable}
                content={tooltip}
                header={label}
                iconStyle={{ marginLeft: '5px' }}
                name={name}
              />
            )}
          </Label>
        )}
        {hint && (
          <div className='usa-hint' id={`${name}_hint`}>
            {hint}
          </div>
        )}
        <div className={fileInputStyles} aria-disabled={readOnly}>
          <div className={targetStyles} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
            {files?.length > 0 && (
              <div className={fileInputPreviewHeadingClasses}>
                {previewHeaderText}{' '}
                {!readOnly && <span className='usa-file-input__choose'>Change file{files.length > 1 && 's'}</span>}
              </div>
            )}
            <div className={instructionStyles} aria-hidden='true'>
              <span className='usa-file-input__drag-text'> {dragText || defaultText.dragText} </span>
              <span className='usa-file-input__choose'> {chooseText || defaultText.chooseText} </span>
            </div>
            {/* Displays File Preview list */}
            {files?.map((file, index) => (
              <FilePreview
                key={`filePreview_${name}_${index}`}
                imageId={`${name}_${index}`}
                file={file}
                readOnly={readOnly}
                showError={showError}
                resetFileErrors={resetFileErrors}
              />
            ))}
            <div className={fileInputBoxClasses} />
            {/* Displays File Errors list */}
            {showError === true && resetFileErrors === false && (
              <>
                {errorText?.length > 0 &&
                  errorText.map((text, index) => (
                    <div key={index} className='usa-file-input__accepted-files-message margin-top-1'>
                      {text}
                    </div>
                  ))}
              </>
            )}
            <UswdsTextInput
              accept={accept}
              className='usa-file-input__input'
              id={id}
              multiple={multiple}
              name={name}
              disabled={readOnly}
              inputRef={fileInputRef}
              required={required}
              type='file'
              validationStatus={inputError && 'error'}
              aria-describedby={hint ? `${name}_hint` : undefined}
              {...customProps}
              {...rest}
            />
          </div>
        </div>
        <div className='row'>
          {showUploadButton && files?.length > 0 && (
            <Button className='margin-top-2 save-prog-button' size='large' onClick={doUploadFile}>
              Upload
            </Button>
          )}
          {showDownloadButton && (
            <Button className='margin-top-2' size='large' onClick={doDownloadFile}>
              Download
            </Button>
          )}
          {showDeleteButton && (
            <Button className='margin-top-2' size='large' secondary onClick={handleDeleteFile}>
              Delete
            </Button>
          )}
        </div>
      </>
    );
  }
);

export default FileInput;
