export const boldError = (e, typeSuffix) => {
  const targetId = `${e?.target?.id}_li${typeSuffix}`;

  const target = document?.getElementById(`error_list_summary${typeSuffix}`)?.children;
  const elements = target && Array?.from(target);

  elements?.forEach((element) => {
    const domElement = document.getElementById(element?.id);
    if (element?.id === targetId) {
      domElement.style.fontWeight = 'bold';
    } else if (domElement?.getAttribute('style')) {
      domElement.style.fontWeight = 'normal';
    }
  });
};

export const cleanAndTruncateLabels = (input, maxLength) => {
  if (typeof input === 'undefined') {
    return '';
  }

  let cleanedString = input
    ?.replace(/\*/g, '')
    ?.replace(/\s*\([^)]{2,}\)\s*/g, '') // Remove anything with more than one character in parentheses
    ?.replace(/([^\w\s()/+'-])+/g, ''); // Remove punctuation but keep single characters in parentheses and slashes

  if (cleanedString?.length > maxLength) {
    const truncatedString = cleanedString.substring(0, maxLength);
    const lastSpaceIndex = truncatedString.lastIndexOf(' ');

    cleanedString = lastSpaceIndex > -1 ? truncatedString.substring(0, lastSpaceIndex) : truncatedString;
    cleanedString += '... ';
  }

  return cleanedString + ': ';
};

export const scrollById = (errorId, modalId, typeSuffix) => {
  if (!errorId) return;

  const inputElement = modalId
    ? document.getElementById(modalId).querySelector(`#${errorId}`)
    : document.getElementById(errorId);

  const radioInput = inputElement.getElementsByTagName('input')[0];

  if (radioInput) {
    radioInput.focus();
  } else {
    inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  inputElement.focus();

  if (!modalId) {
    const errorListItem = `${errorId}_li${typeSuffix}`;
    document.getElementById(errorListItem).style.fontWeight = 'bold';
  }
};
