import { useEffect } from 'react';
import { PublicNoticeQueries, PN_QUERY_KEYS } from '@api/services/public-notices/query';
import { LookupQueries, LOOKUP_QUERY_KEYS } from '@api/services/lookup';
import { usePublicNotices } from '@stores';
import { Button, Checkbox, TextInput } from '@trussworks/react-uswds';
import { useQuery } from '@tanstack/react-query';
import { getStateNameFromCode } from '@src/utils/helpers';
import SideLoader from '@components/loader/SideLoader';

const nameSort = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

const FilterCheckbox = ({ item, onChange, selected, type }) => (
  <Checkbox
    checked={selected.includes(item.code)}
    className='small-checkbox pn-filter-checkbox'
    id={item.code}
    label={
      <>
        <span className='pn-filter-text'>{item.name}</span>
        <span className='pn-filter-count'>{item.count}</span>
      </>
    }
    name={item.code}
    onChange={(e) => onChange(e, type)}
    value={item.code}
  />
);

const PublicNoticesFilter = () => {
  const {
    debouncedSearchText,
    districtCodes,
    resetToDefaults,
    searchText,
    setDistrictCodes,
    setPageNumber,
    setSearchText,
    setStateCodes,
    stateCodes,
  } = usePublicNotices();

  const hasStateFilters = stateCodes?.length > 0;
  const hasDistrictFilters = districtCodes?.length > 0;

  const handleTextChange = (e) => {
    const value = e.target.value;
    setPageNumber(1);
    setSearchText(value);
  };

  const { data: districtsData, isLoading: isLoadingDistricts } = useQuery({
    queryKey: [LOOKUP_QUERY_KEYS.GET_DISTRICTS],
    queryFn: LookupQueries.getDistricts,
  });

  const { data: filterData, isLoading } = useQuery({
    queryKey: [PN_QUERY_KEYS.GET_FILTER_COUNTS, stateCodes, districtCodes, debouncedSearchText],
    enabled: !isLoadingDistricts,
    queryFn: () =>
      PublicNoticeQueries.getFilterCounts({
        districtCodes: districtCodes,
        searchText: debouncedSearchText,
        states: stateCodes,
      }),
  });

  const handleCheckboxChange = (e, type) => {
    const selected = type === 'state' ? stateCodes : districtCodes;
    const setter = type === 'state' ? setStateCodes : setDistrictCodes;
    const value = e.target.value;
    const newValue = selected.includes(value) ? selected.filter((item) => item !== value) : [...selected, value];

    setter(newValue);
    setPageNumber(1);
  };

  const handleClearStates = () => {
    setStateCodes([]);
  };

  const handleClearDistricts = () => {
    setDistrictCodes([]);
  };

  useEffect(() => {
    if (!districtsData || !filterData) return;
  }, [districtsData, filterData]);

  useEffect(() => {
    resetToDefaults();
  }, [resetToDefaults]);

  return (
    <div className='pn-filters-container'>
      <div className='pn-filter-title'>Filter Notices</div>
      {isLoading ? (
        <SideLoader />
      ) : (
        <>
          <div className='filters-content'>
            <div className='pn-filter-section'>
              <TextInput
                className='pn-filter-text'
                placeholder='search text'
                onChange={handleTextChange}
                value={searchText}
              />
            </div>
            <div className='pn-filter-section'>
              <div className='pn-filter-title'>
                States
                {stateCodes?.length > 0 && <span className='pn-filter-section-count'> : {stateCodes.length}</span>}
                {hasStateFilters && (
                  <Button className='pn-states-clear-btn' outline onClick={handleClearStates}>
                    Clear
                  </Button>
                )}
              </div>
              <div className='pn-filter-section-content'>
                {Object.entries(filterData?.stateCounts || {})
                  .map(([code, count]) => ({
                    code,
                    count,
                    name: getStateNameFromCode(code),
                  }))
                  .sort(nameSort)
                  .map((item) => (
                    <FilterCheckbox
                      item={item}
                      key={item.code}
                      onChange={handleCheckboxChange}
                      selected={stateCodes}
                      type='state'
                    />
                  ))}
              </div>
            </div>
            <div className='pn-filter-section'>
              <div className='pn-filter-title'>
                Districts
                {districtCodes?.length > 0 && (
                  <span className='pn-filter-section-count'> : {districtCodes.length}</span>
                )}
                {hasDistrictFilters && (
                  <Button className='pn-districts-clear-btn' outline onClick={handleClearDistricts}>
                    Clear
                  </Button>
                )}
              </div>
              <div className='pn-filter-section-content'>
                {Object.entries(filterData?.districtCodeCounts || {})
                  .map(([code, count]) => ({
                    code,
                    count,
                    name: districtsData[code]?.orgName,
                  }))
                  .sort(nameSort)
                  .map((item) => (
                    <FilterCheckbox
                      item={item}
                      key={item.code}
                      onChange={handleCheckboxChange}
                      selected={districtCodes}
                      type='district'
                    />
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PublicNoticesFilter;
