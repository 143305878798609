import { usePublicNotices } from '@stores';
import { Label, Select, Pagination } from '@trussworks/react-uswds';

const pageBreakdown = [10, 20, 30, 50];

const PublicNoticesTableFooter = ({ table }) => {
  const { itemsPerPage, pageNumber, setItemsPerPage, setPageNumber, pageCount } = usePublicNotices();

  const handlePreviousButton = () => {
    setItemsPerPage(Math.max(0, Number(pageNumber) - 1));
  };

  const handleNextButton = () => {
    setItemsPerPage(Number(pageNumber) + 1);
  };

  const handlePageNumberClick = (e) => {
    setPageNumber(e?.target?.innerText);
  };

  const handlePageSizeSelect = (e) => {
    const pageSize = parseInt(e.target.value);
    setItemsPerPage(pageSize);
    setPageNumber(1);
    table.setPageSize(pageSize);
  };

  return (
    <div className='d-flex justify-content-center usa-prose'>
      <div className='d-flex flex-align-center'>
        <Pagination
          className='margin-right-3 pagination-styles'
          currentPage={Number(pageNumber)}
          maxSlots={7}
          totalPages={pageCount}
          onClickNext={handleNextButton}
          onClickPageNumber={handlePageNumberClick}
          onClickPrevious={handlePreviousButton}
          pathname=''
        />
        <span className='d-flex flex-align-center margin-right-3 rows-per-page' style={{ whiteSpace: 'nowrap' }}>
          <Label htmlFor='rows_per_page' className='margin-right-1'>
            rows per page:
          </Label>
          <div className='footer-select-container'>
            <Select name='rows_per_page' id='rows_per_page' defaultValue={itemsPerPage} onChange={handlePageSizeSelect}>
              {pageBreakdown.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Select>
          </div>
        </span>
        <span className='d-flex flex-align-center'>
          {`${pageNumber} of ${pageCount} page${pageCount !== 1 ? 's' : ''}`}
        </span>
      </div>
    </div>
  );
};

export default PublicNoticesTableFooter;

//Keep this just in case the client wants it
/* <span className='margin-right-3'>
            jump to page:{' '}
        <input style={{width:'50px'}} type='number' min={0} max={pageCount} value={jumpPageNumber} onKeyDown={e => handleJumpTo(e)} onChange={e => handleJumpPageNumberChange(e)} />
        <Button className='margin-left-1' unstyled onClick={handleGoClick} >
            Go
        </Button>
      </span> */

// const handleFirstButton = () => {
//   setItemsPerPage(1);
//   setJumpPageNumber(1);
// };

// const handleLastButton = () => {
//   setItemsPerPage(pageCount);
//   setJumpPageNumber(1);
// };

// const handleJumpPageNumberChange = (e) => {
//   setJumpPageNumber(e.target.value);
// };

// const handleJumpTo = (e) => {
//   const value = e?.target?.value;
//   if (e?.key === 'Enter' && value > 0 && value <= pageCount)
//   {
//     setItemsPerPage(value);
//   }
// };

// const handleGoClick = () => {
//   if (jumpPageNumber > 0 && jumpPageNumber <= pageCount)
//   {
//     setItemsPerPage(jumpPageNumber);
//   }
// };
