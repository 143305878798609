import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PublicNoticeQueries, PN_QUERY_KEYS } from '@api/services/public-notices';
import { LookupQueries, LOOKUP_QUERY_KEYS } from '@api/services/lookup';
import { ModalContent } from '@components/modal';
import PublicNoticeInfo from './PublicNoticeInfo';
import PublicNoticeComment from '../public-notice-comment/PublicNoticeComment';
import { Button } from '@trussworks/react-uswds';
import ModalLoader from '@src/app-components/loader/ModalLoader';

const PublicNoticeInfoModal = ({ publicNoticeId }) => {
  const { data: districtsData, isLoading: isLoadingDistrict } = useQuery({
    queryKey: [LOOKUP_QUERY_KEYS.GET_DISTRICTS],
    queryFn: LookupQueries.getDistricts,
  });

  const { data, isLoading } = useQuery({
    queryKey: [PN_QUERY_KEYS.GET_BY_ID, publicNoticeId],
    queryFn: () => PublicNoticeQueries.getById(publicNoticeId),
  });

  const districtData = districtsData[data?.districtCode];

  const [view, setView] = useState('info');
  const modalTitle =
    view === 'info' ? (
      <>
        Public Notice: <span>{data?.daNumber}</span>
      </>
    ) : (
      <>Submit Public Notice Comments</>
    );
  const hasCloseButton = view === 'info';

  const handleCommentsClick = () => setView('comments');
  const handleCommentsClose = () => setView('info');

  if (isLoading || isLoadingDistrict) {
    return <ModalLoader title='Public Notice' />;
  }

  return (
    <ModalContent title={modalTitle} hasCloseButton={hasCloseButton}>
      <div className='modal-body'>
        {view === 'info' && (
          <>
            <div className='pn-comment-button-container'>
              <Button className='button-small' onClick={handleCommentsClick}>
                Submit Comments
              </Button>
            </div>
            <PublicNoticeInfo data={data} districtData={districtData} />
          </>
        )}
        {view === 'comments' && <PublicNoticeComment data={data} onClose={handleCommentsClose} />}
      </div>
    </ModalContent>
  );
};

export default PublicNoticeInfoModal;
