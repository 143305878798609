const stepperBundle = {
  name: 'stepper',

  //Initial state of form stepper
  //TODO setup API call to databsae to get list of form sections

  getReducer: () => {
    const initialState = [
      {
        id: 0,
        status: 'active',
        touched: 'false',
        last: 'false',
        label: '',
      },
    ];

    // @TODO FIX LOGIC FOR STEPPER CLICKS TO PROPERLY SHOW STATE OF APPLICATION

    return (state = initialState, { type, payload }) => {

      if (type === 'INITIALIZE_STEPPER') {
        if (payload.length) {
          return payload.map((step, i) => (i === 0 ? { ...step, status: 'active' } : { ...step }));
        }
        return state;
      }

      if (type === 'RESET_STEPPER') {
        return initialState;
      }

      if (type === 'STEP_TOUCHED') {

        return state.map((step) => {
          if (step.id === payload) {
            return { ...step, touched: 'true' };
          }
          else { return { ...step, touched: step.touched }; }
        });
      }

      if (type === 'STEP_ACTIVE') {
        return state.map((step) => {
          if (step.id === payload && (step.status === 'complete' || step.status === 'activecomplete')) {
            return { ...step, status: 'activecomplete' };
          }
          if (step.id === payload && (step.status === 'error' || step.status === 'activeerror')) {
            return { ...step, status: 'activeerror' };
          }
          if (step.id === payload) {
            return { ...step, status: 'activeerror' };
          }
          else if (step.status === 'complete') {
            return { ...step, status: 'complete' };
          }
          else if (step.status === 'activecomplete') {
            return { ...step, status: 'complete' };
          }
          else if (step.status === 'error') {
            return { ...step, status: 'error' };
          }
          else if (step.status === 'activeerror') {
            return { ...step, status: 'error' };
          }
          else if (step.status === '') {
            return { ...step, status: '' };
          }
          else if (step.id !== payload && step.status === 'active') {
            return { ...step, status: '' };
          }
          else { return { ...step, status: step.status }; }
        });
      }

      if (type === 'STEP_COMPLETE') {
        return state.map((step) => {
          if (step.id === payload) {
            return { ...step, status: 'complete' };
          }

          return { ...step };
        });
      }
      if (type === 'STEP_ACTIVE_COMPLETE') {
        return state.map((step) => {
          if (step.id === payload) {
            return { ...step, status: 'activecomplete' };
          }

          return { ...step };
        });
      }

      if (type === 'STEP_ERROR') {
        return state.map((step) => {
          if (step.id === payload) {
            return { ...step, status: 'error' };
          }

          return { ...step };
        });
      }

      if (type === 'STEP_ACTIVE_ERROR') {
        return state.map((step) => {
          if (step.id === payload) {
            return { ...step, status: 'activeerror' };
          }

          return { ...step };
        });
      }

      return state;
    };
  },

  //TODO setup click event

  doSetActiveStep:
    (id) =>
      ({ dispatch }) => {
        dispatch({ type: 'STEP_ACTIVE', payload: id });
      },

  doSetCompleteStep:
    (id) =>
      ({ dispatch }) => {
        dispatch({ type: 'STEP_COMPLETE', payload: id });
      },
  doSetActiveCompleteStep:
    (id) =>
      ({ dispatch }) => {
        dispatch({ type: 'STEP_ACTIVE_COMPLETE', payload: id });
      },
  doSetActiveErrorStep:
    (id) =>
      ({ dispatch }) => {
        dispatch({ type: 'STEP_ACTIVE_ERROR', payload: id });
      },
  doSetErrorStep:
    (id) =>
      ({ dispatch }) => {
        dispatch({ type: 'STEP_ERROR', payload: id });
      },

  doSetTouchedStep:
    (id) =>
      ({ dispatch }) => {
        dispatch({ type: 'STEP_TOUCHED', payload: id });
      },

  doInitializeStepper:
    (initialSteps) =>
      ({ dispatch }) => {
        dispatch({ type: 'INITIALIZE_STEPPER', payload: initialSteps });
      },

  doResetStepper:
    () =>
      ({ dispatch }) => {
        dispatch({ type: 'RESET_STEPPER' });
      },

  selectSteps: (state) => state.stepper,
  selectStepCount: (state) => state.stepper.length,
  selectActiveStep: (state) => {
    const activeSteps = state.stepper.filter((step) => step.status === 'active' || step.status === 'activecomplete' || step.status === 'activeerror');
    return activeSteps.length > 0 ? activeSteps[0] : null;
  }

  // init: store => {store.doInitalizeStepper()}
};
export default stepperBundle;
