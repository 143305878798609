import React from 'react';
import { Tag } from '@trussworks/react-uswds';

import './filtertags.scss';

type FilterTagsProps = {
  filters: { [key: string]: string[] | string }; // Object with keys as filter categories and values as arrays of strings
  onRemoveFilter: (filterType: string, filterValue: string) => void; // Callback to handle filter removal
  onClearFilterType: (filterType: string) => void; // Callback to handle filter removal
};

const FilterTags: React.FC<FilterTagsProps> = ({ filters, onRemoveFilter, onClearFilterType }) => (
  <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
    {Object.entries(filters)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
      .map(([filterType, filterValues]) => {
        const hasFilters =
          (Array.isArray(filterValues) && filterValues.length > 0) ||
          (typeof filterValues === 'string' && filterValues.trim().length > 0);

        if (!hasFilters) return null;

        return (
          <div key={filterType} className='filter-container'>
            <span className='filter-label'>{filterType.charAt(0).toUpperCase() + filterType.slice(1)}</span>
            <div className='filter-tags'>
              {Array.isArray(filterValues) &&
                filterValues.map((value, index) => (
                  <Tag key={`${filterType}-${index}`} className='filter-tag'>
                    <span>{value.charAt(0).toUpperCase() + value.slice(1)}</span>
                    <button
                      type='button'
                      className='remove-tag-button'
                      aria-label={`Remove ${value} from ${filterType}`}
                      onClick={() => onRemoveFilter(filterType, value)}
                    >
                      ✕
                    </button>
                  </Tag>
                ))}
              {filterValues && typeof filterValues === 'string' && (
                <Tag key={`${filterType}-0`} className='filter-tag'>
                  <span>{filterValues}</span>
                  <button
                    type='button'
                    className='remove-tag-button'
                    aria-label={`Remove ${filterValues} from ${filterType}`}
                    onClick={() => onRemoveFilter(filterType, filterValues)}
                  >
                    ✕
                  </button>
                </Tag>
              )}
            </div>
            {Array.isArray(filterValues) && filterValues.length > 1 && (
              <button
                type='button'
                className='clear-all-button'
                onClick={() => onClearFilterType(filterType)}
                aria-label={`Clear all ${filterType}`}
              >
                Clear All
              </button>
            )}
          </div>
        );
      })}
  </div>
);

export default FilterTags;
