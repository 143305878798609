import { useCallback, useEffect, useMemo, useState } from 'react';
import useErrorFocus from '@hooks/useErrorFocus';
import { connect } from 'redux-bundler-react';
import { createColumnHelper } from '@tanstack/react-table';
import { Accordion, Alert, Label } from '@trussworks/react-uswds';

import { EditCell } from '@components/table/tableCellComponents/EditCell';
import { TableCell } from '@components/table/tableCellComponents/TableCell';
import { PermitDateTableCell } from '@components/table/tableCellComponents/PermitDateTableCell';

import TextInput from '@components/text-input/TextInput';
import FieldHeader from '@forms/components/Form/FieldHeader';
import statusModal from '@forms/components/modals/statusModal';
import TextAreaInput from '@components/textarea/TextArea';
import SelectInput from '@components/select/Select';
import CreatableSelectInput from '@components/new-inputs/creatableSelectInput';
import TanStackTableNew from '@components/table/TanStackTable/TanStackTableNew';
import ExternalLink from '@components/external-link/ExternalLink';
import Tooltip from '@components/tooltip/tooltip';
import MultiSelectInput from '@components/multi-select/multiSelectInput';
import ExplanationTableCell from '@components/table/tableCellComponents/ExplanationTableCell';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import Checkbox from '@components/checkbox/Checkbox';
import PermitsDredgeProject from './PermitsFormGeneralProjectInformation.dredgeProject';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { ormErrorMsg } from '../../_helper';
import { decimalNumberRegex, integerRegex, latRegex, lngRegex, setNumberValue } from '@src/utils/regex';
import { natureOfActivityTooltipContent } from '@pages/Resources/components/_shared/helper';

import { ApplicableStatutoryAuthorities } from '@src/utils/enums';
import { dateBeforeB, filterNullEmptyObjects, formatCoordFlt, isDateValid, isValidORMNumber } from '@src/utils/helpers';

import '@styles/index.scss';

export const PermitsFormGeneralProjectInformationMetadata = {
  sectionName: 'General Project Information',
};

const currentEnv = import.meta.env.VITE_ENVIRONMENT;

const nwpTermsList = [
  {
    term: 'NWP 3, Maintenance',
    desc: 'information regarding the original design capacities and configurations of the outfalls, intakes, small impoundments, and canals',
  },
  {
    term: 'NWP 31, Maintenance of Existing Flood Control Facilities',
    desc: 'a description of the maintenance baseline and the dredged material disposal site',
  },
  {
    term: 'NWP 33, Temporary Construction, Access, and Dewatering',
    desc: 'a restoration plan showing how all temporary fills and structures will be removed and the area restored to pre-project conditions',
  },
  {
    term: 'NWP 44, Mining Activities',
    desc: 'if reclamation is required by other statutes, then a copy of the final reclamation plan must be submitted with the preconstruction notification',
  },
  {
    term: 'NWP 45, Repair of Uplands Damaged by Discrete Events',
    desc: 'documentation, such as a recent topographic survey or photographs, to justify the extent of the proposed restoration',
  },
  {
    term: 'NWP 48, Commercial Shellfish Aquaculture Activities',
    desc: '(1) a map showing the boundaries of the project area, with latitude and longitude coordinates for each corner of the project area; (2) the name(s) of the species that will be cultivated during the period this NWP is in effect; (3) whether canopy predator nets will be used; (4) whether suspended cultivation techniques will be used; and (5) general water depths in the project area (a detailed survey is not required)',
  },
  {
    term: 'NWP 49, Coal Remining Activities',
    desc: 'a document describing how the overall mining plan will result in a net increase in aquatic resource functions must be submitted to the district engineer and receive written authorization prior to commencing the activity',
  },
  {
    term: 'NWP 50, Underground Coal Mining Activities',
    desc: 'if reclamation is required by other statutes, then a copy of the reclamation plan must be submitted with the preconstruction notification',
  },
];

const nwpTermsAccordionList = [
  {
    title:
      'The terms of some of the Nationwide Permits include additional information requirements for preconstruction notifications',
    content: (
      <>
        {nwpTermsList.map((nwp, index) => (
          <p key={index} className={`margin-bottom-0 ${index !== 0 && 'margin-top-105'}`}>
            <span className='text-bold'>{nwp.term}</span> - {nwp.desc}.
          </p>
        ))}
      </>
    ),
    id: '1',
    headingLevel: 'h3',
  },
];

const PermitsFormGeneralProjectInformation = connect(
  'doUpdateSectionValidity',
  'doUpdatePermitRequest',
  'doUpdatePermitRequestSub',
  'doClearPileDataByUse',
  'doUpdateRequestData',
  'doSecondaryModalOpen',
  'selectRequestFormData',
  'selectGPPermitTypes',
  'selectPermitData',
  'selectSteps',
  'selectActiveStep',
  'selectRequestAPIData',
  'selectIsReadOnly',
  ({
    doUpdateSectionValidity,
    doUpdatePermitRequest,
    doUpdatePermitRequestSub,
    doClearPileDataByUse,
    doUpdateRequestData,
    doSecondaryModalOpen,
    requestFormData,
    gPPermitTypes,
    permitData,
    steps,
    activeStep,
    requestAPIData,
    isReadOnly,
    stepNo,
  }) => {
    const [otherNWPData, setOtherNWPData] = useState([]);
    const [otherNWPKey, setOtherNWPKey] = useState(0);
    const [tableInvalid, setTableInvalid] = useState(true);
    const [rowErrors, setRowErrors] = useState(false);
    const columnHelper = createColumnHelper();
    const [dredgeProjectData, setDredgeProjectData] = useState({});
    const thisSectionStepStatus = useMemo(() => steps.find((step) => step.id === stepNo)?.touched, [steps, stepNo]);

    const convertFileNumbersToSelectFormat = (data) =>
      data && data.map((obj) => ({ label: obj.ormFileNumber, value: obj.ormFileNumber }));

    const populateDredgeTypes = () => {
      const dredgeTypes = [];
      if (permitData?.dredgeProject?.isDredgeTypeMaintenance) {
        dredgeTypes.push('Maintenance');
      }
      if (permitData?.dredgeProject?.isDredgeTypeNew) {
        dredgeTypes.push('New');
      }
      return dredgeTypes;
    };

    const otherNWPValidationSchema = yup.object().shape({
      approvalType: yup.string().required('Please select an option'),
      explanation: yup
        .string()
        .when('approvalType', { is: 'Other', then: () => yup.string().required('Field is required') })
        .nullable(),
      fileNumber: yup.string().required('Field is required'),
      appliedDate: yup.string().required('Please select a date'),
      approvedDate: yup.string().nullable(),
      deniedDate: yup.string().nullable(),
    });

    const otherNWPTableLength = otherNWPData?.length;

    const schema = (otherNWPTableLength, rowErrors) =>
      yup.object().shape(
        {
          projectName: yup.string().required('Field is required'),
          previousFileNumbersExist: yup.string().required('Please select an option'),
          previousFileNumbers: yup.array().when('previousFileNumbersExist', {
            is: 'true',
            then: () =>
              yup
                .array()
                .min(1, 'At least one Previous File Number is required')
                .required('At least one Previous File Number is required'),
          }),
          gpPermitTypes: yup.array(),
          otherNWP: yup
            .boolean()
            .test(
              'check-table-length-and-errors',
              'Other Nationwide Permits: Table contains invalid rows',
              function () {
                return !(rowErrors && otherNWPTableLength > 0);
              }
            ),
          applicableStatutoryAuthority: yup.array().min(1, 'Please select at least one value'),
          projectDescription: yup
            .string()
            .test(
              'isDefault',
              'DEFAULT_VALUE is a reserved word, please enter a valid string',
              (val) => val.toUpperCase() !== 'DEFAULT_VALUE'
            )
            .required('Project Description is required'),
          natureOfActivity: yup
            .string()
            .test(
              'isDefault',
              'DEFAULT_VALUE is a reserved word, please enter a valid string',
              (val) => val.toUpperCase() !== 'DEFAULT_VALUE'
            )
            .required('Nature of Activity is required'),
          projectPurpose: yup
            .string()
            .test(
              'isDefault',
              'DEFAULT_VALUE is a reserved word, please enter a valid string',
              (val) => val.toUpperCase() !== 'DEFAULT_VALUE'
            )
            .required('Project Purpose is required'),
          navigableWatersAdditionalInfo: yup.string().nullable(),
          isAnyWorkComplete: yup.string().required('Please select an option'),
          completedWorkDescription: yup
            .string()
            .when('isAnyWorkComplete', { is: 'true', then: () => yup.string().required('Field is required') })
            .nullable(),
          //DREDGING
          isDredgingNavWaters: yup
            .string()
            .when('applicableStatutoryAuthority', {
              is: (val) => val?.includes(ApplicableStatutoryAuthorities.Section10),
              then: () => yup.string().required('Please select an option'),
            })
            .nullable(),
          materialTypeComposition: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Field is required') })
            .nullable(),
          dredgeMethod: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Field is required') })
            .nullable(),
          constructionMethods: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Field is required') })
            .nullable(),
          estimatedNumberOfEvents: yup
            .string()
            .when(['estimatedNumberOfEvents', 'isDredgingNavWaters'], (estimatedNumberOfEvents, isDredgingNavWaters) =>
              estimatedNumberOfEvents && isDredgingNavWaters === 'true'
                ? yup.string().matches(integerRegex, { message: 'Must be an integer.', excludeEmptyString: true })
                : yup.string().nullable()
            ),
          workStartDate: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Please select a date') })
            .nullable(),
          workEndDate: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Please select a date') })
            .nullable()
            .test({
              test: (value, { parent: { isDredgingNavWaters } }) =>
                isDredgingNavWaters === 'true' ? value !== '' : true,
              message: 'Please select a date',
            })
            .test({
              test: (value, { parent: { workStartDate } }) => !value || new Date(value) >= new Date(workStartDate),
              message: 'The end date must be after the start date',
            }),
          durationOfWork: yup
            .string()
            .when('isDredgingNavWaters', {
              is: 'true',
              then: () =>
                yup
                  .number()
                  .required('Field is required')
                  .typeError('Must be an integer.')
                  .positive('Value must be > 0'),
            })
            .nullable(),
          unitOfWork: yup
            .string()
            .when('isDredgingNavWaters', { is: 'true', then: () => yup.string().required('Please select an option') })
            .nullable(),
          currentDepth: yup
            .string()
            .when('isDredgingNavWaters', {
              is: 'true',
              then: () => yup.string().required('Field is required').matches(decimalNumberRegex, 'Field is invalid'),
            })
            .nullable(),
          dredgeType: yup
            .array()
            .when('isDredgingNavWaters', {
              is: 'true',
              then: () => yup.array().min(1, 'Please select at least one value'),
            })
            .nullable(),
          lastDredgeCycle: yup
            .string()
            .when('dredgeType', {
              is: (val) => val?.includes('Maintenance'),
              then: () => yup.string().required('Please select a date'),
            })
            .nullable(),
          authorizedLength: yup
            .string()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () => yup.string().required('Field is required').matches(decimalNumberRegex, 'Field is invalid'),
            })
            .nullable(),
          authorizedWidth: yup
            .string()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () => yup.string().required('Field is required').matches(decimalNumberRegex, 'Field is invalid'),
            })
            .nullable(),
          authorizedDepth: yup
            .string()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () => yup.string().required('Field is required').matches(decimalNumberRegex, 'Field is invalid'),
            })
            .nullable(),
          disposalPlan: yup
            .string()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () => yup.string().required('Field is required'),
            })
            .nullable(),
          estNumDisposalTrips: yup
            .string()
            .when(['estNumDisposalTrips', 'dredgeType'], (estNumDisposalTrips, dredgeType) =>
              estNumDisposalTrips && dredgeType?.length > 0
                ? yup.string().matches(integerRegex, { message: 'Must be an integer.', excludeEmptyString: true })
                : yup.string().nullable()
            ),
          dredgeDisposalLatitude: yup
            .mixed()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () =>
                yup
                  .string()
                  .required('Field is required')
                  .matches(latRegex, 'Format is incorrect. Must be +-XX.XXXXXX and include at least 6 decimal places.'),
            })
            .nullable(),
          dredgeDisposalLongitude: yup
            .mixed()
            .when('dredgeType', {
              is: (val) => val?.length > 0,
              then: () =>
                yup
                  .string()
                  .required('Field is required')
                  .matches(
                    lngRegex,
                    'Format is incorrect. Must be +-XXX.XXXXXX and include at least 6 decimal places.'
                  ),
            })
            .nullable(),
        },
        [
          ['estimatedNumberOfEvents', 'estimatedNumberOfEvents'],
          ['estNumDisposalTrips', 'estNumDisposalTrips'],
          ['existingVesselSlips', 'existingVesselSlips'],
        ]
      );

    const defaultValues = {
      projectName: requestFormData?.projectName,
      previousFileNumbersExist: requestFormData?.previousFileNumbers?.length > 0 ? 'true' : 'false',
      previousFileNumbers:
        requestFormData?.previousFileNumbers?.length > 0
          ? convertFileNumbersToSelectFormat(requestFormData.previousFileNumbers)
          : [],
      projectDescription: requestFormData?.projectDescription ?? '',
      projectPurpose: requestFormData?.projectPurpose ?? '',
      natureOfActivity: requestFormData?.natureOfActivity ?? '',
      applicableStatutoryAuthority: permitData?.applicableStatutoryAuthorities ?? [],
      gpPermitTypes: permitData?.gpPermitTypes?.map((item) => ({ value: item, label: item })) ?? [],
      supportingPermits: permitData?.supportingPermits
        ? permitData?.supportingPermits?.map((item) => ({
            ...item,
            appliedDate: item?.appliedDate?.split('T')?.[0],
            approvedDate: item?.approvedDate?.split('T')?.[0],
            deniedDate: item?.deniedDate?.split('T')?.[0],
          }))
        : [],
      activityDescription: permitData?.activityDescription ?? '',
      activityPurposeAndNeed: permitData?.activityPurposeAndNeed ?? '',
      navigableWatersAdditionalInfo: permitData?.navigableWatersAdditionalInfo ?? '',
      isAnyWorkComplete:
        permitData?.isAnyWorkComplete !== undefined && permitData?.isAnyWorkComplete !== null
          ? permitData.isAnyWorkComplete
            ? 'true'
            : 'false'
          : '',
      completedWorkDescription: permitData?.completedWorkDescription ?? '',
      // Dredge Project
      isDredgingNavWaters:
        permitData?.isDredgingNavWaters !== undefined && permitData?.isDredgingNavWaters !== null
          ? permitData.isDredgingNavWaters
            ? 'true'
            : 'false'
          : '',
      materialTypeComposition: permitData?.dredgeProject?.materialTypeComposition ?? '',
      dredgeMethod: permitData?.dredgeProject?.dredgeMethod ?? '',
      constructionMethods: permitData?.dredgeProject?.constructionMethods ?? '',
      estimatedNumberOfEvents: permitData?.dredgeProject?.estimatedNumberOfEvents ?? '',
      workStartDate: permitData?.dredgeProject?.workStartDate?.split('T')?.[0] ?? '',
      workEndDate: permitData?.dredgeProject?.workEndDate?.split('T')?.[0] ?? '',
      durationOfWork: permitData?.dredgeProject?.durationOfWork ?? null,
      unitOfWork: permitData?.dredgeProject?.unitOfWork ?? '',
      currentDepth: permitData?.dredgeProject?.currentDepth ?? '',
      dredgeType: populateDredgeTypes(),
      lastDredgeCycle: permitData?.dredgeProject?.lastDredgeCycle?.split('T')?.[0] ?? '',
      authorizedLength: permitData?.dredgeProject?.authorizedLength ?? '',
      authorizedWidth: permitData?.dredgeProject?.authorizedWidth ?? '',
      authorizedDepth: permitData?.dredgeProject?.authorizedDepth ?? '',
      disposalPlan: permitData?.dredgeProject?.disposalPlan ?? '',
      estNumDisposalTrips: permitData?.dredgeProject?.estNumDisposalTrips ?? '',
      dredgeDisposalLatitude:
        typeof permitData?.dredgeProject?.dredgeDisposalLatitude === 'number'
          ? permitData?.dredgeProject?.dredgeDisposalLatitude?.toFixed(7)
          : (permitData?.dredgeProject?.dredgeDisposalLatitude ?? ''),
      dredgeDisposalLongitude:
        typeof permitData?.dredgeProject?.dredgeDisposalLongitude === 'number'
          ? permitData?.dredgeProject?.dredgeDisposalLongitude?.toFixed(7)
          : (permitData?.dredgeProject?.dredgeDisposalLongitude ?? ''),

      // Pile Driving and Coastal Shoreline Stabilization Manual Overwrite
      includesInstallPiling:
        permitData?.includesInstallPiling !== undefined && permitData?.includesInstallPiling !== ''
          ? permitData.includesInstallPiling
            ? 'true'
            : 'false'
          : 'false',
      includesShorelineStabilization:
        permitData?.includesShorelineStabilization !== undefined && permitData?.includesShorelineStabilization !== ''
          ? permitData.includesShorelineStabilization
            ? 'true'
            : 'false'
          : 'false',
    };

    const methods = useForm({
      resolver: yupResolver(schema(otherNWPTableLength, rowErrors)),
      mode: 'onBlur',
      defaultValues: defaultValues,
    });
    const {
      formState: { isValid, errors },
      setValue,
      setError,
      clearErrors,
      watch,
      getValues,
      trigger,
    } = methods;
    const previousFileNumbersExist = watch('previousFileNumbersExist', 'false');
    const previousFileNumbers = watch('previousFileNumbers', []);

    const isAnyWorkComplete = watch('isAnyWorkComplete');
    const applicableStatutoryAuthority = watch('applicableStatutoryAuthority');
    const isDredgingNavWaters = watch('isDredgingNavWaters');
    const dredgeType = watch('dredgeType');
    const gpPermitTypes = watch('gpPermitTypes');

    const NWPColumns = useMemo(
      () => [
        columnHelper.display({
          id: 'edit',
          cell: isReadOnly ? <></> : EditCell,
          size: 40,
          enableResizing: false,
          enableSorting: false,
        }),
        columnHelper.accessor('approvalType', {
          header: 'Type of Approval',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          size: 275,
          meta: {
            type: 'select',
            required: true,
            options: [
              { value: 'Nationwide Permit', label: 'Nationwide Permit' },
              { value: 'Regional General Permit', label: 'Regional General Permit' },
              { value: 'Programmatic General Permit', label: 'Programmatic General Permit' },
              { value: 'Individual Permit', label: 'Individual Permit' },
              { value: 'Other', label: 'Other' },
            ],
          },
        }),
        columnHelper.accessor('explanation', {
          header: 'Explanation',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : ExplanationTableCell,
          size: 300,
          meta: {
            required: true,
            type: 'text',
          },
        }),
        columnHelper.accessor('fileNumber', {
          header: 'File Number',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
          size: 300,
          meta: {
            required: true,
            type: 'text',
          },
        }),
        columnHelper.accessor('appliedDate', {
          header: 'Date Applied',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : PermitDateTableCell,
          size: 150,
          meta: {
            type: 'date',
            min: dateBeforeB,
            required: true,
          },
        }),
        columnHelper.accessor('approvedDate', {
          header: 'Date Approved',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : PermitDateTableCell,
          size: 150,
          meta: {
            type: 'date',
            min: dateBeforeB,
            required: false,
          },
        }),
        columnHelper.accessor('deniedDate', {
          header: 'Date Denied',
          cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : PermitDateTableCell,
          size: 150,
          meta: {
            type: 'date',
            min: dateBeforeB,
            required: false,
          },
        }),
      ],
      [columnHelper, isReadOnly]
    );

    const updateOtherNWPData = useCallback(
      (rowIndex, columnId, updatedValue) => {
        setOtherNWPData((oldData) => {
          const newData = oldData ? [...oldData] : null;
          if (newData && newData[rowIndex]) {
            // Update properties
            newData[rowIndex] = {
              ...newData[rowIndex],
              [columnId]: updatedValue,
            };
            return newData;
          }
        });
      },
      [setOtherNWPData]
    );

    const removeMultipleRows = useCallback(
      (indicesToRemove) => {
        setOtherNWPData((oldData) => {
          const newRows = oldData.filter((_, index) => !indicesToRemove.includes(index));
          return newRows;
        });
        setOtherNWPKey((old) => old + 1);
      },
      [setOtherNWPData, setOtherNWPKey]
    );

    // Update Supporting Permits data in Permit Request Body
    useEffect(() => {
      otherNWPData &&
        doUpdatePermitRequest({
          supportingPermits: otherNWPData.map((item) => ({
            ...item,
            appliedDate:
              isDateValid(item?.appliedDate?.split('T')?.[0]) === true ? item?.appliedDate?.split('T')?.[0] : '',
            approvedDate:
              isDateValid(item?.approvedDate?.split('T')?.[0]) === true ? item?.approvedDate?.split('T')?.[0] : '',
            deniedDate:
              isDateValid(item?.deniedDate?.split('T')?.[0]) === true ? item?.deniedDate?.split('T')?.[0] : '',
          })),
        });
    }, [otherNWPData, doUpdatePermitRequest]);

    // Update Dredge Project data in Permit Request Body
    useEffect(() => {
      dredgeProjectData && doUpdatePermitRequest({ dredgeProject: dredgeProjectData });
    }, [dredgeProjectData, doUpdatePermitRequest]);

    // Load data from API
    useEffect(() => {
      if (requestAPIData?.request?.permits?.[0]?.supportingPermits) {
        setOtherNWPData(
          requestAPIData?.request?.permits?.[0]?.supportingPermits.map((item) => ({
            ...item,
            appliedDate:
              isDateValid(item?.appliedDate?.split('T')?.[0]) === true ? item?.appliedDate?.split('T')?.[0] : '',
            approvedDate:
              isDateValid(item?.approvedDate?.split('T')?.[0]) === true ? item?.approvedDate?.split('T')?.[0] : '',
            deniedDate:
              isDateValid(item?.deniedDate?.split('T')?.[0]) === true ? item?.deniedDate?.split('T')?.[0] : '',
          }))
        );
      }

      if (requestAPIData?.request?.permits?.[0]?.dredgeProject) {
        setDredgeProjectData(requestAPIData?.request?.permits?.[0]?.dredgeProject);
      }
    }, [requestAPIData]);

    const handleChange = (e) => {
      if (e?.target?.name === 'previousFileNumbersExist' && e?.target?.value === 'false') {
        setValue('previousFileNumbers', [], { shouldValidate: true });
        doUpdateRequestData({ previousFileNumbers: [] });
        return;
      }

      // Map & insert values into GP data modal
      if (!isReadOnly) {
        const values = getValues();
        const permitTypes = values?.gpPermitTypes?.map((val) => val.value);
        const gpValues = {
          activityDescription: values?.activityDescription ?? '',
          activityPurposeAndNeed: values?.activityPurposeAndNeed ?? '',
          navigableWatersAdditionalInfo: values?.navigableWatersAdditionalInfo ?? '',
          isAnyWorkComplete:
            values?.isAnyWorkComplete === 'true' ? true : values?.isAnyWorkComplete === 'false' ? false : null,
          completedWorkDescription: values?.completedWorkDescription ?? '',
          isDredgingNavWaters:
            values?.isDredgingNavWaters === 'true' ? true : values?.isDredgingNavWaters === 'false' ? false : null,
          includesInstallPiling: false,
          includesShorelineStabilization: false,
          applicableStatutoryAuthorities: values?.applicableStatutoryAuthority ?? [],
          gpPermitTypes: permitTypes,
          dredgeProject: populateDredgeProject() ?? null,
        };
        const prevFileNumbers =
          values?.previousFileNumbersExist === 'false'
            ? []
            : values?.previousFileNumbers?.length > 0
              ? values?.previousFileNumbers?.map((val) => ({ ormFileNumber: val?.value?.toUpperCase() }))
              : [];
        doUpdateRequestData({
          projectName: values?.projectName,
          previousFileNumbers: prevFileNumbers,
          projectDescription: values?.projectDescription,
          projectPurpose: values?.projectPurpose,
          natureOfActivity: values?.natureOfActivity,
        });
        doUpdatePermitRequest(gpValues);
      }
    };

    // Populate General Project Info nested sections

    const populateDredgeProject = () => {
      const values = getValues();
      // Dredge Project
      const dredgeProjectObj = {
        materialTypeComposition: values?.materialTypeComposition ?? '',
        dredgeMethod: values?.dredgeMethod ?? '',
        constructionMethods: values?.constructionMethods ?? '',
        estimatedNumberOfEvents: setNumberValue(values?.estimatedNumberOfEvents),
        workStartDate: values?.workStartDate ?? '',
        workEndDate: values?.workEndDate ?? '',
        durationOfWork: setNumberValue(values?.durationOfWork),
        unitOfWork: values?.unitOfWork ?? '',
        currentDepth: setNumberValue(values?.currentDepth, false),
        isDredgeTypeMaintenance:
          values?.dredgeType.length > 0 ? (values?.dredgeType?.includes('Maintenance') ? true : false) : null,
        isDredgeTypeNew: values?.dredgeType.length > 0 ? (values?.dredgeType?.includes('New') ? true : false) : null,
        lastDredgeCycle: values?.lastDredgeCycle ?? '',
        authorizedLength: setNumberValue(values?.authorizedLength, false),
        authorizedWidth: setNumberValue(values?.authorizedWidth, false),
        authorizedDepth: setNumberValue(values?.authorizedDepth, false),
        disposalPlan: values?.disposalPlan ?? '',
        estNumDisposalTrips: setNumberValue(values?.estNumDisposalTrips),
        dredgeDisposalLatitude: formatCoordFlt(values?.dredgeDisposalLatitude) ?? null,
        dredgeDisposalLongitude: formatCoordFlt(values?.dredgeDisposalLongitude) ?? null,
      };
      // Check if values in obj are all null/empty string
      return filterNullEmptyObjects(dredgeProjectObj) !== null ? { ...dredgeProjectData, ...dredgeProjectObj } : null;
    };

    const populateGPPermitTypeOptions = () => {
      if (gpPermitTypes.find((item) => item.value === "I'm not sure")) {
        return [{ value: "I'm not sure", label: "I'm not sure" }];
      } else if (gpPermitTypes.length > 0 && gpPermitTypes.find((item) => item.value !== "I'm not sure")) {
        return gPPermitTypes?.map((item) => ({ value: item, label: item }));
      } else {
        return [
          { value: "I'm not sure", label: "I'm not sure" },
          ...gPPermitTypes?.map((item) => ({ value: item, label: item })),
        ];
      }
    };

    const handleSelectChange = (e) => {
      const results = e.map((num) => isValidORMNumber(num.value));

      if (!results.every(Boolean)) {
        setValue('previousFileNumbers', e.length > 1 ? previousFileNumbers : []);
        doSecondaryModalOpen(statusModal, { status: 'Failed', msg: ormErrorMsg });
      }
    };

    /* clear out sections when dropdowns are false */
    useEffect(() => {
      if (isAnyWorkComplete === 'false' || isAnyWorkComplete === '') {
        setValue('completedWorkDescription', '', { shouldValidate: true });
        doUpdatePermitRequest({ completedWorkDescription: '' });
      }
    }, [isAnyWorkComplete, setValue, doUpdatePermitRequest]);

    useEffect(() => {
      if (!applicableStatutoryAuthority.includes(ApplicableStatutoryAuthorities.Section10)) {
        setValue('isDredgingNavWaters', '', { shouldValidate: true });
        doUpdatePermitRequest({ isDredgingNavWaters: null });
      }
    }, [applicableStatutoryAuthority, doUpdatePermitRequest, setValue]);

    useEffect(() => {
      if (applicableStatutoryAuthority.length === 0) {
        setValue('isDredgingNavWaters', '', { shouldValidate: true });
        doUpdatePermitRequest({ isDredgingNavWaters: null });
      }
    }, [applicableStatutoryAuthority, doUpdatePermitRequest, setValue]);

    // Dredging
    useEffect(() => {
      if (dredgeType.length === 0) {
        setValue('lastDredgeCycle', '', { shouldValidate: true });
        setValue('authorizedLength', '', { shouldValidate: true });
        setValue('authorizedWidth', '', { shouldValidate: true });
        setValue('authorizedDepth', '', { shouldValidate: true });
        setValue('disposalPlan', '', { shouldValidate: true });
        setValue('estNumDisposalTrips', '', { shouldValidate: true });
        setValue('dredgeDisposalLatitude', '', { shouldValidate: true });
        setValue('dredgeDisposalLongitude', '', { shouldValidate: true });
        doUpdatePermitRequestSub('dredgeProject', { lastDredgeCycle: null });
        doUpdatePermitRequestSub('dredgeProject', { authorizedLength: null });
        doUpdatePermitRequestSub('dredgeProject', { authorizedWidth: null });
        doUpdatePermitRequestSub('dredgeProject', { authorizedDepth: null });
        doUpdatePermitRequestSub('dredgeProject', { disposalPlan: '' });
        doUpdatePermitRequestSub('dredgeProject', { estNumDisposalTrips: null });
        doUpdatePermitRequestSub('dredgeProject', { dredgeDisposalLatitude: null });
        doUpdatePermitRequestSub('dredgeProject', { dredgeDisposalLongitude: null });
      }
    }, [dredgeType, setValue, doUpdatePermitRequestSub]);

    useEffect(() => {
      if (isDredgingNavWaters === 'false' || isDredgingNavWaters === '') {
        setValue('materialTypeComposition', '', { shouldValidate: true });
        setValue('dredgeMethod', '', { shouldValidate: true });
        setValue('constructionMethods', '', { shouldValidate: true });
        setValue('estimatedNumberOfEvents', '', { shouldValidate: true });
        setValue('workStartDate', '', { shouldValidate: true });
        setValue('workEndDate', '', { shouldValidate: true });
        setValue('durationOfWork', '', { shouldValidate: true });
        setValue('unitOfWork', '', { shouldValidate: true });
        setValue('currentDepth', '', { shouldValidate: true });
        setValue('dredgeType', [], { shouldValidate: true });
        doUpdatePermitRequestSub('dredgeProject', { materialTypeComposition: '' });
        doUpdatePermitRequestSub('dredgeProject', { dredgeMethod: '' });
        doUpdatePermitRequestSub('dredgeProject', { constructionMethods: '' });
        doUpdatePermitRequestSub('dredgeProject', { estimatedNumberOfEvents: null });
        doUpdatePermitRequestSub('dredgeProject', { workStartDate: '' });
        doUpdatePermitRequestSub('dredgeProject', { workEndDate: '' });
        doUpdatePermitRequestSub('dredgeProject', { durationOfWork: null });
        doUpdatePermitRequestSub('dredgeProject', { unitOfWork: '' });
        doUpdatePermitRequestSub('dredgeProject', { currentDepth: null });
        doUpdatePermitRequestSub('dredgeProject', { isDredgeTypeMaintenance: null });
        doUpdatePermitRequestSub('dredgeProject', { isDredgeTypeNew: null });
        // Reset all dredge project data
        setDredgeProjectData({});
      }
    }, [isDredgingNavWaters, setValue, doUpdatePermitRequestSub]);

    useEffect(() => {
      if (rowErrors && otherNWPData?.length > 0) {
        setError('otherNWP', { type: 'custom', message: 'Other Nationwide Permits: Table contains invalid rows' });
        setTableInvalid(true);
      } else if (!rowErrors && tableInvalid !== false) {
        clearErrors('otherNWP');
        setTableInvalid(false);
      }
    }, [rowErrors, otherNWPData.length, setTableInvalid, setError, clearErrors, tableInvalid]);

    useEffect(() => {
      const sectionValid = isValid && !tableInvalid;
      doUpdateSectionValidity(
        PermitsFormGeneralProjectInformationMetadata.sectionName,
        sectionValid,
        stepNo,
        isReadOnly
      );
    }, [isValid, doUpdateSectionValidity, stepNo, tableInvalid, isReadOnly]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly && (
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        )}
        <FieldHeader text='General Project Information' />
        <h6 className='border-bottom width-full padding-bottom-1 margin-top-3'>Project</h6>
        <div className='ml-2'>
          <TextInput
            label='Project Name'
            name='projectName'
            type='text'
            tooltip='Please provide name identifying the proposed project, e.g., Landmark Plaza, Burned Hills Subdivision, or Edsall Commercial Center'
            onChange={handleChange}
            readOnly={isReadOnly}
            required
          />
        </div>
        <div className='margin-left-1'>
          <SelectInput
            className='w-50'
            name='previousFileNumbersExist'
            label='Has the USACE previously issued a file number for any part of the project area?'
            onChange={handleChange}
            readOnly={isReadOnly}
            required
          >
            <option key='2' value='true'>
              Yes
            </option>
            <option key='3' value='false'>
              No
            </option>
          </SelectInput>
        </div>
        {previousFileNumbersExist === 'true' && (
          <div className='margin-top-1 margin-left-1'>
            <CreatableSelectInput
              className='margin-top-1'
              name='previousFileNumbers'
              label='Previous File Number(s)'
              tooltip='Enter the file number and press "ENTER" or click on "Create ..."'
              placeholder='e.g. MVS-2023-12345, MVM-2020-1234 ...'
              required
              multi
              readOnly={isReadOnly}
              handleChange={handleSelectChange}
              handleBlur={handleChange}
            />
          </div>
        )}

        <h6 className='border-bottom width-full padding-bottom-1 margin-top-3'>Permits Info</h6>
        <div className='margin-left-1'>
          <MultiSelectInput
            multi
            name='gpPermitTypes'
            label='Identify the specific Nationwide Permit(s) or Regional or Programmatic General Permit you propose to use'
            tooltip='List the number(s) of the Nationwide Permit(s) you want to use to authorize the proposed activity (e.g., NWP 29).'
            options={populateGPPermitTypeOptions()}
            handleChange={handleChange}
            readOnly={isReadOnly}
          />
        </div>
        <Alert className='margin-left-1' type='info' heading='NOTE' headingLevel='h4'>
          You can access additional information about the Nationwide Permit(s) or Regional or Programmatic General
          Permit you select above, at the following link:{' '}
          <ExternalLink
            href='https://usace.contentdm.oclc.org/utils/getfile/collection/p16021coll7/id/19757'
            title='read more here'
            content='https://usace.contentdm.oclc.org/utils/getfile/collection/p16021coll7/id/19757'
          />
        </Alert>
        <Alert className='margin-left-1' type='info'>
          The PCN must include a delineation of wetlands, other special aquatic sites, and other waters, such as lakes
          and ponds, and perennial and intermittent streams, on the project site. Wetland delineations must be prepared
          in accordance with the current method required by USACE. The permittee may ask USACE to delineate the special
          aquatic sites and other waters on the project site, but there may be a delay if USACE does the delineation,
          especially if the project site is large or contains many wetlands, other special aquatic sites, and other
          waters. Furthermore, the 45-day period will not start until the delineation has been submitted to or completed
          by USACE, as appropriate.
        </Alert>
        <label className='margin-top-2 margin-bottom-0 margin-left-1'>
          List any other Nationwide Permit(s), Regional General Permit(s), Individual Permit(s) used to authorize any
          part of the proposed project or any related activity. Also, if any other Certificates or Approvals/Denials
          have been received from other Federal, State or Local Agencies for Work Described in This Application, please
          include here and select “Other” in the Type of Approval column{' '}
          <Tooltip
            name='NWP-tooltip'
            content='List any other Nationwide Permit(s), Regional General Permit(s), or individual permit(s) used or intended to be used to authorize any part of the proposed project or any related activity. For linear projects, list other separate and distant crossings of waters and wetlands authorized by Nationwide Permits 12 or 14 that do not require PCNs.'
            clickable={false}
            iconStyle={{ marginLeft: '5px' }}
          />
        </label>
        <div id='otherNWP'>
          <TanStackTableNew
            data={otherNWPData}
            key={otherNWPKey}
            columns={NWPColumns}
            validationSchema={otherNWPValidationSchema}
            rowErrorCallback={setRowErrors}
            addRow={() => setOtherNWPData((prev) => (prev ? [...prev, {}] : [{}]))}
            updateSourceData={updateOtherNWPData}
            removeMultipleRows={removeMultipleRows}
            isReadOnly={isReadOnly}
            initialTableState={{}}
            hideDrag={true}
            placeholderText={'No Permits found, Please click below to add an Permit'}
            placeholderClick={() => setOtherNWPData((prev) => (prev ? [...prev, {}] : [{}]))}
          />
        </div>
        <fieldset className='margin-top-2' id='applicableStatutoryAuthority'>
          <Label className='margin-bottom-0' htmlFor='applicableStatutoryAuthority'>
            <span id='applicableStatutoryAuthority_label'>Select Applicable Statutory Authority</span>
            <span className='asterisk-color'>*</span>
          </Label>
          <Alert className='margin-top-1' type='info'>
            If you're unsure of which authorities apply to your project, you can{' '}
            <ExternalLink
              href={
                currentEnv === 'local' || currentEnv === 'development' ? '/home/permitting' : '/rrs/home/permitting'
              }
              title='read about permitting requirements'
              content='read about permitting requirements'
              aria-label='read about permitting requirements'
            />{' '}
            or contact your{' '}
            <ExternalLink
              href='https://regulatory.ops.usace.army.mil/offices '
              title='local regulatory office'
              content='local regulatory office'
              aria-label='local regulatory office'
            />
            .
          </Alert>
          <Checkbox
            disabled={isReadOnly}
            id='check-section-404'
            label={ApplicableStatutoryAuthorities.Section404}
            labelDescription='Requires authorization from the U.S. Army Corps of Engineers, for the discharge of dredged or fill material into all waters of the United States, including wetlands. Discharges of fill material generally include, without limitation: placement of fill that is necessary for the construction of any structure, or impoundment requiring rock, sand, dirt, or other material for its construction; site-development fills for recreational, industrial, commercial, residential, and other uses; causeways or road fills; dams and dikes; artificial islands; property protection or reclamation devices such as riprap, groins, seawalls, breakwaters, and revetments; beach nourishment; levees; fill for intake and outfall pipes and subaqueous utility lines; fill associated with the creation of ponds; and any other work involving the discharge of fill or dredged material. A USACE permit is required whether the work is permanent or temporary. Examples of temporary discharges include dewatering of dredged material prior to final disposal, and temporary fills for access roadways, cofferdams, storage and work areas.'
            name='applicableStatutoryAuthority'
            onChange={handleChange}
            tile
            value={ApplicableStatutoryAuthorities.Section404}
          />
          <Checkbox
            disabled={isReadOnly}
            id='check-section-10'
            label={ApplicableStatutoryAuthorities.Section10}
            labelDescription='Requires authorization from the U.S. Army Corps of Engineers for the construction of any structure in or over any navigable water of the United States. Structures or work outside the limits defined for navigable waters of the United States require a Section 10 permit if the structure or work affects the course, location, or condition of the water body. The law applies to any dredging or disposal of dredged materials, excavation, filling, re-channelization, or any other modification of a navigable water of the United States, and applies to all structures, from the smallest floating dock to the largest commercial undertaking. It further includes, without limitation, any wharf, dolphin, weir, boom breakwater, jetty, groin, bank protection (e.g. riprap, revetment, bulkhead), mooring structures such as pilings, aerial or subaqueous power transmission lines, intake or outfall pipes, permanently moored floating vessel, tunnel, artificial canal, boat ramp, aids to navigation, and any other permanent, or semi-permanent obstacle or obstruction.'
            name='applicableStatutoryAuthority'
            onChange={handleChange}
            tile
            value={ApplicableStatutoryAuthorities.Section10}
          />
        </fieldset>
        <div className='margin-left-1'>
          <TextAreaInput
            name='projectDescription'
            label='Project Description (Describe the proposed Nationwide Permit activity)'
            tooltip='Describe the proposed Nationwide Permit activity, including the direct and indirect adverse environmental effects the activity would cause. The description of the proposed activity should be sufficiently detailed to allow the U.S. Army Corps of Engineers to determine that the adverse environmental effects of the activity will be no more than minimal. Identify the materials to be used in constructions, as well as the methods by which the work is to be done.'
            onChange={handleChange}
            readOnly={isReadOnly}
            required
          />
        </div>
        <div className='margin-left-1'>
          <TextAreaInput
            name='natureOfActivity'
            label='Nature of Activity'
            tooltip={natureOfActivityTooltipContent}
            readOnly={isReadOnly}
            onChange={handleChange}
            required
          />
        </div>
        <div className='margin-left-1'>
          <TextAreaInput
            name='projectPurpose'
            label='Project Purpose (Describe the purpose of the Nationwide Permit activity)'
            required
            tooltip='Describe the purpose and need for the proposed Nationwide Permit activity. What will it be used for and why?'
            onChange={handleChange}
            readOnly={isReadOnly}
          />
        </div>
        <div className='margin-left-1'>
          <label className='margin-top-2 margin-bottom-0'>
            If the terms of the Nationwide Permit(s) you want to use require additional information to be included in
            the PCN, please provide here
          </label>
          <Accordion bordered items={nwpTermsAccordionList} />
          <TextAreaInput
            className='margin-top-1'
            name='navigableWatersAdditionalInfo'
            onChange={handleChange}
            readOnly={isReadOnly}
          />
        </div>
        <div className='margin-left-1'>
          <SelectInput
            className='w-50'
            name='isAnyWorkComplete'
            label='Is any portion of the Nationwide Permit activity complete?'
            tooltip='Describe any work that has already been completed for the NWP activity.'
            onChange={handleChange}
            readOnly={isReadOnly}
            required
          >
            <option key='2' value='true'>
              Yes
            </option>
            <option key='3' value='false'>
              No
            </option>
          </SelectInput>
        </div>
        {(isAnyWorkComplete === 'true' || isAnyWorkComplete === true) && (
          <div className='margin-left-1'>
            <TextAreaInput
              name='completedWorkDescription'
              label='Describe the completed work'
              onChange={handleChange}
              readOnly={isReadOnly}
              required
            />
          </div>
        )}

        {/* Dredge Project Section */}
        {applicableStatutoryAuthority.length > 0 &&
          applicableStatutoryAuthority?.includes(ApplicableStatutoryAuthorities.Section10) && (
            <>
              <FieldHeader text='Dredging' subtext='Projects Involving Impacts to Section 10 Navigable Waters' />
              <div className='margin-left-1'>
                <SelectInput
                  name='isDredgingNavWaters'
                  label='Does the project involve dredging in navigable waters of the United States?'
                  className='w-50'
                  onChange={handleChange}
                  readOnly={isReadOnly}
                  required
                >
                  <option key='2' value='true'>
                    Yes
                  </option>
                  <option key='3' value='false'>
                    No
                  </option>
                </SelectInput>
              </div>
              {(isDredgingNavWaters === 'true' || isDredgingNavWaters === true) && (
                <PermitsDredgeProject handleChange={handleChange} isReadOnly={isReadOnly} />
              )}
            </>
          )}
        <div className='margin-bottom-3' />
      </FormProvider>
    );
  }
);

PermitsFormGeneralProjectInformation.metadata = PermitsFormGeneralProjectInformationMetadata;

export default PermitsFormGeneralProjectInformation;
