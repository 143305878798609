import { Alert } from '@trussworks/react-uswds';
import Question from './Question';

const Section = ({ sectionData, onChange }) => (
  <section className='ddf-section'>
    <h2 className='ddf-section-title'>{sectionData.sectionName}</h2>
    {sectionData.additionalInfo && (
      <Alert className='ddf-section-info callout' noIcon slim>
        {sectionData.additionalInfo}
      </Alert>
    )}
    {sectionData.jpaQuestions.map((questionData) => (
      <Question key={questionData.jpaQuestionID} onChange={onChange} questionData={questionData} />
    ))}
  </section>
);

export default Section;
