import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import Step from './Step';
import FormStatus from './FormStatus';
import './verticalStepper.scss';

const VerticalStepper = connect(
  'doSetActiveStep',
  'doSetTouchedStep',
  'selectSteps',
  'selectActiveStep',
  ({ doSetActiveStep, doSetTouchedStep, steps, activeStep }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [previousStep, setPreviousStep] = useState();

    const handleClick = (event) => {
      const clickId = event.target.getAttribute('data-clickid');
      const id = clickId || event.target.id;
      const targetId = parseInt(id);

      if (activeStep?.id !== targetId) {
        doSetActiveStep(targetId);
        document.getElementById('scroll').scrollTop = 0;
      }
    };

    useEffect(() => {
      if (activeStep?.id) {
        setPreviousStep(currentStep);
        setCurrentStep(activeStep?.id ?? 0);
      }
    }, [activeStep?.id, setPreviousStep, setCurrentStep, currentStep]);

    useEffect(() => {
      previousStep !== currentStep && doSetTouchedStep(previousStep);
    }, [previousStep, currentStep, doSetTouchedStep]);

    return (
      <div className='vertical-container'>
        <FormStatus />
        <div className='step-status'>
          Current Step: {currentStep + 1} of {steps.length}
        </div>
        {steps.map((step) => (
          <Step
            clickId={step.clickId}
            id={step.id}
            isSubStep={step.isSubStep}
            key={step.id}
            onClick={handleClick}
            status={step.status}
            touched={step.touched}
          >
            {step.label}
          </Step>
        ))}
      </div>
    );
  }
);

export default VerticalStepper;
