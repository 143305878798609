import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import FieldHeader from '@forms/components/Form/FieldHeader.jsx';
import Tooltip from '@components/tooltip/tooltip.jsx';
import { Accordion, Alert } from '@trussworks/react-uswds';
import InternalLink from '@components/internal-link/InternalLink';
import LinkButton from '@components/link/linkButton.jsx';
import NewApplicationModal from '@pages/Home/components/modals/NewApplication.jsx';
import genericSecondaryModal from '@forms/components/modals/genericSecondaryModal.jsx';
import ExternalLink from '@components/external-link/ExternalLink';
import { FileTypes } from '@src/utils/enums';
import { examplePlansFileList } from '@pages/Resources/components/Permitting/PermittingHelper';

export const IPForm2aInstructionsMetadata = {
  header: 'Request General Permit',
  sectionName: 'Instructions',
};

const tooltipContent = {
  WOTUSDelinReport: (
    <>
      <p>
        The term "delineation" of <InternalLink title='WOTUS' href='/home/jurisdiction' content='WOTUS' /> is
        differentiated here from a "complete delineation report" of{' '}
        <InternalLink title='WOTUS' href='/home/jurisdiction' content='WOTUS' />. In order to submit a complete permit
        application, an applicant is only required to provide a delineation that depicts the boundaries of aquatic
        resources on project site. This information will assist the agencies in making meaningful comments during the
        public notice period.
      </p>
      <p>
        A "complete delineation report" includes the boundary information in addition to the scientific methods and
        documentation used to arrive at those boundaries. A "complete delineation report" is typically in the form of a
        report that consists of a document or series of documents, stored in the USACE administrative record for the
        project, that describes the methodology used to conduct the delineation and typically includes a collection of
        one or more maps, datasheets, photographs, and/or figures that depict and support a legally defensible
        delineation of <InternalLink title='WOTUS' href='/home/jurisdiction' content='WOTUS' /> on a property.
      </p>
    </>
  ),
  competeDelinReport: (
    <p>
      A "complete delineation report" is often in the form of a report that consists of a document or series of
      documents, stored in the USACE administrative record for the project, that describes the methodology used to
      conduct the delineation and typically includes a collection of one or more maps, datasheets, photographs, and/or
      figures that depict and support a legally defensible delineation of{' '}
      <InternalLink title='WOTUS' href='/home/jurisdiction' content='WOTUS' /> on a property.{' '}
    </p>
  ),
};

const activityRequirementsAccordion = [
  {
    id: 1,
    headingLevel: 'h3',
    title: 'Activities Involving Discharges of Dredged or Fill Material',
    content: (
      <>
        <p>
          The application must include the source of the material; the purpose of the discharge, a description of the
          type, composition and quantity of the material; the method of transportation and disposal of the material; and
          the location of the disposal site (33 CFR 325.1(d)(4)).
        </p>
        <p>Mitigation:</p>
        <p>
          The application{' '}
          <span className='text-bold'>
            must include a statement describing how impacts to{' '}
            <InternalLink title='WOTUS' href='/home/jurisdiction' content='WOTUS' openInNewTab /> are to be avoided and
            minimized. The application must also include a statement describing how impacts to WOTUS are to be
            compensated or a statement explaining why compensatory mitigation should not be required
          </span>{' '}
          for the proposed impacts (33 CFR 325.1(d)(7)). This explanation shall address the proposed avoidance and
          minimization and the amount, type, and location of any proposed compensatory mitigation, including any
          out-of-kind compensation, or indicate an intention to use an approved mitigation bank or in-lieu fee program.
        </p>
        <p>
          If it has been determined compensatory mitigation may be required and an approved mitigation bank or in-lieu
          fee program will not be used, applicants have the option to submit a{' '}
          <span className='text-bold'>draft compensatory mitigation plan</span> prepared in accordance with 33 CF
          332.4(c) with the permit application.
        </p>
      </>
    ),
  },
  {
    id: 2,
    headingLevel: 'h3',
    title: 'Activities Involving Dredging in Navigable Waters of the United States',
    content: (
      <p>
        The application must include a description of the type, composition, and quantity of the material to be dredged,
        the method of dredging, and the site and plans for disposal of the dredged material (33 CFR 325.1(d)(3)).
      </p>
    ),
  },
  {
    id: 3,
    headingLevel: 'h3',
    title: 'Activities occurring in the Territorial Seas or Ocean Waters',
    content: (
      <p>
        The application must include a description of the activity's relationship to the baseline from which the
        territorial sea is measured (33 CFR 325.3(a)(7)).
      </p>
    ),
  },
  {
    id: 4,
    headingLevel: 'h3',
    title:
      'Activities Involving the Transportation of Dredged Material for the Purpose of Disposing of it in Ocean Waters ',
    content: (
      <p>
        The application must include the source of the material; the purpose of the discharge, a description of the
        type, composition, and quantity of the material; the method of transportation and disposal of the material; and
        the location of the disposal site (33 CFR 325.1(d)(4)). The application must also include the physical
        boundaries of the disposal site, a description of known dredged material discharges at the proposed disposal
        site, the existence and documented effects of other authorized disposals that have been made in the disposal
        area (e.g., heavy metal background reading and organic carbon content), and the estimated length of time during
        which the disposal would continue at the proposed site. If the proposed disposal site has not been designated by
        the Administrator, Environmental Protection Agency, the application must include a description of the
        characteristics of the proposed disposal site and an explanation as to why no previously designated disposal
        site is feasible. See 33 CFR 325.3(a)(17).
      </p>
    ),
  },
  {
    id: 5,
    headingLevel: 'h3',
    title: 'Activities Involving the Construction of a Filled Area or Pile or Float-supported Platform',
    content: (
      <p>
        The project description must include the use of, and specific structures to be erected on, the fill or platform
        (33 CFR 325.1(d)(5)).
      </p>
    ),
  },
  {
    id: 6,
    headingLevel: 'h3',
    title: 'Activities Involving the Construction or Placement of an Artificial Reef',
    content: (
      <p>
        The application must include provisions for siting, constructing, monitoring, and managing the artificial reef
        (33 CFR 325.1(d)(9)).
      </p>
    ),
  },
];

const optionalInfoAccordion = [
  {
    id: 1,
    headingLevel: 'h3',
    title: 'Historic Properties, Endangered Species, and Essential Fish Habitat',
    content: (
      <p>
        While not required to issue the Public Notice,{' '}
        <span className='text-bold'>
          we recommend that applicants provide any information they have pertaining to{' '}
          <ExternalLink
            href='https://www.fisheries.noaa.gov/national/habitat-conservation/essential-fish-habitat'
            title='Essential Fish Habitat'
            content='Essential Fish Habitat'
          />
          ,{' '}
          <ExternalLink
            href='https://www.fws.gov/service/esa-section-7-consultation'
            title='Endangered Species'
            content='Endangered Species'
          />
          , and/or{' '}
          <ExternalLink
            href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Related-Resources/'
            title='cultural/historic properties'
            content='cultural/historic properties'
          />{' '}
          that could be affected by the proposed activity.
        </span>{' '}
        This information should be provided in the threatened/endangered species and cultural/historic properties
        sections of the permit application.
      </p>
    ),
  },
];

const IPForm2aInstructions = connect(
  'doDownloadFile',
  'doUpdateSectionValidity',
  'doUpdateRelativeUrl',
  'doModalOpen',
  'doSecondaryModalOpen',
  'doResetReduxFormData',
  ({
    doDownloadFile,
    doUpdateSectionValidity,
    doUpdateRelativeUrl,
    doModalOpen,
    doSecondaryModalOpen,
    doResetReduxFormData,
    stepNo,
  }) => {
    const permitRequirementsAccordion = [
      {
        id: 1,
        headingLevel: 'h3',
        title: 'Project Description/Nature of Activity',
        content: (
          <>
            <p className='text-bold'>
              Providing a clear description of the project can help avoid unnecessary agency comments during the public
              notice period.
            </p>
            <p>Project Description:</p>
            <p>
              Describe the proposed activity, including the direct and indirect adverse environmental effects the
              activity would cause, its purpose, and intended use, so as to provide sufficient information to generate
              meaningful comments from the appropriate agencies as well as the general public. The description of the
              proposed activity should also include information on how the activity has been designed to avoid and
              minimize impacts to <InternalLink title='WOTUS' href='/home/jurisdiction' content='WOTUS' openInNewTab />{' '}
              to the maximum extent practicable. Identify the material(s) to be used in construction, as well as the
              methods by which the work is to be done.
            </p>
            <p>Nature of Activity:</p>
            <p>
              Describe the overall activity or project. Give appropriate dimensions of structures/fills such as wing
              walls, dikes, (identify the materials to be used in construction, as well as the methods by which the work
              is to be done), or excavation (length, width, and depth). If the project would include the construction of
              a filled or pile or float-supported platform, you must include a description of the use of, and specific
              structures to be erected on, the fill or platform.
            </p>
          </>
        ),
      },
      {
        id: 2,
        headingLevel: 'h3',
        title: 'Project Plans',
        content: (
          <>
            <p>
              The application{' '}
              <span className='text-bold'>
                must include plan and elevation drawings showing the general and specific site location and character of
                all proposed activities, including the size relationship of the proposed structures to the size of the
                impacted waterway and depth of water in the area (33 CFR 325.3(a)(6))
              </span>
              . An original set of <span className='text-bold'>8½ x11 or 11 ½ x 17-inch</span> plans including a
              vicinity map, a plan view, and a typical cross-section map should be provided in the Supporting
              Information Section.
            </p>
            <p>
              While plans do not need to be professionally produced, they should be clear, accurate, and contain all
              necessary information. The example plans included in these instructions could be used as a guide when
              developing project plans.{' '}
              <span className='text-bold'>
                Following these examples will help facilitate an efficient review of the application and prevent the
                need for the USACE to request additional information.
              </span>
            </p>
            <p className='text-bold'>Example Plans:</p>
            {examplePlansFileList.map((file, index) => (
              <p key={index} className={`margin-bottom-0 ${index !== 0 && 'margin-top-0'}`}>
                <LinkButton
                  onClick={() => doDownloadFile(file.filename, FileTypes.Resource)}
                  title={file.label}
                  content={file.label}
                />
              </p>
            ))}
          </>
        ),
      },
      {
        id: 3,
        headingLevel: 'h3',
        title: 'Delineations of Waters of the United States',
        content: (
          <>
            <p>
              A delineation
              <Tooltip name='delineationTooltip' content={tooltipContent.WOTUSDelinReport} clickable /> of{' '}
              <InternalLink title='WOTUS' href='/home/jurisdiction' content='WOTUS' openInNewTab /> within the project
              area is required and must be clearly depicted on the project drawings/plans. A delineation of WOTUS
              identifies and locates all aquatic resources (including wetlands) using scientific methods developed by
              the USACE. A delineation ensures accurate quantification of impacts to WOTUS and helps the applicant
              clearly convey the extent and nature of the activity on their project plans. If an applicant is proposing
              to discharge fill material into WOTUS, the delineation can also be used to support the required
              explanation describing how impacts to WOTUS are being avoided and minimized on the project site. (33 CFR
              325.1(d)(7)).
            </p>
            <p>
              The USACE recommends submittal of a complete delineation report
              <Tooltip name='completeDelineationTooltip' content={tooltipContent.competeDelinReport} clickable /> prior
              to or concurrently with the permit application to expedite the review process.{' '}
            </p>
            <p>
              If a complete delineation report is not provided with the application, it may be required as additional
              information to process the application pursuant to 33 CFR 325.1(e), 40 CFR 230.5(d), and 40 CFR 230.5(c).
            </p>
          </>
        ),
      },
    ];

    const handleLink = () => {
      const onSaveFunc = () => {
        doResetReduxFormData();
        sessionStorage.setItem('IPButtonSession', true);
        const IPButtonSession = sessionStorage.getItem('IPButtonSession');
        doUpdateRelativeUrl('/');
        IPButtonSession && doModalOpen(NewApplicationModal);
        sessionStorage.removeItem('IPButtonSession');
      };
      doSecondaryModalOpen(genericSecondaryModal, {
        title: 'Are You Sure?',
        msg: 'You are about to leave this application to start a new Pre-application request. Your current Individual Permit (ENG 4345) request will remain accessible via the RRS dashboard.',
        cancelText: 'Cancel',
        onSave: onSaveFunc,
      });
    };

    useEffect(() => {
      doUpdateSectionValidity(IPForm2aInstructionsMetadata.sectionName, true, stepNo);
    }, [doUpdateSectionValidity, stepNo]);

    return (
      <>
        <FieldHeader text='INSTRUCTIONS' />
        <p className='margin-bottom-2 margin-top-2'>
          This path provides an electronic option for applicants to complete and submit applications for{' '}
          <span className='text-bold'>standard permits, letters of permission, and general permits in some areas</span>.
          This section will guide you through the process to ensure that a complete application is submitted.
        </p>

        <p className='margin-bottom-1 text-bold text-underline'>Standard Permit (SP):</p>
        <p className='margin-bottom-2'>
          Standard Permits (SP) are a type of <span className='text-bold'>individual permit</span> typically used for
          projects that involve more than minimal impacts to{' '}
          <InternalLink
            title='Waters of the United States (WOTUS)'
            href='/home/jurisdiction'
            content='Waters of the United States (WOTUS)'
            openInNewTab
          />{' '}
          or that exceed thresholds in the General Permits. An SP can be used to authorize all types of impacts to
          WOTUS. An SP requires a public interest review, including publication of a public notice (generally lasting 15
          or 30 days), National Environmental Policy Act review, Section 404b(1) of the Clean Water Act analysis, and
          coordination with involved agencies, interested parties, and the general public.{' '}
        </p>
        <p className='margin-bottom-1 text-bold text-underline'>Letter of Permission (LOP):</p>
        <p className='margin-bottom-2'>
          A Letter of Permission (LOP) is a type of <span className='text-bold'>individual permit</span> issued through
          an abbreviated processing procedure that includes coordination with federal and state fish and wildlife
          agencies. Letters of Permission are commonly used to authorize structures in Section 10 waters but in some
          cases can authorize discharges of fill material in{' '}
          <InternalLink title='WOTUS' href='/home/jurisdiction' content='WOTUS' openInNewTab />. A Letter of Permission
          will be issued where procedures of 33 CFR 325.2(e)(1) have been followed.
        </p>
        <p className='margin-bottom-1 text-bold text-underline'>General Permits (GPs):</p>
        <p className='margin-bottom-2'>
          There are three types of general permits: Nationwide Permits (NWPs), Regional General Permits (RGPs), and
          Programmatic General Permits (PGPs). General permits are typically not tailored for individual
          applicants/projects but instead authorize activities that the USACE has determined to be substantially similar
          in nature (such as utility lines, residential developments, bank stabilization, etc.) and which cause minimal
          individual and cumulative environmental impacts. General permits can authorize activities within a limited
          geographic area (such as a county or state), a specific region (such as a group of contiguous states), or the
          entire nation.
        </p>

        <p className='text-bold'>
          By completing this form fully and accurately, you will be satisfying the requirements for a complete
          application for a SP or a LOP, and for a complete General Permit preconstruction notification in some
          Districts.
        </p>

        <Alert type='warning' heading='IMPORTANT' headingLevel='h2'>
          <p className='text-bold'>
            The United States Army Corps of Engineers (USACE) strongly encourages pre-application coordination meetings
            prior to submitting applications for standard permits.{' '}
          </p>
          <p>
            Pre-application meetings provide the applicant with helpful information necessary in pursuing a complete
            application, including factors the USACE must consider in its permit decision making process.
            Pre-application meetings are most productive when the applicant has prepared a delineation of{' '}
            <InternalLink title='WOTUS' href='/home/jurisdiction' content='WOTUS' openInNewTab />, developed information
            regarding impacts to WOTUS, developed a project purpose, considered alternatives (on and offsite) to reduce
            impacts to WOTUS, and considered a mitigation statement.{' '}
            <span className='text-bold'>
              Pre-application meetings improve the USACE and other state/federal agency's understanding of the proposed
              project and provides helpful information to the applicant regarding information requirements which reduces
              delays in application reviews and permit decisions.{' '}
            </span>
          </p>
          <LinkButton
            className='btn office-support-button'
            onClick={() => handleLink()}
            title='Submit a pre-application coordination request for a Standard Permit'
            content='Submit a pre-application coordination request for a Standard Permit'
          />
        </Alert>

        <hr></hr>
        <h5 className='margin-top-3 margin-bottom-3 text-bold text-underline'>
          Requirements for Individual Permit Applications:
        </h5>
        <p>
          The permit application must include a complete{' '}
          <span className='text-bold'>description of the proposed activity</span> including necessary{' '}
          <span className='text-bold'>drawings, sketches, or plans</span> sufficient for public notice (detailed
          engineering plans and specifications are not required); the{' '}
          <span className='text-bold'>
            location, purpose and need for the proposed activity; scheduling of the activity
          </span>
          ; the{' '}
          <span className='text-bold'>
            names and addresses of adjoining property owners; the location and dimensions of adjacent structures
          </span>
          ; and a{' '}
          <span className='text-bold'>
            list of authorizations required by other federal, interstate, state, or local agencies
          </span>{' '}
          for the work, including all approvals received or denials already made (33 CFR 325.1(d)(1)).
        </p>
        <Alert type='warning' heading='IMPORTANT' headingLevel='h2'>
          <span className='text-bold'>
            The application must include all activities which the applicant plans to undertake which are reasonably
            related to the same project and for which a USACE permit would be required (33 CFR 325.1(d)(2))
          </span>
          . For example, a permit application for a marina may include dredging required for access as well as any fill
          associated with construction of the marina. Applicants should make every effort to disclose all
          known/reasonably foreseeable phases of the proposed project.{' '}
          <span className='text-bold'>
            The USACE project manager ultimately determines whether the information submitted meets the requirements for
            a complete application.
          </span>
        </Alert>
        <p className='margin-bottom-2 margin-top-2 text-italic'>Select each of the topics below to learn more</p>
        <div className='container margin-bottom-2'>
          <Accordion bordered items={permitRequirementsAccordion} multiselectable={true} />
        </div>

        <p className='margin-bottom-1 text-bold'>Requirements Based on the Type of Activity:</p>
        <p className='text-italic'>Select each of the activity types below to learn more.</p>
        <div className='container margin-bottom-2'>
          <Accordion bordered items={activityRequirementsAccordion} multiselectable={true} className='container' />
        </div>

        <p className='text-bold'>Optional Information:</p>
        <div className='container margin-bottom-2'>
          <Accordion bordered items={optionalInfoAccordion} className='container' />
        </div>
      </>
    );
  }
);

IPForm2aInstructions.metadata = IPForm2aInstructionsMetadata;

export default IPForm2aInstructions;
