/*
 * - Stores paths for the JPA service endpoints
 */

const serviceRoot = 'api/jpa/';

export const JpaEndpoints = {
  getForm: () => `${serviceRoot}getFormData`,
  getInitialSection: () => `${serviceRoot}getInitialSection`,
  getOrgs: () => `${serviceRoot}getOrgData`,
};
