/*
 * - Definitions for Lookup queries
 */

import JpaService from './service';
import {
  GetJpaFormData,
  GetJpaFormRequest,
  GetJpaInitialSectionRequest,
  GetJpaOrgData,
  GetJpaOrgRequest,
} from './types';

export const JPA_QUERY_KEYS = {
  GET_FORM: 'GET_JPA_FORM',
  GET_INITIAL_SECTION: 'GET_INITIAL_JPA_SECTION',
  GET_ORG: 'GET_JPA_ORG',
};

export class JpaQueries {
  public static readonly getForm = async (payload: GetJpaFormRequest): Promise<GetJpaFormData> => {
    const { data } = await JpaService.getForm(payload);
    return data;
  };

  // RESPONSE TYPE ???
  public static readonly getInitialSection = async (payload: GetJpaInitialSectionRequest): Promise<any> => {
    const { data } = await JpaService.getInitialSection(payload);
    return data;
  };

  public static readonly getOrgs = async (payload: GetJpaOrgRequest): Promise<GetJpaOrgData> => {
    const { data } = await JpaService.getOrgs(payload);
    return data;
  };
}
