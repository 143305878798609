import { useQuery } from '@tanstack/react-query';
import { PublicNoticeQueries, PN_QUERY_KEYS } from '@api/services/public-notices';
import { connect } from 'redux-bundler-react';
import { usePublicNotices } from '@stores';

import Breadcrumb from '@components/breadcrumb';
import { getEnvironmentVisibility } from '@components/environment-visibility/EnvironmentVisibility';
import PublicNoticesTable from './public-notices-table/PublicNoticesTable';
import PublicNoticesMap from './public-notices-map/PublicNoticesMap';
import PublicNoticesFilters from './PublicNoticesFilters';
import classnames from 'classnames';

import './publicNotices.scss';
import { Button, ButtonGroup } from '@trussworks/react-uswds';
import { useEffect } from 'react';
import PageLoader from '@src/app-components/loader/PageLoader';
import PublicNoticeFilterTags from './PublicNoticeFilterTags';

const showPublicNotices = getEnvironmentVisibility({ mode: 'hide' });

const PublicNotices = connect('doUpdateRelativeUrl', ({ doUpdateRelativeUrl }) => {
  const {
    debouncedSearchText,
    districtCodes,
    itemsPerPage,
    pageNumber,
    recordCount,
    setRecordCount,
    setView,
    sortColumn,
    sortOrder,
    stateCodes,
    view,
  } = usePublicNotices();

  const { data, isLoading } = useQuery({
    queryKey: [
      PN_QUERY_KEYS.GET_ALL,
      districtCodes,
      itemsPerPage,
      pageNumber,
      debouncedSearchText,
      sortColumn,
      sortOrder,
      stateCodes,
    ],
    queryFn: () =>
      PublicNoticeQueries.getAll({
        districtCodes,
        itemsPerPage: view === 'table' ? itemsPerPage : undefined,
        pageNumber,
        searchText: debouncedSearchText,
        sortColumn,
        sortOrder,
        states: stateCodes,
      }),
  });

  const tableStyles = classnames({
    'display-none': view !== 'table',
  });
  const mapStyles = classnames({
    'display-none': view !== 'map',
  });

  useEffect(() => {
    if (data) {
      setRecordCount(data.totalFilteredCount);
    }
  }, [setRecordCount, data]);

  !showPublicNotices && doUpdateRelativeUrl('/home');

  return (
    <div className='content-container'>
      <Breadcrumb pathname={'/public-notices'} href='/' />
      <h1>Public Notices</h1>
      <div className='pn-content'>
        <PublicNoticesFilters />
        <div className='pn-records'>
          <div className='pn-results-count'>Results Count: {recordCount}</div>
          {isLoading ? (
            <PageLoader />
          ) : (
            <>
              <ButtonGroup className='pn-view-toggle' type='segmented'>
                <Button
                  aria-selected={view === 'table'}
                  className='page-control'
                  onClick={() => setView('table')}
                  outline={view !== 'table'}
                >
                  Table view
                </Button>
                <Button
                  aria-selected={view === 'map'}
                  className='page-control'
                  onClick={() => setView('map')}
                  outline={view !== 'map'}
                >
                  Map view
                </Button>
              </ButtonGroup>
              <PublicNoticeFilterTags />
              <div className={tableStyles}>
                <PublicNoticesTable publicNotices={data?.publicNotices} />
              </div>
              <div className={mapStyles}>
                <PublicNoticesMap publicNotices={data?.publicNotices} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});
export default PublicNotices;
