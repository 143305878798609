import FilterTags from '@src/app-components/filter-tags/FilterTags';
import { usePublicNotices } from '@stores';
import { getStateNameFromCode } from '@src/utils/helpers';
import { LookupQueries, LOOKUP_QUERY_KEYS } from '@api/services/lookup';
import { useQuery } from '@tanstack/react-query';

const PublicNoticeFilterTags = () => {
  const { districtCodes, searchText, stateCodes, setStateCodes, setDistrictCodes, setSearchText } = usePublicNotices();

  const { data: districtsData } = useQuery({
    queryKey: [LOOKUP_QUERY_KEYS.GET_DISTRICTS],
    queryFn: LookupQueries.getDistricts,
  });

  const states = stateCodes.map((code) => getStateNameFromCode(code));
  const districts = districtCodes.map((code) => districtsData[code]?.orgName);

  const filters = { Districts: districts, States: states, Search: searchText };

  const handleRemoveFilter = (filterType, filterValue) => {
    switch (filterType) {
      case 'Districts':
        setDistrictCodes(districtCodes.filter((code) => districtsData[code]?.orgName !== filterValue));
        break;
      case 'States':
        setStateCodes(stateCodes.filter((code) => getStateNameFromCode(code) !== filterValue));
        break;
      case 'Search':
        setSearchText('');
        break;
      default:
        break;
    }
  };

  const handleClearFilterType = (filterType) => {
    switch (filterType) {
      case 'Districts':
        setDistrictCodes([]);
        break;
      case 'States':
        setStateCodes([]);
        break;
      case 'Search':
        setSearchText('');
        break;
      default:
        break;
    }
  };

  return <FilterTags filters={filters} onRemoveFilter={handleRemoveFilter} onClearFilterType={handleClearFilterType} />;
};

export default PublicNoticeFilterTags;
