import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { toast } from 'react-toastify';
import { Alert } from '@trussworks/react-uswds';

import { useFormContext } from 'react-hook-form';

import FileInput from '@components/file-input/FileInput';
import SplitLayout from '@components/layout/splitLayout';
import LinkButton from '@components/link/linkButton';
import ReadOnlyMap from '@components/map/readOnlyMap';

import { FileTypes, TemplateFiles } from '@src/utils/enums';

const ProjectLocationTabGDB = connect(
  'doGetGeometryData',
  'doDownloadFile',
  ({
    doGetGeometryData,
    doDownloadFile,
    activeTab,
    doDeleteFile = () => {},
    extent,
    geometry,
    locationType = 'Project',
    resetFields = () => {},
  }) => {
    const { setValue, watch } = useFormContext();
    const [selectedFile, setSelectedFile] = useState([]);

    const projectName = watch('projectName');

    const calculateGDBCentroid = async (file) => {
      const toastID = toast.loading('Processing GDB file...');
      doGetGeometryData(file?.[0], true, toastID);
    };

    const handleDelete = () => {
      doDeleteFile();
      setSelectedFile([]);
      resetFields();
    };

    const handleFileInput = (e) => {
      const filesArr = Array.from(e?.target?.files) ?? [];
      setSelectedFile(filesArr);
      !e?.target?.files && resetFields();
    };

    // Set rightOfEntryFile
    useEffect(() => {
      setValue('projectLocationGDBFile', selectedFile?.length > 0 ? 'true' : null, { shouldValidate: true });
    }, [selectedFile, setValue]);

    return (
      <SplitLayout>
        <>
          <div className='row'>
            <div className='col'>
              <h4 className='border-bottom width-full margin-bottom-2 margin-top-2'>{locationType} Map</h4>
            </div>
          </div>
          {activeTab === 0 && (
            <div className='row'>
              <div className='col'>
                <ReadOnlyMap mapOnly extent={extent} geometry={geometry} geometryName={projectName} />
              </div>
            </div>
          )}
        </>
        <>
          <div className='row'>
            <div className='col'>
              <h4 className='border-bottom width-full margin-bottom-2 margin-top-2'>Geodatabase File Upload</h4>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <Alert type='info' validation>
                <ul>
                  <li>{locationType} Location geometries should be dissolved to a single row geometry</li>
                  <li>Acceptable geometry types: Point, Line, Polygon, MultiPoint, MultiLine, MultiPolygon</li>
                  <li>All geometries must use CRS(Coordinate Reference System) WGS84</li>
                  <li>Geodatabases must be compressed into a single .zip file</li>
                  <li>
                    Use the GDB upload template located here:{' '}
                    <LinkButton
                      onClick={() => doDownloadFile(TemplateFiles.ProjectLocationGDBTemplate, FileTypes.Template)}
                      title='GDB Template [ZIP, 24 KB]'
                      content='GDB Template [ZIP, 24 KB]'
                    />
                  </li>
                </ul>
              </Alert>
            </div>
          </div>

          <div className='d-flex flex-column align-items-center margin-top-3 margin-bottom-3'>
            <FileInput
              accept='.zip'
              doDeleteFile={() => handleDelete()}
              doUploadFile={() => calculateGDBCentroid(selectedFile)}
              id='project-location-gdb-file'
              dragText='Drag .gdb (zipped) file here or '
              name='projectLocationGDBFile'
              onChange={handleFileInput}
              onDrop={handleFileInput}
              required
              showDeleteButton={geometry}
              showUploadButton={!geometry}
              value={selectedFile}
            />
          </div>
        </>
      </SplitLayout>
    );
  }
);

export default ProjectLocationTabGDB;
