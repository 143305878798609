/*
 * - Exposes class and methods to interact with the PublicNotice API
 */

import request, { REQUEST_METHODS } from '@api/Request';
import { PublicNoticeEndpoints } from './config';
import {
  GetAllPublicNoticesRequest,
  GetAllPublicNoticesResponse,
  GetPublicNoticeByIdResponse,
  ExportPublicNoticeCsvRequest,
  ExportPublicNoticeCsvResponse,
  GetPublicNoticeFilterCountsRequest,
  GetPublicNoticeFilterCountsResponse,
  SavePublicNoticeCommentRequest,
  SavePublicNoticeCommentResponse,
} from './types';

export default class PublicNoticeService {
  // Queries ---------------------------------------------------------------------------------------

  public static readonly getAll = (data: GetAllPublicNoticesRequest): Promise<GetAllPublicNoticesResponse> =>
    request({
      url: PublicNoticeEndpoints.getAll(),
      method: REQUEST_METHODS.POST,
      data,
    });

  public static readonly getFilterCounts = (
    data: GetPublicNoticeFilterCountsRequest
  ): Promise<GetPublicNoticeFilterCountsResponse> =>
    request({
      url: PublicNoticeEndpoints.getFilterCounts(),
      method: REQUEST_METHODS.POST,
      data,
    });

  public static readonly getById = (id: string): Promise<GetPublicNoticeByIdResponse> =>
    request({
      url: PublicNoticeEndpoints.getById(),
      method: REQUEST_METHODS.GET,
      params: { id },
    });

  // Mutations -------------------------------------------------------------------------------------

  public static readonly exportAllAsCsv = (
    data: ExportPublicNoticeCsvRequest
  ): Promise<ExportPublicNoticeCsvResponse> =>
    request({
      url: PublicNoticeEndpoints.exportAllAsCsv(),
      method: REQUEST_METHODS.POST,
      data,
    });

  public static readonly saveComment = (
    data: SavePublicNoticeCommentRequest
  ): Promise<SavePublicNoticeCommentResponse> =>
    request({
      url: PublicNoticeEndpoints.saveComment(),
      method: REQUEST_METHODS.POST,
      data,
    });
}
