/*
 * - Stores paths for the public notice service endpoints
 */

const serviceRoot = 'api/publicnotice/';

export const PublicNoticeEndpoints = {
  // Queries ---------------------------------------------------------------------------------------
  getAll: () => `${serviceRoot}getPublicNotices`,
  getById: () => `${serviceRoot}getPublicNotice`,
  getFilterCounts: () => `${serviceRoot}getPublicNoticeFilterCounts`,

  // Mutations -------------------------------------------------------------------------------------
  exportAllAsCsv: () => `${serviceRoot}exportPublicNotices`,
  saveComment: () => `${serviceRoot}addPublicNoticeComment`,
};
